import { Empty } from 'antd';
import React, { Suspense } from 'react';
import { useSortBy, useTable } from 'react-table';
import { TableProps } from '../../constants/tableData';
import { LoadingScreen } from '../../core/components/LoadingScreen';
import TableHeader from './components/TableHeader';

const Table = ({
  columns,
  data,
  search,
  handleSearch,
  confirmSearch,
  handleSort,
  sort,
  order,
}: TableProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  return (
    <div className='text-[8px] lg:text-sm ant-table-content lg:overflow-x-hidden overflow-auto h-[72vh] max-h-[72vh] border-y-4 rounded-md border-x-[1px]'>
      <Suspense fallback={<LoadingScreen />}>
        <table
          className={columns[0].accessor !== 'name' ? '' : 'ml-auto mr-0'}
          style={{ tableLayout: 'auto', width: '100%' }}
          {...getTableProps()}
        >
          <thead className='ant-table-thead text-table sticky top-0 z-20'>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    className={
                      'ant-table-cell ant-table-column-has-sorters text-secondary-text align-baseline hover:bg-slate-200 transition border-0' +
                      (column.id === 'status' ? 'min-w-110 w-48' : '') +
                      (column.id === 'createdDate' ? 'min-w-110 w-32' : '')
                    }
                    {...column.getHeaderProps()}
                    key={column.id}
                  >
                    {column.id !== 'expander' && (
                      <TableHeader
                        column={column}
                        search={search}
                        handleSearch={handleSearch}
                        confirmSearch={confirmSearch}
                        handleSort={handleSort}
                        sort={sort}
                        order={order}
                      />
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            className='ant-table-tbody text-primary-text font-[500] z-10'
            {...getTableBodyProps()}
          >
            {data.length !== 0 ? (
              <>
                {rows.map((row, i) => {
                  const val = prepareRow(row);
                  return (
                    <React.Fragment key={i + 'p'}>
                      <tr
                        {...row.getRowProps()}
                        className='ant-table-row ant-table-row-level-0 lg:border-y-4 border-y-[1px] bg-white hover:scale-[1.005] transition'
                      >
                        {row.cells.map(cell => {
                          return (
                            <td
                              className='ant-table-cell md:min-w-[110px] p-[10px]'
                              {...cell.getCellProps()}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <Empty
                className='absolute left-0 right-0 mx-auto p-10'
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description='Nema podataka'
              />
            )}
          </tbody>
        </table>
      </Suspense>
    </div>
  );
};

export default Table;
