import { createContext, useReducer } from 'react';
import { OperatorReducer } from '../reducers/OperatorReducer';

const initialState: any = {
  loginCredentials: {
    email: '',
    password: '',
    rememberMe: false,
  },
  doctorCEForm: {
    firstName: '',
    lastName: '',
    title: '',
    email: '',
    phoneNumber: '',
    enabled: true,
    medicineFields: [],
  },
  ticketCEForm: {
    firstName: '',
    lastName: '',
    email: '',
    doctor: '',
    phoneNumber: '',
    medicineField: '',
  },
};

export const OperatorContext = createContext(initialState);

export const OperatorStateProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(OperatorReducer, initialState);

  const updateOperatorState = (payload: any, type?: string) => {
    dispatch({
      type,
      payload,
    });
  };

  return (
    <OperatorContext.Provider
      value={{
        loginCredentials: state.loginCredentials,
        doctorCEForm: state.doctorCEForm,
        ticketCEForm: state.ticketCEForm,
        updateOperatorState,
      }}
    >
      {children}
    </OperatorContext.Provider>
  );
};
