import { Modal, Typography } from 'antd';
import { Suspense, useState } from 'react';
import { IconContext } from 'react-icons';
import { MdArrowDownward, MdNavigateNext } from 'react-icons/md';
import CustomButton from 'src/components/CustomComponents/CustomButton/CustomButton';
import ErrorBoundary from 'src/components/ErrorBoundary';
import { LoadingScreen } from 'src/core/components/LoadingScreen';
import PublicWrapper from 'src/core/components/PublicWrapper';
import { FooterComponent, TitleComponent } from './components/ModalComponents';

interface HomeScreenProps {
  createCall: any;
  startJoiningCall: any;
}

export default function HomeScreen({
  createCall,
  startJoiningCall,
}: HomeScreenProps) {
  const [mouseEntered, setMouseEntered] = useState(false);

  const startCall = () => {
    createCall().then((url: string) => startJoiningCall(url));
  };

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingScreen />}>
        <PublicWrapper />
        <Modal
          className="w-full md:w-[600px] m-auto"
          visible={true}
          closable={false}
          centered={true}
          title={<TitleComponent />}
          footer={
            <div className="text-primary-text my-0 text-xs text-center">
              <span>
                Selektujte “Allow” ili “Dozvoli” kako biste dozvolili aplikaciji
                da koristi vašu kameru i mikrofon
              </span>
            </div>
          }
        >
          <Typography.Title
            className="text-primary-text text-center mx-auto"
            level={4}
          >
            Pritiskom na dugme ispod, pristupite pozivu
          </Typography.Title>
          <IconContext.Provider
            value={{
              className: 'mt-10 mb-5 mx-auto bounceAlphaDown',
              size: '35',
              color: '#F14DAE',
            }}
          >
            <MdArrowDownward />
          </IconContext.Provider>
          <FooterComponent startCall={startCall} />
        </Modal>
      </Suspense>
    </ErrorBoundary>
  );
}
