import { Typography } from 'antd';
import moment from 'moment';
import { IconContext } from 'react-icons';
import { MdOutlineErrorOutline } from 'react-icons/md';
import { RiFileDownloadFill } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { FormItem } from '../PatientWebForm/constants/stepsData';

export const patientInfoTicket = [
  {
    id: 1,
    name: 'firstName',
    text: 'Ime',
  },
  {
    id: 2,
    name: 'lastName',
    text: 'Prezime',
  },
  {
    id: 3,
    name: 'birthDate',
    text: 'Datum rođenja',
  },
  {
    id: 4,
    name: 'gender',
    text: 'Pol',
  },
];

const itemUpload = (item: any) => {
  const token = window.location.pathname.split('/')[2];
  console.log(token);

  return (
    <div className='flex flex-wrap'>
      {item.answer.files?.length !== 0 ? (
        item.answer.files?.map((file: any) => (
          <a
            className='pointer hover:bg-gray-100 transition flex items-center break-all w-fit bg-gray-200 rounded-md px-2 m-1'
            href={`${file?.downloadUrl}?token=${token}`}
            download={file?.name}
          >
            <IconContext.Provider
              value={{
                className: 'm-2 ml-0 h-5 w-5',
                color: '#f14dae',
              }}
            >
              <RiFileDownloadFill />
              <Typography className='text-primary-text mr-2'>{file?.name}</Typography>
            </IconContext.Provider>
          </a>
        ))
      ) : (
        <span className='italic'>Pacijent nije dodao fajlove</span>
      )}
    </div>
  );
};

export const patientInfoQustionsValue = (itemType: string, item: FormItem) => {
  const notResponded: JSX.Element = <span className='italic'>Pacijent nije odgovorio</span>;
  if (itemType === 'SINGLE_CHOICE')
    return item.answer.selectedAnswers.length ? item.answer.selectedAnswers : notResponded;
  if (itemType === 'MULTIPLE_CHOICE') {
    const tempAnswers: string[] = [];
    item.answer.selectedAnswers.map((item: string) => {
      tempAnswers.push(item, ' ');
    });
    return tempAnswers.length ? tempAnswers : notResponded;
  }
  if (itemType === 'DATE')
    return item.answer.text !== null ? moment(item.answer.text).format('L') : notResponded;
  if (itemType === 'FREE_TEXT' || itemType === 'INTEGER' || itemType === 'DECIMAL')
    return item.answer.text !== null ? item.answer.text : notResponded;
  if (itemType === 'FILE_UPLOAD') return itemUpload(item);
};

export const PatientInfoError = ({ error }: any) => {
  return (
    <div className='flex flex-col items-center justify-center h-44'>
      <MdOutlineErrorOutline className='text-secondary-text w-32 h-32' />
      <Typography className='text-primary-text w-max text-lg font-semibold'>
        {error.response.data.message}
      </Typography>
    </div>
  );
};
