import { Button } from 'antd';
import React from 'react';

interface IButton {
  type?:
    | 'text'
    | 'link'
    | 'ghost'
    | 'default'
    | 'primary'
    | 'dashed'
    | undefined;
  text: string;
  disabled?: boolean;
  className?: string;
  icon?: any;
  ghost?: boolean;
  loading?: boolean;
  iconPosition?: 'left' | 'right';
  htmlType?: 'button' | 'submit' | 'reset' | undefined;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const CustomButton: React.VFC<IButton> = ({
  type,
  text,
  disabled,
  className,
  icon,
  ghost,
  loading,
  iconPosition,
  htmlType,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <Button
      onClick={() => onClick && onClick()}
      onMouseEnter={() => onMouseEnter && onMouseEnter()}
      onMouseLeave={() => onMouseLeave && onMouseLeave()}
      disabled={disabled}
      type={type}
      ghost={ghost}
      loading={loading}
      className={`rounded-md ${className}`}
      htmlType={htmlType}
    >
      {!loading && (
        <span className="mx-0 flex items-center justify-center">
          <span>
            {iconPosition ? (iconPosition === 'left' ? icon : text) : icon}
          </span>
          <span>
            {iconPosition ? (iconPosition === 'right' ? icon : text) : text}
          </span>
        </span>
      )}
    </Button>
  );
};

CustomButton.defaultProps = {
  disabled: false,
  ghost: false,
  type: 'primary',
};

export default CustomButton;
