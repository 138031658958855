import { Steps, Typography } from 'antd';
import { useState } from 'react';
import { IconContext } from 'react-icons';
import { MdNavigateNext } from 'react-icons/md';
import CustomButton from '../../../components/CustomComponents/CustomButton/CustomButton';

const { Step } = Steps;
const { Title } = Typography;

interface Props {
  currentStep: number;
  handleNextStep: (currentStep: number) => void;
  enumsData: any;
}

const buttonText: Record<number, string> = {
  1: 'Dalje',
  2: 'Gotovo',
  3: 'Zatvori',
};

export const TitleComponent = ({
  currentStep,
  handleNextStep,
  enumsData,
}: Props) => {
  return (
    <>
      {currentStep !== 3 && (
        <div className="my-2">
          <Steps size="small" current={currentStep - 1}>
            <Step />
            <Step />
          </Steps>
          <Title level={3} className="text-center my-2 text-primary-text">
            Upitnik za zakazivanje konsultacije
          </Title>
        </div>
      )}
    </>
  );
};

export const FooterComponent: React.FC<Props> = ({
  currentStep,
  handleNextStep,
  enumsData,
}: Props) => {
  const [mouseEntered, setMouseEntered] = useState(false);
  return (
    <div className="flex justify-between items-center">
      <Typography className="text-primary-text">
        Broj za podršku:{' '}
        <a
          className="inline p-0"
          href={`tel:${enumsData?.support_phone_number?.normalized}`}
        >
          {enumsData?.support_phone_number?.displayed}
        </a>
      </Typography>
      <CustomButton
        className="my-2 bg-btn-primary font-bold text-white h-[40px] w-[100px]"
        onMouseEnter={() => setMouseEntered(true)}
        onMouseLeave={() => setMouseEntered(false)}
        icon={
          <IconContext.Provider
            value={{
              className: `${mouseEntered && 'bounceAlpha'}`,
              size: '25',
              color: 'white',
            }}
          >
            <MdNavigateNext />
          </IconContext.Provider>
        }
        text={buttonText[currentStep]}
        type="primary"
        onClick={() => handleNextStep(currentStep)}
        iconPosition="right"
      />
    </div>
  );
};
