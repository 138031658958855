import { createContext, useReducer } from 'react';
import { OnlineMeetingReducer } from '../reducers/OnlineMeetingReducer';

const initialState: any = {
  messages: [],
  files: [],
  ratingData: { grade: 0, comment: '' },
  messagesCount: 0,
  collapsed: true,
};

export const OnlineMeetingContext = createContext(initialState);

export const OnlineMeetingStateProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(OnlineMeetingReducer, initialState);

  const updateOnlineMeetingState = (payload: any, type?: string) => {
    dispatch({
      type,
      payload,
    });
  };

  return (
    <OnlineMeetingContext.Provider
      value={{
        messages: state.messages,
        files: state.files,
        ratingData: state.ratingData,
        messagesCount: state.messagesCount,
        collapsed: state.collapsed,
        updateOnlineMeetingState,
      }}
    >
      {children}
    </OnlineMeetingContext.Provider>
  );
};
