import PublicWrapper from 'src/core/components/PublicWrapper';
import RatingModal from './components/RatingModal';

type Props = {};

const RatingStep = (props: Props) => {
  const token =
    localStorage.getItem('daily_auth_token') ??
    window.location.pathname.split('/')[2];

  return (
    <>
      <PublicWrapper />
      <RatingModal />
    </>
  );
};

export default RatingStep;
