import { Outlet } from 'react-router-dom';
import { OnlineMeetingStateProvider } from 'src/contexts/OnlineMeetingState';

const OnlineMeetingWrapper: React.FC = () => {
  return (
    <OnlineMeetingStateProvider>
      <Outlet />
    </OnlineMeetingStateProvider>
  );
};

export default OnlineMeetingWrapper;
