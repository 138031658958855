import { Input } from 'antd';
import { TableHeaderProps } from '../../../constants/tableData';

const { Search } = Input;

const TableHeader = ({
  column,
  search,
  handleSearch,
  confirmSearch,
  handleSort,
  sort,
  order,
}: TableHeaderProps) => {
  return (
    <span key={column.id}>
      <div
        className='ant-table-column-sorters ant-tooltip-open'
        onClick={() => handleSort!(column, order)}
        key={column.id}
      >
        <span
          className={`ant-table-column-title z-[9999] font-bold ${
            column.render('id') === 'actions' && 'text-center'
          }`}
        >
          {column.render('Header')}
        </span>
        {column.hasSort === true ? (
          <span className='ant-table-column-sorter ant-table-column-sorter-full z-[9999] w-10 flex justify-center'>
            <span className='ant-table-column-sorter-inner'>
              <span
                role='img'
                aria-label='caret-up'
                className={
                  order !== ''
                    ? order === 'asc' && column.sortName === sort
                      ? `anticon anticon-caret-up ant-table-column-sorter-up active`
                      : `anticon anticon-caret-up ant-table-column-sorter-up`
                    : `anticon anticon-caret-up ant-table-column-sorter-up`
                }
              >
                <svg
                  viewBox='0 0 1024 1024'
                  focusable='false'
                  data-icon='caret-up'
                  width='1em'
                  height='1em'
                  fill='currentColor'
                  aria-hidden='true'
                >
                  <path d='M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z'></path>
                </svg>
              </span>
              <span
                role='img'
                aria-label='caret-down'
                className={
                  order !== ''
                    ? order === 'desc' && column.sortName === sort
                      ? `anticon anticon-caret-up ant-table-column-sorter-up active`
                      : `anticon anticon-caret-up ant-table-column-sorter-up`
                    : `anticon anticon-caret-up ant-table-column-sorter-up`
                }
              >
                <svg
                  viewBox='0 0 1024 1024'
                  focusable='false'
                  data-icon='caret-down'
                  width='1em'
                  height='1em'
                  fill='currentColor'
                  aria-hidden='true'
                >
                  <path d='M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z'></path>
                </svg>
              </span>
            </span>
          </span>
        ) : null}
      </div>
      {column.hasSearch && (
        <Search
          className='rounded-md w-40 z-50 mt-2 mx-auto ml-0 flex justify-center'
          placeholder='Pretraži...'
          value={search[column.id]}
          // onChange={handleSearch(column.id)}
          // onSearch={confirmSearch}
        />
      )}
    </span>
  );
};

export default TableHeader;
