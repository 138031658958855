import { Badge, Button, Divider, Typography } from 'antd';

import { TiMessages } from 'react-icons/ti';
import React, { ForwardRefExoticComponent, useContext } from 'react';
import { IconContext } from 'react-icons';
import { BasicProps } from 'antd/lib/layout/layout';
import { FaWpforms } from 'react-icons/fa';
import { IoExitOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import Logo from '../../../../../assets/stetoskop-telemedicina-logo.png';
import MiniLogo from '../../../../../assets/logo.png';
import useWindowSize, { windowSizeType } from 'src/hooks/useWindowSize';
import { OnlineMeetingContext } from 'src/contexts/OnlineMeetingState';

interface Props {
  toggleSider: (type: 'chat' | 'form') => void;
  Header: ForwardRefExoticComponent<
    BasicProps & React.RefAttributes<HTMLElement>
  >;
  showCall: boolean;
  leaveCall: any;
  drawerType: 'chat' | 'form';
  supportPhoneNumber: any;
  handleMessagesCount: (seen: boolean, count?: number) => void;
  messagesCount: number;
}

const Navbar: React.FC<Props> = ({
  toggleSider,
  Header,
  showCall,
  leaveCall,
  drawerType,
  supportPhoneNumber,
  handleMessagesCount,
  messagesCount,
}: Props) => {
  const { updateOnlineMeetingState, collapsed } =
    useContext(OnlineMeetingContext);

  const { Title } = Typography;

  // const navigate = useNavigationHook();

  // const redirect = (path: string) => {
  //   navigate(path);
  // };
  const redirect = useNavigate();
  const windowSize = useWindowSize();

  const token =
    localStorage.getItem('daily_auth_token') ??
    window.location.pathname.split('/')[2];

  const leave = () => {
    leaveCall();
    window.location.href = `${
      window.location.href.split('/online-meeting/')[0]
    }/rating/${token}`;
  };

  const leaveClassName: Record<windowSizeType, string> = {
    'wide-desktop': 'my-5 ml-2',
    desktop: 'my-5 ml-2',
    tablet: 'my-5',
    mobile: 'my-5 p-0',
    none: 'my-5 p-0',
  };

  const buttonsClassName: Record<windowSizeType, string> = {
    'wide-desktop': 'my-5 mx-2',
    desktop: 'my-5 mx-2',
    tablet: 'my-5',
    mobile: 'my-5 mx-2',
    none: 'my-5 mx-2',
  };

  const headerClassName: Record<windowSizeType, string> = {
    'wide-desktop': '',
    desktop: '',
    tablet: 'pl-2',
    mobile: 'pl-2 rounded-md',
    none: 'pl-2 rounded-md',
  };

  const supportNumClassName: Record<windowSizeType, string> = {
    'wide-desktop': 'text-sm',
    desktop: 'text-sm',
    tablet: 'text-xs w-[28%] mx-2',
    mobile: 'text-xs w-[28%] mx-2',
    none: 'text-xs w-[28%] mx-2',
  };

  return (
    <Header
      className={`w-[100vw] bg-white flex items-center justify-between pr-2 opacity-90 ${headerClassName[windowSize]}`}
    >
      {windowSize === 'tablet' || windowSize === 'mobile' ? (
        <img src={MiniLogo} width="50px" />
      ) : (
        <img src={Logo} width="180px" />
      )}
      {supportPhoneNumber?.displayed && windowSize !== 'mobile' ? (
        <Typography
          className={`text-primary-text ${supportNumClassName[windowSize]}`}
        >
          Za podršku pozovite{' '}
          <a
            className="inline p-0"
            href={`tel:${supportPhoneNumber?.normalized}`}
          >
            {supportPhoneNumber?.displayed}
          </a>
          {windowSize !== 'tablet' ? ', ili pritisnite dugme za pomoć.' : ''}
        </Typography>
      ) : (
        <a
          className="inline p-0"
          href={`tel:${supportPhoneNumber?.normalized}`}
        >
          {supportPhoneNumber?.displayed}
        </a>
      )}
      {showCall && (
        <div>
          <>
            <Button
              className={`w-auto h-auto border-0 shadow-none hover:text-[#EB008B] ${
                buttonsClassName[windowSize]
              } ${
                !collapsed && drawerType === 'form'
                  ? 'text-[#EB008B]'
                  : 'text-primary-text'
              }`}
              onClick={() => toggleSider('form')}
            >
              <IconContext.Provider
                value={{
                  className: 'm-auto w-5 h-5',
                }}
              >
                <FaWpforms /> Podaci
              </IconContext.Provider>
            </Button>
            {windowSize !== 'mobile' && (
              <Badge offset={[-20, 25]} count={messagesCount}>
                <Button
                  className={`w-auto h-auto border-0 shadow-none hover:text-[#EB008B] ${
                    buttonsClassName[windowSize]
                  } ${
                    !collapsed && drawerType === 'chat'
                      ? 'text-[#EB008B]'
                      : 'text-primary-text'
                  }`}
                  onClick={() => toggleSider('chat')}
                >
                  <IconContext.Provider
                    value={{
                      className: 'm-auto w-5 h-5',
                    }}
                  >
                    <TiMessages /> Poruke
                  </IconContext.Provider>
                </Button>
              </Badge>
            )}
          </>

          {windowSize !== 'mobile' && (
            <Divider
              className="h-[100%] border-l-gray-300 border-l-[1.5px]"
              type="vertical"
            />
          )}
          <Button
            className={`w-auto h-auto border-0 shadow-none text-primary-text hover:text-[#FF0000] ${leaveClassName[windowSize]}`}
            onClick={leave}
          >
            <IconContext.Provider
              value={{
                className: 'm-auto w-5 h-5',
                color: '#FF0000',
              }}
            >
              <IoExitOutline />
              Napusti poziv
            </IconContext.Provider>
          </Button>
        </div>
      )}
      {/* {windowSize == 'mobile' && (
        <div className="fixed top-[calc(100vh-150px)] right-8 z-[9999] rounded-full w-16 h-16 bg-white flex justify-center items-center shadow-lg">
          <Button
            className={` rounded-full w-fit h-fit p-0 border-0 shadow-none hover:text-[#EB008B] ${
              buttonsClassName[windowSize]
            } ${
              !collapsed && drawerType === 'chat'
                ? 'text-[#EB008B]'
                : 'text-primary-text'
            }`}
            onClick={() => toggleSider('chat')}
          >
            <IconContext.Provider
              value={{
                className: 'm-auto w-5 h-5',
              }}
            >
              <TiMessages /> Poruke
            </IconContext.Provider>
          </Button>
        </div>
      )} */}
    </Header>
  );
};

export default Navbar;
