import * as XLSX from 'xlsx';
import { DatePicker, Select } from 'antd';
import { CEFormItem } from '../../Doctors/constants/doctorsData';

const { RangePicker } = DatePicker;

export const getTicketsByDate = (params: any) => {
  return {
    method: 'get',
    url: 'agent/ticketsByDate',
    params: params,
    withCredentials: true,
  };
};

export const allMeetings = (data: any) => {
  const temp: any = [];
  data.map((ticket: any) => {
    if (ticket.statusName === 'Završen') {
      temp.push({
        'Ime doktora': ticket.doctor,
        'Ime pacijenta': ticket.firstName + ' ' + ticket.lastName,
        'Datum konsultacije': ticket.consultDate.format('DD.MM.YYYY'),
        'Vreme konsultacije': ticket.consultTime.format('HH:mm'),
        'Trajanje konsultacije': ticket.durationTime,
      });
    }
  });
  return temp;
};

export const calculateMeetingsByDoctor = (data: any) => {
  const tempData: any = [];
  data.map((ticket: any) => {
    if (ticket.statusName === 'Završen') {
      tempData.push({
        'Ime doktora': ticket.doctor,
        'Ukupno trajanje svih konsultacija': ticket.durationTime,
        'Ukupan broj konsultacija': 1,
        'Prosek minuta po konsultaciji': 1,
      });
    }
  });
  const summed = tempData.reduce((acc: any, cur: any) => {
    const item =
      acc.length > 0 && acc.find((doctor: any) => doctor['Ime doktora'] === cur['Ime doktora']);
    if (item) {
      item['Ukupno trajanje svih konsultacija'] += cur['Ukupno trajanje svih konsultacija'];
      item['Ukupan broj konsultacija']++;
      item['Prosek minuta po konsultaciji'] =
        item['Ukupno trajanje svih konsultacija'] / item['Ukupan broj konsultacija'];
    } else
      acc.push({
        'Ime doktora': cur['Ime doktora'],
        'Ukupno trajanje svih konsultacija': cur['Ukupno trajanje svih konsultacija'],
        'Ukupan broj konsultacija': cur['Ukupan broj konsultacija'],
        'Prosek minuta po konsultaciji':
          cur['Ukupno trajanje svih konsultacija'] / cur['Ukupan broj konsultacija'],
      });
    return acc;
  }, []);
  return summed;
};

export const analyticsForm: Array<CEFormItem> = [
  {
    id: 1,
    name: 'analyticsDate',
    text: 'Raspon datuma',
    required: true,
    message: 'Molimo Vas unesite datum!',
    placeholder: ['Početak', 'Kraj'],
    separator: '',
    tooltip: 'Odaberite raspon datuma za koji želite da izvezete podatke!',
    type: RangePicker,
  },
  {
    id: 2,
    name: 'analyticsType',
    text: 'Tip podataka',
    placeholder: 'Tip podataka',
    required: true,
    message: 'Molimo Vas odaberite tip podataka!',
    tooltip: 'Odaberite tip podataka za prethodno odabrani datum!',
    options: [
      { id: 1, value: 'statConsult', label: 'Statistika konsultacija' },
      { id: 2, value: 'statConsultPerDoctor', label: 'Statistika konsultacija po doktoru' },
    ],
    type: Select,
  },
];

export const handleExcelFileExport = (data: any, exportName: string) => {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data);

  const autoColumnSize = (data: any) => {
    return Object.keys(data[0]).map((key: any) => ({
      wch: key.toString().length > 22 ? key.toString().length : 22,
    }));
  };
  worksheet['!cols'] = autoColumnSize(data);

  XLSX.utils.book_append_sheet(workbook, worksheet);
  XLSX.writeFile(workbook, exportName);
};
