import { createContext, useReducer } from 'react';
import {
  FormItem,
  MedFieldAnswers,
} from '../pages/PatientWebForm/constants/stepsData';

import { WebFormReducer } from '../reducers/WebFormReducer';

let tempPatientData: any = {};
let tempMedFieldAnswers: Array<MedFieldAnswers> = [];

export const formItemsStateMapper = (
  formItems: Array<FormItem> | undefined
) => {
  if (formItems) {
    const tempFormIds: any = formItems?.map((item) => {
      return item?.id;
    });
    const tempFormValues = formItems?.map((item) => {
      return item?.initiallyDisplayed;
    });
    for (let i: number = 0; i < formItems?.length; i++) {
      tempPatientData[tempFormIds[i]] = tempFormValues[i];
    }
  }
  return tempPatientData;
};

export const medFieldAnswersMapper = (
  formItems: Array<FormItem> | undefined
) => {
  if (formItems) {
    tempMedFieldAnswers = formItems.map((item) => {
      return {
        ticketId: 0,
        questionId: item.id,
        text: '',
        date: '',
        selectedAnswers: [],
        selectedAnswerIds: [],
        files: [],
      };
    });
    return tempMedFieldAnswers;
  }
};

const initialState: any = {
  patientData: tempPatientData,
  step: sessionStorage.getItem('ticket_id') ? 2 : 1,
  ticketAnswers: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    medicineFieldId: null,
    doctorId: null,
    birthDate: '',
    gender: null,
  },
  medFieldAnswers: tempMedFieldAnswers,
};

export const FieldEnableContext = createContext(initialState);

export const WebFormStateProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(WebFormReducer, initialState);

  const updateWebFormState = (payload: any, type?: string) => {
    dispatch({
      type,
      payload,
    });
  };

  return (
    <FieldEnableContext.Provider
      value={{
        patientData: state.patientData,
        step: state.step,
        ticketAnswers: state.ticketAnswers,
        medFieldAnswers: state.medFieldAnswers,
        updateWebFormState,
      }}
    >
      {children}
    </FieldEnableContext.Provider>
  );
};
