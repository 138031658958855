import { setupAxios } from '../../axios/setupAxios';
import { IUser } from '../Admin/constants/usersInterfaces';
import { Pageable } from '../Operator/constants/operatorPages';
import { ITicketsConsult } from '../Operator/pages/Tickets/interfaces/TicketsInterfaces';

const axiosData = (
  type: string,
  endpoint: string,
  pageable?: Pageable,
  data?: any,
  credentials?: boolean
) => {
  return {
    method: type,
    url: `${endpoint}`,
    data,
    params: pageable,
    withCredentials: credentials !== undefined ? credentials : true,
  };
};

export const API_AGENT = {
  getAllStatuses: async () => {
    const response = await setupAxios(axiosData('get', 'agent/enums'));
    return response.data;
  },
  getNotificationsCount: async () => {
    const response = await setupAxios(axiosData('get', 'agent/notifications/count'));
    return response.data;
  },
  getAllNotifications: async (params: Pageable) => {
    const response = await setupAxios(axiosData('get', 'agent/notifications', params));
    return response.data;
  },
  patchNotifications: async (notificationsIds: any) => {
    const response = await setupAxios(
      axiosData('patch', `agent/notifications?notificationIds=${notificationsIds}`)
    );
    return response.data;
  },
  readAllNotifications: async () => {
    const response = await setupAxios(axiosData('patch', `agent/notifications/clear`));
    return response.data;
  },
  editDoctorStatus: async ({ doctor, id }: any) => {
    const response = await setupAxios(
      axiosData('patch', `agent/doctors/${id}/enabled`, undefined, doctor)
    );
    return response.data;
  },
  editTicketStatus: async ({ id, status }: any) => {
    const response = await setupAxios(
      axiosData('patch', `agent/tickets/${id}/status`, undefined, status)
    );
    return response.data;
  },
  getTicket: async (id: any) => {
    const response = await setupAxios(axiosData('get', `agent/tickets/${id}`));
    return response.data;
  },
  getTicketMessages: async (id: any) => {
    const response = await setupAxios(axiosData('get', `agent/messages/${id}`));
    return response.data;
  },
  editTicket: async ({ data, id }: any) => {
    const response = await setupAxios(axiosData('put', `agent/tickets/${id}`, undefined, data));
    return response.data;
  },
  editConsult: async (data: ITicketsConsult) => {
    const response = await setupAxios(axiosData('put', 'agent/meetings', undefined, data));
    return response.data;
  },
  editTicketAnswers: async (data: any) => {
    const response = await setupAxios(axiosData('put', 'agent/answers', undefined, data));
    return response.data;
  },
};

export const API_ADMIN = {
  postUser: async (user: IUser) => {
    const response = await setupAxios(axiosData('post', 'admin/users', undefined, user));
    return response.data;
  },
  editUser: async ({ user, id }: any) => {
    const response = await setupAxios(axiosData('put', `admin/users/${id}`, undefined, user));
    return response.data;
  },
  editUserPassword: async ({ user, id }: any) => {
    const response = await setupAxios(axiosData('patch', `admin/users/${id}`, undefined, user));
    return response.data;
  },
  editUserStatus: async ({ user, id }: any) => {
    const response = await setupAxios(
      axiosData('patch', `admin/users/${id}/enabled`, undefined, user)
    );
    return response.data;
  },
};

export const API_PUBLIC = {
  getPatientInfo: async (token: string) => {
    const response = await setupAxios(
      axiosData('get', `api/meetings/patient-info/${token}`, undefined, undefined, false)
    );
    return response.data;
  },
};
