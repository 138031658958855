import { Typography } from 'antd';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { IconContext } from 'react-icons';
import { RiFileDownloadFill } from 'react-icons/ri';
import { OnlineMeetingContext } from 'src/contexts/OnlineMeetingState';

interface Props {
  user: { id: string | number; name: string };
  scrollToBottom: () => void;
  messagesEndRef: any;
}

const TextMessages: React.FC<Props> = ({ user, scrollToBottom, messagesEndRef }: Props) => {
  const { messages } = useContext(OnlineMeetingContext);
  const token = localStorage.getItem('be_auth_token') ?? window.location.pathname.split('/')[2];

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  return (
    <div className='overflow-auto h-full'>
      {messages?.length !== 0 &&
        messages?.map((message: any, index: number) => (
          <div key={index}>
            {message?.type === 'CHAT' || message?.type === 'FILE' ? (
              <div key={index}>
                {message?.sentTime && index === 0 ? (
                  <Typography className='my-2 text-primary-text text-center'>
                    {moment(message?.sentTime).format('HH:mm')}
                  </Typography>
                ) : moment(message?.sentTime).format('HH:mm') !==
                  moment(messages[index - 1].sentTime).format('HH:mm') ? (
                  <Typography className='my-2 text-primary-text text-center'>
                    {moment(message?.sentTime).format('HH:mm')}
                  </Typography>
                ) : (
                  ''
                )}
                <Typography
                  className={`mx-4 font-bold text-primary-text ${
                    message?.senderName === user.name ? 'text-right' : ''
                  }`}
                >
                  {message?.senderName}
                </Typography>
                <div
                  key={index}
                  className={`mb-6 py-2 px-4 rounded-xl mx-4 max-w-[300px] w-fit ${
                    message?.senderName === user.name
                      ? 'bg-secondary-bg rounded-br-none ml-[auto]'
                      : 'bg-primary-bg rounded-tl-none'
                  }`}
                >
                  <Typography
                    className={`${
                      // message?.senderName === user.name
                      //   ? 'text-black'
                      // :
                      'text-white'
                    }`}
                  >
                    {message?.text}
                  </Typography>
                  <div key={index} className='flex flex-wrap'>
                    {message?.files?.length !== 0 &&
                      message?.files?.map((file: any) => (
                        <a
                          className='pointer hover:bg-gray-100 transition flex items-center break-all w-fit bg-gray-200 rounded-md px-2 m-1'
                          href={
                            !file?.id
                              ? `data:${file?.contentType};base64,${file?.encodedContent}`
                              : `${file?.url}?token=${token}`
                          }
                          download={
                            !file?.id
                              ? file?.name
                              : file?.contentType === 'application/msword'
                              ? 'download.doc'
                              : file?.contentType ===
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                              ? 'download.docx'
                              : file?.name
                          }
                        >
                          {console.log(file)}
                          <IconContext.Provider
                            value={{
                              className: 'm-2 ml-0 h-5 w-5',
                              color: '#f14dae',
                            }}
                          >
                            <RiFileDownloadFill />
                            <Typography className='text-primary-text mr-2'>{file?.name}</Typography>
                          </IconContext.Provider>
                        </a>
                      ))}
                  </div>
                </div>
              </div>
            ) : (
              <Typography className='my-2 text-primary-text text-center'>
                {message?.sentTime && index === 0 ? (
                  <Typography className='my-2 text-primary-text text-center'>
                    {moment(message?.sentTime).format('HH:mm')}
                  </Typography>
                ) : moment(message?.sentTime).format('HH:mm') !==
                  moment(messages[index - 1].sentTime).format('HH:mm') ? (
                  <Typography className='my-2 text-primary-text text-center'>
                    {moment(message?.sentTime).format('HH:mm')}
                  </Typography>
                ) : (
                  ''
                )}
                {message?.text}
              </Typography>
            )}
          </div>
        ))}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default TextMessages;
