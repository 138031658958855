import axios from 'axios';

const URL_ROOT = `${process.env.REACT_APP_BASE_URL}${
  process.env.REACT_APP_BASE_PORT ? ':' + process.env.REACT_APP_BASE_PORT : ''
}`;

const api = axios.create({
  baseURL: URL_ROOT,
});

export const getChatInfo = (dailyToken: string | null) => {
  return api({
    method: 'get',
    url: `api/meetings/info/${dailyToken}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
};
