import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useMutation } from 'react-query';
import {
  ErrorSnackbar,
  ISnackbarMutation,
  SuccessSnackbar,
} from 'src/components/Snackbar/Snackbar';

const useMutateData = (
  axiosData: Function,
  snackbarProperties: ISnackbarMutation,
  refetch?: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<any, unknown>>
) => {
  return useMutation((data: any) => axiosData(data), {
    onSuccess: () => {
      refetch && refetch!();
      SuccessSnackbar({
        message: snackbarProperties.onSuccessMessage,
      });
    },
    onError: (error: any) => {
      ErrorSnackbar({
        message: snackbarProperties.onErrorMessage,
        description: error.response.data.message,
      });
    },
  });
};

export default useMutateData;
