import { ReactNode } from 'react';
import Step1 from '../components/Step1/Step1';
import Step2 from '../components/Step2/Step2';
import Success from '../components/Success/Success';

const step1 = () => {
  return <Step1 />;
};

const step2 = () => {
  return <Step2 />;
};

const success = () => {
  return <Success />;
};

export const steps: Record<number, () => ReactNode> = {
  1: step1,
  2: step2,
  3: success,
};
