import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Input,
  InputNumber,
  message,
  notification,
  Select,
  Upload,
} from 'antd';
import moment from 'moment';
import 'moment/locale/sr';

moment.locale('sr');

const allowedContentTypes = [
  'image/jpeg',
  'image/png',
  'image/webp',
  'image/gif',
  'image/tiff',
  'text/plain',
  'application/zip',
  'application/x-7z-compressed',
  'application/x-tar',
  'application/vnd.rar',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.oasis.opendocument.text',
  'video/mpeg',
  'video/mp4',
  'video/x-msvideo',
  'video/ogg',
  'video/webm',
];

const beforeUpload = (file: any) => {
  let fileNameArr = file?.name?.split('.');
  if (
    allowedContentTypes.includes(file?.type) ||
    fileNameArr[fileNameArr.length - 1] === 'doc' ||
    fileNameArr[fileNameArr.length - 1] === 'docx'
  ) {
    return file;
  }
  notification.config({
    duration: 3,
  });
  notification['error']({
    message: 'Nepodržani tip fajla',
    description:
      'Izbrišite fajl i prosledite drugi tipa .pdf, .doc, .docx, .txt, .mp4... ',
    placement: 'top',
  });
  return false;
};

const dummyRequest = ({ file, onSuccess }: any) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};

// const props = {
//   name: 'file',
//   customRequest: dummyRequest,
//   className: 'rounded-sm w-[300px]',
// };

export const UploadComponent = (props: any) => {
  return (
    <Upload
      name="file"
      beforeUpload={beforeUpload}
      customRequest={dummyRequest}
      className="rounded-md w-[300px]"
      {...props}
    >
      <Button
        {...props}
        className="rounded-md w-[300px] text-zinc-400 text-left px-3 flex justify-between text-sm items-center"
      >
        {props?.placeholder ?? 'Kliknite da dodate dokument'}
        <UploadOutlined className="mt-0.5" />
      </Button>
    </Upload>
  );
};

export const ModUploadComponent = (props: any) => {
  return (
    <Upload
      name="file"
      beforeUpload={beforeUpload}
      customRequest={dummyRequest}
      className="rounded-md w-[16vw]"
      {...props}
    >
      <Button
        {...props}
        className="rounded-md text-zinc-400 w-[16vw] text-left px-3 flex justify-between text-sm items-center overflow-hidden"
      >
        {props?.placeholder ?? 'Kliknite da dodate dokument'}
        <UploadOutlined className="mt-0.5" />
      </Button>
    </Upload>
  );
};

export const basicInfo: Array<FormItem> | any = [
  {
    id: 11,
    name: 'firstName',
    text: 'Ime',
    required: true,
    message: 'Molimo Vas unestite Vaše ime!',
    type: Input,
    initiallyDisplayed: true,
  },
  {
    id: 12,
    name: 'lastName',
    text: 'Prezime',
    required: true,
    message: 'Molimo Vas unestite Vaše prezime!',
    type: Input,
    initiallyDisplayed: true,
  },
  {
    id: 13,
    name: 'phoneNumber',
    text: 'Kontakt telefon',
    required: true,
    message: 'Molimo Vas unestite Vaš kontakt telefon!',
    type: Input,
    initiallyDisplayed: true,
  },
  {
    id: 14,
    name: 'email',
    text: 'Email',
    required: false,
    message: 'Molimo Vas unestite Vašu email adresu!',
    type: Input,
    initiallyDisplayed: true,
  },
  {
    id: 15,
    name: 'birthDate',
    text: 'Datum rođenja',
    required: false,
    message: 'Molimo Vas unestite datum Vašeg rođenja!',
    type: DatePicker,
    initiallyDisplayed: true,
  },
  {
    id: 16,
    name: 'gender',
    text: 'Pol',
    required: false,
    message: 'Molimo Vas unestite Vaš pol!',
    type: Select,
    optionsHook: 'allGenders',
    initiallyDisplayed: true,
  },
  {
    id: 17,
    name: 'medicineFieldId',
    text: 'Grana medicine',
    required: true,
    message: 'Molimo Vas unestite granu medicine!',
    type: Select,
    optionsHook: 'allMedFields',
    initiallyDisplayed: true,
  },
  {
    id: 18,
    name: 'doctorId',
    text: 'Izaberite lekara *',
    required: true,
    message: 'Molimo Vas izaberite lekara!',
    type: Select,
    optionsHook: 'allDoctors',
    initiallyDisplayed: false,
  },
];

export interface FormItem {
  id: number;
  name: string;
  text: string;
  required: boolean;
  message: string;
  type: any;
  component?: any;
  options?: Array<Options>;
  optionsHook: string;
  initiallyDisplayed: boolean;
  questionId: number;
  answer: any;
}

export interface Doctor {
  id: number;
  firstName?: string;
  lastName?: string;
  title?: string;
  value?: string;
}

export interface MedField {
  id: number;
  name: string;
  value?: string;
  label?: string;
}

export interface Gender {
  id?: number;
  name: string;
  value?: string;
}

export interface Options {
  id: number;
  value?: string;
  text?: string;
  name?: string;
  triggersQuestions?: Array<number>;
}

export interface TicketAnswers {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  medicineFieldId?: MedField | number;
  doctorId?: Doctor | number;
  birthDate?: any;
  gender?: any;
}

export interface MedFieldAnswers {
  ticketId: number;
  questionId: number;
  text: any;
  date?: any;
  selectedAnswers: Array<any>;
  selectedAnswerIds: Array<number>;
  files: Array<any>;
}

export type ComponentType =
  | 'SINGLE_CHOICE'
  | 'MULTIPLE_CHOICE'
  | 'FREE_TEXT'
  | 'FILE_UPLOAD'
  | 'DECIMAL'
  | 'INTEGER'
  | 'DATE';

export interface Components {
  component?: any;
}

export const components: Record<ComponentType, Components> = {
  SINGLE_CHOICE: { component: Select },
  MULTIPLE_CHOICE: { component: Select },
  FREE_TEXT: { component: Input },
  FILE_UPLOAD: { component: UploadComponent },
  DECIMAL: { component: InputNumber },
  INTEGER: { component: InputNumber },
  DATE: { component: DatePicker },
};

export const updatedForm: TicketAnswers = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  medicineFieldId: {
    id: 1,
    name: 'Gastroenterologija',
    value: 'Gastroenterologija',
  },
  doctorId: undefined,
};
