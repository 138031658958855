import { IRatingData } from 'src/pages/Rating/constants/ratingInterfaces';

const RatingReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'RATE_MEETING':
      const updatedRating: IRatingData = action.payload;
      return { ...state, ratingData: updatedRating };
    default:
      return state;
  }
};

export { RatingReducer };
