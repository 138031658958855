import { useQuery } from 'react-query';
import { setupAxios } from '../axios/setupAxios';
import { FormItem } from '../pages/PatientWebForm/constants/stepsData';

const useForm = (key: string, getFunction: Function, mapper: Function, params?: any) => {
  return useQuery(
    key,
    () =>
      setupAxios(getFunction(params)).then(response => {
        const tempData: Array<FormItem> = response.data.map((item: any) => {
          return mapper(item);
        });
        return tempData;
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
};

export default useForm;
