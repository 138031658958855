import { useMutation } from 'react-query';
import { setupAxios } from '../../../axios/setupAxios';
import {
  ErrorSnackbar,
  ISnackbarMutation,
  SuccessSnackbar,
} from '../../../components/Snackbar/Snackbar';

const usePostInfo = (
  postFunction: Function,
  redirect: Function,
  path: string,
  snackbarProperties: ISnackbarMutation
) => {
  return useMutation((data: any) => setupAxios(postFunction(data)), {
    onSuccess: response => {
      localStorage.setItem('telemedicina_access_token', response?.data?.accessToken);
      redirect(path);
      SuccessSnackbar({
        message: snackbarProperties.onSuccessMessage,
      });
    },
    onError: (error: any) => {
      ErrorSnackbar({
        message: snackbarProperties.onErrorMessage,
        description: error.response.data.message,
      });
    },
  });
};

export default usePostInfo;
