import { Badge, Button, Input, Popover, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { UploadChangeParam } from 'antd/lib/upload';
import axios from 'axios';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { FaFileUpload } from 'react-icons/fa';
import { FiSend } from 'react-icons/fi';
import { OnlineMeetingContext } from 'src/contexts/OnlineMeetingState';
import { DeleteOutlined } from '@ant-design/icons';
import { RiSendPlaneFill } from 'react-icons/ri';

interface Props {
  sendMessage: (message: string, uploadFile?: any) => void;
  handleChatInput: (event: any) => void;
  message: string;
  base64URL: string;
  fileList: Array<any>;
  dummyRequest: ({ file, onSuccess }: any) => void;
  handleFileInputChange: (
    event: UploadChangeParam<UploadFile<unknown>>
  ) => void;
  clearFiles: () => void;
  beforeUpload: ({ file }: any) => void;
}

const ChatInput: React.FC<Props> = ({
  sendMessage,
  handleChatInput,
  message,
  base64URL,
  fileList,
  dummyRequest,
  handleFileInputChange,
  clearFiles,
  beforeUpload,
}: Props) => {
  const { files } = useContext(OnlineMeetingContext);

  const [uploadList, setUploadList] = useState(false);

  useEffect(() => {
    if (files.length === 0) {
      setUploadList(false);
    }
  }, [files]);

  return (
    <div className="p-4 bg-secondary-bg flex justify-between items-center">
      <Popover
        placement="left"
        content={
          files.length !== 0 && <a onClick={clearFiles}>Obriši fajlove</a>
        }
      >
        <Upload
          customRequest={dummyRequest}
          beforeUpload={beforeUpload}
          onChange={handleFileInputChange}
          fileList={files}
          showUploadList={false}
        >
          <Badge count={files.length}>
            <Button
              className="mx-2 bg-slate-200 w-[40px] h-[40px]"
              shape="circle"
              icon={
                <IconContext.Provider
                  value={{
                    className: 'm-auto h-full',
                    color: '#f14dae',
                  }}
                >
                  <FaFileUpload />
                </IconContext.Provider>
              }
            />
          </Badge>
        </Upload>
      </Popover>
      <Input
        placeholder="Napiši poruku..."
        size="large"
        className="mx-2 rounded-xl w-[calc(100%-130px)] text-primary-text"
        value={message}
        onChange={handleChatInput}
        onKeyPress={(event) => {
          if (event.key === 'Enter') sendMessage(message, files);
        }}
      />
      <Button
        className="ml-2 bg-slate-200 w-[40px] h-[40px]"
        shape="circle"
        onClick={() => sendMessage(message, files)}
        icon={
          <IconContext.Provider
            value={{
              className: 'm-auto h-full',
              color: '#f14dae',
              size: '25',
            }}
          >
            <RiSendPlaneFill />
          </IconContext.Provider>
        }
      />
    </div>
  );
};

export default ChatInput;
