import { Modal } from 'antd';
import React, { Suspense, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../../components/CustomComponents/CustomButton/CustomButton';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import { OperatorContext } from '../../../../contexts/OperatorState';
import { LoadingScreen } from '../../../../core/components/LoadingScreen';
import PublicWrapper from '../../../../core/components/PublicWrapper';
import usePostInfo from '../../hooks/usePostInfo';

import LoginContent from './components/LoginContent';
import { FooterComponent, TitleComponent } from './components/ModalComponents';
import {
  loginCredentialsMapper,
  postLoginCredentials,
} from './helpers/loginHelpers';

const Login: React.FC = () => {
  const { loginCredentials } = useContext(OperatorContext);

  // const navigate = useNavigationHook();

  // const redirect = (path: string) => {
  //   navigate(path);
  // };

  const redirect = useNavigate();

  const postLogin = usePostInfo(
    postLoginCredentials,
    redirect,
    '/operator/tickets',
    {
      onSuccessMessage: 'Uspešna prijava!',
      onErrorMessage: 'Neuspešna prijava!',
    }
  );

  const handleLogin = () => {
    const mappedLoginCredentials = loginCredentialsMapper(loginCredentials);
    postLogin.mutate(mappedLoginCredentials);
  };

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingScreen />}>
        <PublicWrapper />
        <Modal
          className="w-full md:w-[500px] m-auto"
          visible={true}
          closable={false}
          centered={true}
          title={<TitleComponent />}
          footer={
            <FooterComponent
              handleLogin={handleLogin}
              isLoading={postLogin.isLoading}
            />
          }
        >
          <LoginContent onKeyPress={handleLogin} />
        </Modal>
        <CustomButton
          ghost
          type="default"
          text="Nazad na upitnik"
          className="absolute z-[10000] bottom-8 right-8"
          onClick={() => redirect('/')}
        />
      </Suspense>
    </ErrorBoundary>
  );
};

export default Login;
