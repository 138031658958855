import { useQuery } from 'react-query';
import { setupAxios } from 'src/axios/setupAxios';
import { Gender } from '../constants/stepsData';

const useGenders = (
  key: string,
  getFunction: Function,
  mapper: Function,
  params?: any
) => {
  return useQuery(
    key,
    () =>
      setupAxios(getFunction(params)).then((response) => {
        const tempData: Array<Gender> = response?.data?.Gender?.map(
          (item: any) => {
            return mapper(item);
          }
        );
        return tempData;
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
};

export default useGenders;
