import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { api } from '../axios/setupAxios';

const checkTokenValidity = () => {
  const token = localStorage.getItem('telemedicina_access_token');
  if (token) {
    try {
      const decodedToken: any = jwtDecode(token);
      const now = moment();
      const expirationTime = moment(decodedToken.exp, 'X');
      if (now.isSameOrAfter(expirationTime)) {
        api.defaults.headers.common['Authorization'] = ``;
        localStorage.removeItem(token);
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  }
  return false;
};

export { checkTokenValidity };
