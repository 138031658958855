import { Badge, Divider, Dropdown, Menu } from 'antd';
import { BasicProps } from 'antd/lib/layout/layout';
import React, { ForwardRefExoticComponent, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import checkTokenRole from '../../../utils/checkTokenRole';
import { API_AGENT } from '../../service/api_agent';
import { adminPages, operatorPages } from '../constants/operatorPages';
import MobileDrawer from '../helpers/MobileDrawer';
import { NotificationMenu } from '../helpers/NavbarHelper';
import { IoLogOutOutline, IoMenuOutline } from 'react-icons/io5';

import Logo from '../../../assets/stetoskop-telemedicina-logo.png';
import { IconContext } from 'react-icons';
import { IoIosNotificationsOutline } from 'react-icons/io';

interface Props {
  Header: ForwardRefExoticComponent<BasicProps & React.RefAttributes<HTMLElement>>;
}

const Navbar: React.FC<Props> = ({ Header }: Props) => {
  const [mobileDrawer, setMobileDrawer] = useState(false);
  const [notifications, setNotifications] = useState(0);
  const [notificationsState, setNotificationsState] = useState(false);

  const location = useLocation();
  const isAdmin = checkTokenRole();

  const redirect = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('telemedicina_access_token');
    localStorage.removeItem('ticket-option');
    localStorage.removeItem('pagination-size');
    redirect('/operator/login');
  };

  const handleNotificationsCount = (data: number) => {
    setNotifications(data);
  };

  const getNotificationsCount = useQuery('notifications-count', API_AGENT.getNotificationsCount, {
    onSuccess: data => {
      handleNotificationsCount(data.newNotificationCount);
    },
    cacheTime: 5000,
    refetchInterval: 5000,
  });

  const handleMobileDrawer = () => {
    setMobileDrawer(!mobileDrawer);
  };

  return (
    <Header className='bg-white flex items-center opacity-90'>
      {window.innerWidth > 900 ? (
        <>
          <img
            src={Logo}
            width='160px'
            className='cursor-pointer mr-10'
            onClick={() => redirect('/operator/tickets')}
          />
          <Divider className='h-[100%] border-l-gray-300 border-l-[1.5px]' type='vertical' />
          <Menu
            className='bg-transparent border-0 font-bold w-[100%]'
            mode='horizontal'
            selectedKeys={[location.pathname]}
          >
            {isAdmin
              ? adminPages.map(page => (
                  <Menu.Item
                    className='flex items-center px-7 text-primary-text'
                    onClick={() => redirect(page.path)}
                    icon={page?.icon}
                    key={page?.path}
                  >
                    {page?.label}
                  </Menu.Item>
                ))
              : null}
            {operatorPages.map(page => (
              <Menu.Item
                className='flex items-center px-7 text-primary-text'
                onClick={() => redirect(page.path)}
                icon={page?.icon}
                key={page?.path}
              >
                {page?.label}
              </Menu.Item>
            ))}
          </Menu>
        </>
      ) : (
        <>
          <div
            className='mr-auto text-primary-text hover:text-secondary-text'
            onClick={() => handleMobileDrawer()}
          >
            <IconContext.Provider value={{ className: 'mr-2', size: '30' }}>
              <IoMenuOutline />
            </IconContext.Provider>
          </div>
          <MobileDrawer drawerVisibility={mobileDrawer} handleCEDrawer={handleMobileDrawer} />
        </>
      )}
      <div className={window.innerWidth > 900 ? 'flex w-[200px] mr-5' : 'flex mr-4'}>
        <Dropdown
          overlay={
            <NotificationMenu
              count={notifications}
              handleNotificationsCount={handleNotificationsCount}
              setNotificationsState={setNotificationsState}
            />
          }
          destroyPopupOnHide
          trigger={['click']}
          arrow={{
            pointAtCenter: true,
          }}
          placement='bottom'
          onVisibleChange={state => setNotificationsState(state)}
          visible={notificationsState}
        >
          <Badge count={notifications}>
            <div
              className={`cursor-pointer flex items-center hover:text-secondary-text focus:text-secondary-text transition ${
                notificationsState ? 'text-secondary-text' : 'text-primary-text'
              }`}
            >
              <IconContext.Provider value={{ className: 'mr-2', size: '30' }}>
                <IoIosNotificationsOutline />
              </IconContext.Provider>
              {window.innerWidth > 900 ? <span className='font-bold'>Obaveštenja</span> : <span />}
            </div>
          </Badge>
        </Dropdown>
      </div>
      <div
        onClick={() => handleLogout()}
        className={
          window.innerWidth > 900
            ? 'ml-5 flex items-center w-[200px] cursor-pointer text-primary-text hover:text-secondary-text focus:text-secondary-text transition'
            : 'flex items-center cursor-pointer text-primary-text hover:text-secondary-text focus:text-secondary-text transition'
        }
      >
        <IconContext.Provider value={{ className: 'mr-2', size: '30' }}>
          <IoLogOutOutline />
        </IconContext.Provider>
        {window.innerWidth > 900 ? <span className='font-bold'>Odjavi se</span> : <span />}
      </div>
    </Header>
  );
};

export default React.memo(Navbar);
