import axios from 'axios';
import moment from 'moment';
import { DownloadSnackbar } from 'src/components/Snackbar/Snackbar';
import {
  components,
  FormItem,
  ModUploadComponent,
} from 'src/pages/PatientWebForm/constants/stepsData';

export const questionsInitialValue = (itemType: string, item: FormItem) => {
  if (itemType === 'MULTIPLE_CHOICE' || itemType === 'SINGLE_CHOICE')
    return item.answer.selectedAnswers;
  if (itemType === 'DATE') {
    if (item.answer.text === null) return '';
    return moment(item.answer.text);
  }
  if (itemType === 'FREE_TEXT' || itemType === 'INTEGER' || itemType === 'DECIMAL')
    return item.answer.text;
};

// possible feature - function for item preview in browser
const dataURItoBlob = (dataURI: string, contentType: string) => {
  const byteString = window.atob(dataURI);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: contentType });
  return blob;
};

export const downloadFile = (fileUrl: string, fileName: string) => {
  const token = localStorage.getItem('telemedicina_access_token');
  DownloadSnackbar();
  axios({
    url: fileUrl,
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
  });
};

export const questionsMapper = ({ text, type, options, questionId, answer }: FormItem) => {
  let component: any =
    type === 'SINGLE_CHOICE' || type === 'MULTIPLE_CHOICE'
      ? components.SINGLE_CHOICE?.component
      : type === 'FREE_TEXT'
      ? components.FREE_TEXT?.component
      : type === 'DECIMAL' || type === 'INTEGER'
      ? components.DECIMAL?.component
      : type === 'DATE'
      ? components.DATE?.component
      : ModUploadComponent;

  options?.map(option => {
    return (option.value = option.text);
  });

  answer.files.map((item: any) => {
    item.status = 'done';
    item.downloadUrl = item.url;
    item.url = '';
  });

  return {
    text,
    type,
    component,
    options,
    questionId,
    answer,
  };
};

export const filterFiles = (questions: any) => {
  let tempFiles: any[] = [];
  questions.fileList.map((items: any) => {
    if (items.id) {
      tempFiles.push({
        contentType: items.contentType,
        encodedContent: items.encodedContent,
        name: items.name,
        id: items.id,
      });
    } else {
      let tempValue = items.originFileObj;
      let fileType = items.type;
      let fileNameArr = items.name?.split('.');
      if (fileType === '') {
        if (fileNameArr[fileNameArr.length - 1] === 'doc') fileType = 'application/msword';
        if (fileNameArr[fileNameArr.length - 1] === 'docx')
          fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      }
      getBase64(tempValue)
        .then((result: any) => {
          tempValue['base64'] = result;
          result = result.split(';base64,')[1];
          const mappedData = {
            contentType: items.type,
            encodedContent: result,
            name: items.name,
            id: null,
          };
          tempFiles.push(mappedData);
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  });
  return tempFiles;
};

const getBase64 = (event: any) => {
  return new Promise(resolve => {
    let baseURL: any = '';
    let reader = new FileReader();

    reader.readAsDataURL(event);

    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};

export const answersDataMapper = (
  defaultQuestions: any,
  questions: any,
  id: number,
  files: any
) => {
  const mappedQuestions: any[] = [];

  const arrayOfIds = (stringOptions: string[], options: any[]) => {
    const temp: any[] = [];
    if (Array.isArray(stringOptions)) {
      stringOptions.map(stringItem => {
        temp.push(options.find(optionItem => optionItem.value === stringItem).id);
      });
    } else {
      temp.push(options.find(optionItem => optionItem.value === stringOptions).id);
    }
    return temp;
  };
  defaultQuestions.map((item: any) => {
    if (
      item.type === 'DATE' ||
      item.type === 'FREE_TEXT' ||
      item.type === 'INTEGER' ||
      item.type === 'DECIMAL'
    ) {
      if (questions[item.questionId] && questions[item.questionId] !== '')
        mappedQuestions.push({
          ticketId: id,
          questionId: item.questionId,
          text:
            item.type === 'DATE'
              ? questions[item.questionId].format('YYYY-MM-DD')
              : String(questions[item.questionId]),
        });
    }

    if (item.type === 'FILE_UPLOAD') {
      if (files.length)
        mappedQuestions.push({
          ticketId: id,
          questionId: item.questionId,
          files: files,
        });
    }

    if (item.type === 'MULTIPLE_CHOICE' || item.type === 'SINGLE_CHOICE') {
      if (questions[item.questionId].length)
        mappedQuestions.push({
          ticketId: id,
          questionId: item.questionId,
          selectedAnswerIds: arrayOfIds(questions[item.questionId], item.options),
        });
    }
  });

  return mappedQuestions;
};

export const TicketDoctorsMapper = ({ id, firstName, lastName, title }: any) => {
  const label: string = `${title} ${firstName} ${lastName}`;
  return {
    id,
    value: id,
    label,
  };
};

export const TicketMedFieldsMapper = ({ id, name }: any) => {
  const label: string = name;
  return {
    id,
    value: id,
    label,
  };
};
