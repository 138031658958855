import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Checkbox, Input } from 'antd';
import { IconContext } from 'react-icons';
import { BsKey, BsPersonCircle } from 'react-icons/bs';

const validateInput = (data: any, value: any) => {
  if (value.length <= 6)
    return Promise.reject(new Error('Lozinka mora imati minimum 6 karaktera'));
  if (!/[A-Z]/.test(value))
    return Promise.reject(new Error('Lozinka mora imati jedno veliko slovo'));
  if (!/\d/.test(value))
    return Promise.reject(new Error('Lozinka mora sadržati broj'));

  return Promise.resolve();
};

export const tempLoginCredentials: LoginCredentials = {
  email: '',
  password: '',
  rememberMe: false,
};

export const loginInputs: Array<LoginInput> = [
  {
    name: 'Email',
    type: 'email',
    component: Input,
    prefix: (
      <IconContext.Provider
        value={{
          className: 'mr-2',
          color: '#6B7280',
        }}
      >
        <BsPersonCircle />
      </IconContext.Provider>
    ),
    props: {
      name: 'email',
      key: 'email',
      rules: [
        {
          required: true,
          message: `Unesite email adresu!`,
        },
      ],
    },
  },
  {
    name: 'Lozinka',
    type: 'password',
    component: Input.Password,
    prefix: (
      <IconContext.Provider
        value={{
          className: 'mr-2',
          color: '#6B7280',
        }}
      >
        <BsKey />
      </IconContext.Provider>
    ),
    iconRender: (visible: boolean) =>
      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />,
    props: {
      name: 'password',
      key: 'password',
      rules: [
        {
          required: true,
          message: `Unesite lozinku!`,
        },
        {
          validator: validateInput,
        },
      ],
    },
  },
  {
    name: 'Zapamti me',
    type: 'checkbox',
    component: Checkbox,
    props: {
      name: 'remember',
      key: 'checkbox',
      valuePropName: 'checked',
    },
  },
];

export interface LoginCredentials {
  email: string;
  password: string;
  rememberMe?: boolean;
}

export interface LoginInput {
  name: string;
  type: string;
  component: any;
  prefix?: any;
  iconRender?: any;
  props: Props;
}

interface Props {
  name: string;
  key: string;
  valuePropName?: string;
  rules?: Array<any>;
}
