import { useEffect, useRef } from 'react';

import { useMediaTrack } from '@daily-co/daily-react-hooks';
import { IconContext } from 'react-icons';
import { IoPersonCircleOutline } from 'react-icons/io5';
import { BiMicrophoneOff } from 'react-icons/bi';
import useWindowSize, { windowSizeType } from 'src/hooks/useWindowSize';
import CountdownTImer from './CountdownTImer';

interface TileProps {
  id: string;
  isScreenShare?: boolean;
  focusMe: boolean;
  endTime: string;
}

const Tile: React.FC<TileProps> = ({
  id,
  isScreenShare,
  focusMe,
  endTime,
}: TileProps) => {
  const windowSize = useWindowSize();

  const videoTrack = useMediaTrack(id, isScreenShare ? 'screenVideo' : 'video');
  const audioTrack = useMediaTrack(id, isScreenShare ? 'screenAudio' : 'audio');

  const videoElement = useRef<any>(null);
  const audioElement = useRef<any>(null);

  useEffect(() => {
    if (videoTrack?.state === 'playable') {
      videoElement.current &&
        (videoElement.current.srcObject =
          videoTrack &&
          videoTrack.persistentTrack &&
          new MediaStream([videoTrack.persistentTrack]));
    }
  }, [videoTrack]);

  useEffect(() => {
    if (audioTrack?.state === 'playable') {
      audioElement?.current &&
        (audioElement.current.srcObject =
          audioTrack &&
          audioTrack.persistentTrack &&
          new MediaStream([audioTrack.persistentTrack]));
    }
  }, [audioTrack]);

  const tileClassName: Record<windowSizeType, string> = {
    'wide-desktop': 'w-[460px] h-[270px]',
    desktop: 'w-[375px] h-[220px]',
    tablet: 'w-[200px] h-[117px]',
    mobile: 'w-[200px] h-[117px]',
    none: 'w-[200px] h-[117px]',
  };

  return (
    <div
      className={`${
        focusMe
          ? 'absolute left-3 top-3 opacity-90 hover:opacity-100 transition z-10 ' +
            tileClassName[windowSize]
          : 'h-[calc(100vh-208px)] w-full rounded-md flex items-center text-white '
      }`}
    >
      <CountdownTImer endTime={endTime} />

      {videoTrack.isOff ? (
        // <div
        //   className={`flex justify-center items-center bg-secondary-bg text-white rounded-md ${
        //     !focusMe
        //       ? 'h-[calc(100vh-208px)] w-[calc(100vw-624px)]'
        //       : 'absolute opacity-90 hover:opacity-100 transition z-10 ' +
        //         tileClassName[windowSize]
        //   }`}
        // >
        <>
          <IconContext.Provider
            value={{
              size: '80',
            }}
          >
            <IoPersonCircleOutline />
          </IconContext.Provider>
          {audioTrack.isOff && (
            <IconContext.Provider
              value={{
                className: 'absolute bottom-2 left-2 w-8 h-8 z-10 fill-red-700',
              }}
            >
              <BiMicrophoneOff />
            </IconContext.Provider>
          )}
        </>
      ) : (
        // </div>
        <>
          <video
            className="h-full w-fit mx-auto"
            autoPlay
            muted
            playsInline
            ref={videoElement}
          />
          {audioTrack.isOff && (
            <IconContext.Provider
              value={{
                className: 'absolute bottom-2 left-2 w-8 h-8 z-10 fill-red-700',
              }}
            >
              <BiMicrophoneOff />
            </IconContext.Provider>
          )}
        </>
      )}
      {audioTrack && <audio autoPlay playsInline ref={audioElement} />}
    </div>
  );
};

export default Tile;
