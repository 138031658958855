import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

const LoadingScreen: React.FC = () => {
  const loadingIndicator: JSX.Element = <LoadingOutlined className='text-6xl' spin />;
  return (
    <div className='flex items-center justify-center h-full w-full'>
      <Spin indicator={loadingIndicator} />
    </div>
  );
};

export { LoadingScreen };
