import {
  pageUrlFromRoomUrl,
  roomUrlFromPageUrl,
} from '../../../../utils/dailyUtils';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import Call from './components/Call';
import DailyIframe from '@daily-co/daily-js';
import { DailyProvider } from '@daily-co/daily-react-hooks';
import HomeScreen from './components/HomeScreen/HomeScreen';
import { Button, Layout, Modal, Typography } from 'antd';
import Navbar from './components/Navbar';
import MeetingDrawer from './components/MeetingDrawer/MeetingDrawer';
import Tray from './components/Tray';
import onlineMeetingApi from '../../../../axios/onlineMeetingApi';
import { getChatInfo } from './components/MeetingDrawer/helpers/getChatInfo';
import { PacientForm } from './constants/onlineMeetingData';
import { Message } from './components/MeetingDrawer/constants/textChatData';
import { OnlineMeetingContext } from 'src/contexts/OnlineMeetingState';
import { messagesMapper } from './components/MeetingDrawer/helpers/textChatHelper';
import { pacientFormMapper } from './helpers/onlineMeetingHelpers';
import RatingModal from './components/RatingModal';
import useWindowSize, { windowSizeType } from 'src/hooks/useWindowSize';
import { IconContext } from 'react-icons';
import { TiMessages } from 'react-icons/ti';
import PublicWrapper from 'src/core/components/PublicWrapper';

const { Content, Sider, Header } = Layout;

const STATE_IDLE = 'STATE_IDLE';
const STATE_CREATING = 'STATE_CREATING';
const STATE_JOINING = 'STATE_JOINING';
const STATE_JOINED = 'STATE_JOINED';
const STATE_LEAVING = 'STATE_LEAVING';
const STATE_ERROR = 'STATE_ERROR';

let numberOfUnseenMessages: number = 0;

const OnlineMeeting = () => {
  const windowSize = useWindowSize();

  const [appState, setAppState] = useState(STATE_IDLE);
  const [roomUrl, setRoomUrl] = useState(null);
  const [callObject, setCallObject] = useState<any>(null);
  const [apiError, setApiError] = useState(null);

  const [drawerType, setDrawerType] = useState<'form' | 'chat'>('form');
  const [questions, setQuestions] = useState<Array<any>>([]);
  const [user, setUser] = useState({ id: '', name: '' });

  const [endTime, setEndTime] = useState('');

  const [focusMe, setFocusMe] = useState(false);

  const [ratingModal, setRatingModal] = useState(false);

  const showCall = [STATE_JOINING, STATE_JOINED, STATE_ERROR].includes(
    appState
  );

  const { updateOnlineMeetingState, messagesCount, collapsed } =
    useContext(OnlineMeetingContext);

  const changeTime = (endTime: string) => {
    setEndTime(endTime);
  };

  const messagesEndRef = useRef<any>(null);

  const dailyToken: string | null = localStorage.getItem('be_auth_token');

  const [supportPhoneNumber, setSupportPhoneNumber] = useState({
    displayed: '',
    normalized: '',
  });

  window.scrollTo(0, 0);

  const handleRatingModal = () => {
    setRatingModal(!ratingModal);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  };

  const handleMessagesCount = (seen: boolean) => {
    if (seen === true) {
      numberOfUnseenMessages = 0;
      return updateOnlineMeetingState(numberOfUnseenMessages, 'MESSAGES_COUNT');
    }
    sessionStorage.setItem(
      'messages_count',
      JSON.stringify(numberOfUnseenMessages)
    );
    return updateOnlineMeetingState(numberOfUnseenMessages, 'MESSAGES_COUNT');
  };

  const createCall = useCallback(() => {
    setAppState(STATE_CREATING);
    return onlineMeetingApi
      .createRoom()
      .then(
        (data: any) => {
          localStorage.setItem('daily_auth_token', data?.externalApiToken);
          return data?.room?.url;
        }
        // ?? 'https://stefanmatic.daily.co/Stefan-room'
      )
      .catch((error: any) => {
        setRoomUrl(null);
        setAppState(STATE_IDLE);
        setApiError(error?.response?.data?.message);
      });
  }, []);

  const startJoiningCall = useCallback((url) => {
    const token: any = localStorage.getItem('daily_auth_token');
    const newCallObject: any = DailyIframe.createCallObject();
    setRoomUrl(url);
    setCallObject(newCallObject);
    setAppState(STATE_JOINING);
    newCallObject.join({ url: url, token: token });
  }, []);

  const startLeavingCall = useCallback(() => {
    if (!callObject) return;
    if (appState === STATE_ERROR) {
      callObject.destroy().then(() => {
        setRoomUrl(null);
        setCallObject(null);
        setAppState(STATE_IDLE);
      });
    } else {
      setAppState(STATE_LEAVING);
      callObject.leave();
    }
  }, [callObject, appState]);

  useEffect(() => {
    const url = roomUrlFromPageUrl();
    url && startJoiningCall(url);
  }, [startJoiningCall]);

  useEffect(() => {
    const pageUrl = pageUrlFromRoomUrl(roomUrl);
    if (pageUrl === window.location.href) return;
    window.history.replaceState(null, '', pageUrl);
  }, [roomUrl]);

  useEffect(() => {
    if (!callObject) return;

    const events = ['joined-meeting', 'left-meeting', 'error', 'camera-error'];

    const handleNewMeetingState = () => {
      switch (callObject.meetingState()) {
        case 'joined-meeting':
          setAppState(STATE_JOINED);
          break;
        case 'left-meeting':
          callObject.destroy().then(() => {
            setRoomUrl(null);
            setCallObject(null);
            setAppState(STATE_IDLE);
          });
          break;
        case 'error':
          setAppState(STATE_ERROR);
          break;
        default:
          break;
      }
    };

    handleNewMeetingState();

    for (const event of events) {
      callObject.on(event, handleNewMeetingState);
    }

    return function cleanup() {
      for (const event of events) {
        callObject.off(event, handleNewMeetingState);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callObject]);

  const toggleSider = (type: 'chat' | 'form') => {
    if (collapsed === false && type !== drawerType) {
      setDrawerType(type);
      return;
    }
    setDrawerType(type);
    updateOnlineMeetingState(!collapsed, 'COLLAPSE_DRAWER');
    const token: any = localStorage.getItem('daily_auth_token');
    if (collapsed && type === 'chat') handleMessagesCount(true);
  };

  const toggleFocus = () => {
    setFocusMe(!focusMe);
  };

  useEffect(() => {
    const mappedQuestions: Array<any> = [];
    const mappedTextMessages: Array<Message> = [];
    if (dailyToken) {
      getChatInfo(dailyToken).then((response) => {
        setSupportPhoneNumber(response?.data?.supportPhoneNumber);
        setUser({
          id: response?.data?.participantId,
          name: response?.data?.participantName,
        });
        response?.data?.questions?.map((question: PacientForm) =>
          mappedQuestions.push(pacientFormMapper(question))
        );
        response?.data?.messages?.map((message: Message) =>
          mappedTextMessages.push(messagesMapper(message))
        );
        setEndTime(response?.data?.endTime);
      });
      setQuestions(mappedQuestions);
      updateOnlineMeetingState(mappedTextMessages, 'GET_CHAT');
    }
    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyToken]);

  // useEffect(() => {
  //   updateOnlineMeetingState(
  //     windowSize === 'tablet' || windowSize === 'mobile' ? true : false,
  //     'COLLAPSE_DRAWER'
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [windowSize]);

  const buttonsClassName: Record<windowSizeType, string> = {
    'wide-desktop': 'my-5 mx-2',
    desktop: 'my-5 mx-2',
    tablet: 'my-5',
    mobile: 'my-5',
    none: 'my-5',
  };

  const layoutClassName: Record<windowSizeType, string> = {
    'wide-desktop': 'min-h-screen',
    desktop: 'min-h-screen',
    tablet: 'min-h-full',
    mobile: 'min-h-full',
    none: 'min-h-full',
  };

  const contentClassName: Record<windowSizeType, string> = {
    'wide-desktop': '',
    desktop: '',
    tablet: 'my-3',
    mobile: 'my-3',
    none: 'my-3',
  };

  return (
    <Layout className={layoutClassName[windowSize]}>
      {console.log(collapsed)}
      {apiError ? (
        <>
          <PublicWrapper />
          <Modal
            className="min-w-[300px] mx-auto"
            visible={true}
            closable={false}
            centered={true}
            title={
              <Typography.Title
                className="text-primary-text text-center"
                level={4}
              >
                Greška
              </Typography.Title>
            }
            footer=""
          >
            <Typography.Title
              className="text-primary-text text-center"
              level={4}
            >
              {apiError}
            </Typography.Title>
          </Modal>
        </>
      ) : showCall ? (
        <DailyProvider callObject={callObject}>
          <Layout
            className={`gradient-background grid overflow-y-auto app ${layoutClassName[windowSize]}`}
          >
            <Navbar
              toggleSider={toggleSider}
              Header={Header}
              showCall={showCall}
              leaveCall={startLeavingCall}
              drawerType={drawerType}
              supportPhoneNumber={supportPhoneNumber}
              messagesCount={messagesCount}
              handleMessagesCount={handleMessagesCount}
            />
            <Content
              className={`w-screen flex items-center ${
                contentClassName[windowSize]
              } ${collapsed && 'justify-center'}`}
            >
              <Call
                toggleFocus={toggleFocus}
                focusMe={focusMe}
                endTime={endTime}
              />
            </Content>
            <MeetingDrawer
              toggleSider={toggleSider}
              Sider={Sider}
              drawerType={drawerType}
              questions={questions}
              user={user}
              scrollToBottom={scrollToBottom}
              messagesEndRef={messagesEndRef}
              messagesCount={messagesCount}
              handleMessagesCount={handleMessagesCount}
              leaveCall={startLeavingCall}
              numberOfUnseenMessages={numberOfUnseenMessages}
              changeTime={changeTime}
            />
            <Tray
              leaveCall={startLeavingCall}
              toggleFocus={toggleFocus}
              focusMe={focusMe}
              user={user}
              handleRatingModal={handleRatingModal}
              ratingModal={ratingModal}
            />
            <RatingModal
              ratingModal={ratingModal}
              handleRatingModal={handleRatingModal}
            />
            {windowSize == 'mobile' && (
              <div className="fixed top-[calc(100vh-200px)] right-3 z-[9999] rounded-full w-16 h-16 bg-white flex justify-center items-center shadow-lg">
                <Button
                  className={` rounded-full w-fit h-fit p-0 border-0 shadow-none hover:text-[#EB008B] ${
                    buttonsClassName[windowSize]
                  } ${
                    !collapsed && drawerType === 'chat'
                      ? 'text-[#EB008B]'
                      : 'text-primary-text'
                  }`}
                  onClick={() => toggleSider('chat')}
                >
                  <IconContext.Provider
                    value={{
                      className: 'm-auto w-5 h-5',
                    }}
                  >
                    <TiMessages /> Poruke
                  </IconContext.Provider>
                </Button>
              </div>
            )}
          </Layout>
        </DailyProvider>
      ) : (
        <HomeScreen
          createCall={createCall}
          startJoiningCall={startJoiningCall}
        />
      )}
    </Layout>
  );
};

export default OnlineMeeting;
