import { Typography } from 'antd';
import React, { Suspense } from 'react';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import { LoadingScreen } from '../../../../core/components/LoadingScreen';

const { Title } = Typography;

const Success: React.FC = () => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingScreen />}>
        <Title level={4} className="text-center my-2 text-primary-text">
          Uspešno ste poslali zahtev za zakazivanje konsultacije, uskoro ćemo
          Vas kontaktirati sa svim detaljima.
        </Title>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Success;
