import { Divider, Drawer, Form } from 'antd';
import { RuleObject } from 'antd/lib/form';
import Title from 'antd/lib/typography/Title';
import { FormInstance } from 'rc-field-form/lib/interface';
import { createElement, useContext } from 'react';
import { IconContext } from 'react-icons';
import { FaRegUserCircle } from 'react-icons/fa';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import CustomButton from 'src/components/CustomComponents/CustomButton/CustomButton';
import { AdminContext } from '../../../contexts/AdminState';
import { CEFormItem } from '../../Operator/pages/Doctors/constants/doctorsData';
import { API_ADMIN } from '../../service/api_agent';
import { CEUsersPasswordForm, createEditUserMapper, formCEUsers } from '../constants/usersData';
import { IUser } from '../constants/usersInterfaces';
import useMutateData from '../hooks/useMutateData';

interface ITicketsDrawer {
  handleCEDrawer: (id?: number) => void;
  drawerVisibility: boolean;
  editId?: number;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<any, unknown>>;
}

const CEUsersDrawer: React.VFC<ITicketsDrawer> = ({
  handleCEDrawer,
  drawerVisibility,
  editId,
  refetch,
}: ITicketsDrawer) => {
  const { userCEForm } = useContext(AdminContext);

  const onUserCreate = useMutateData(
    API_ADMIN.postUser,
    {
      onSuccessMessage: 'Uspešno kreiran korisnik!',
      onErrorMessage: 'Greška! Korisnik nije kreiran!',
    },
    refetch
  );

  const onUserEdit = useMutateData(
    API_ADMIN.editUser,
    {
      onSuccessMessage: 'Uspešno izmenjen korisnik!',
      onErrorMessage: 'Greška! Korisnik nije izmenjen!',
    },
    refetch
  );

  const onPasswordEdit = useMutateData(
    API_ADMIN.editUserPassword,
    {
      onSuccessMessage: 'Uspešno promenjena sifra!',
      onErrorMessage: 'Greška! Lozinka nije promenjena!',
    },
    refetch
  );

  const onFinish = (values: IUser, id: number) => {
    handleCEDrawer();
    if (id) return onUserEdit.mutate({ user: createEditUserMapper(values), id: id });
    return onUserCreate.mutate(createEditUserMapper(values));
  };

  const onPasswordChange = (values: any, id: number) => {
    handleCEDrawer();
    return onPasswordEdit.mutate({
      user: { password: values.passwordConfirm },
      id: id,
    });
  };

  return (
    <Drawer
      title={
        <Title level={4} className='flex mb-0 justify-end text-[gray]'>
          {editId ? 'Izmeni korisnika' : 'Dodaj korisnika'}
        </Title>
      }
      placement='right'
      onClose={() => handleCEDrawer()}
      visible={drawerVisibility}
      width={window.innerWidth < 500 ? window.innerWidth : 500}
      destroyOnClose
    >
      <Form
        noValidate
        onFinish={values => onFinish(values, userCEForm.id)}
        className='flex flex-col items-center'
        layout='vertical'
      >
        <IconContext.Provider
          value={{
            className: 'mx-auto w-full mb-4',
            size: '90',
            color: '#F259B3',
          }}
        >
          <FaRegUserCircle />
        </IconContext.Provider>
        <Divider>
          <Title level={4} className='text-center w-full text-primary-text font-bold'>
            Podaci o operateru
          </Title>
        </Divider>
        {formCEUsers.map((item: CEFormItem) => (
          <Form.Item
            className='my-1 text-primary-text'
            name={item.name}
            rules={[{ required: item.required, message: item.message }]}
            initialValue={editId ? userCEForm[item?.name] : null}
            label={item.text}
            key={item.name}
          >
            {createElement(item.type, {
              className: 'rounded-md w-[300px] text-primary-text',
              placeholder: item.text,
              value: userCEForm[item?.name],
              options: item.options,
            })}
          </Form.Item>
        ))}
        {!editId &&
          CEUsersPasswordForm.map(item => {
            return (
              <Form.Item
                className='my-1 text-primary-text'
                name={item.name}
                required={!editId ? item.required : false}
                hasFeedback={item.hasFeedback}
                dependencies={[item.dependencies!]}
                label={item.label}
                tooltip={item.tooltip}
                key={item.name}
                rules={[
                  {
                    required: !editId ? item.required : false,
                    message: item.message,
                    min: item.minLength,
                  },
                  item.name === 'passwordConfirm'
                    ? (props: FormInstance) => ({
                        validator(_: RuleObject, value: any) {
                          if (!value || props.getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Lozinke se ne podudaraju!'));
                        },
                      })
                    : item.name === 'password'
                    ? () => ({
                        validator(_: RuleObject, value: any) {
                          const regex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])/;
                          if (regex.test(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Molimo Vas unesite validnu lozinku!'));
                        },
                      })
                    : {},
                ]}
              >
                {createElement(item.component, {
                  className: 'rounded-md w-[300px] text-primary-text',
                  placeholder: item.label,
                })}
              </Form.Item>
            );
          })}
        <Form.Item className='mt-10 mx-auto flex text-center'>
          <CustomButton
            htmlType='submit'
            className='h-[40px] w-[200px] text-white font-bold bg-btn-primary hover:opacity-80'
            type='primary'
            text='Sačuvaj'
          />
        </Form.Item>
      </Form>

      {editId ? (
        <>
          <Form
            noValidate
            onFinish={values => onPasswordChange(values, userCEForm.id)}
            className='flex flex-col items-center'
            layout='vertical'
          >
            <Divider>
              <Title level={4} className='text-center w-full text-primary-text font-bold'>
                Promeni lozinku
              </Title>
            </Divider>
            {CEUsersPasswordForm.map(item => {
              return (
                <Form.Item
                  className='my-1 text-primary-text'
                  name={item.name}
                  required={!editId ? item.required : false}
                  hasFeedback={item.hasFeedback}
                  dependencies={[item.dependencies!]}
                  label={item.label}
                  tooltip={item.tooltip}
                  key={item.name}
                  rules={[
                    {
                      required: !editId ? item.required : false,
                      message: item.message,
                      min: item.minLength,
                    },
                    item.name === 'passwordConfirm'
                      ? (props: FormInstance) => ({
                          validator(_: RuleObject, value: any) {
                            if (!value || props.getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('Lozinke se ne podudaraju!'));
                          },
                        })
                      : item.name === 'password'
                      ? () => ({
                          validator(_: RuleObject, value: any) {
                            const regex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])/;
                            if (regex.test(value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('Lozinka nije validna!'));
                          },
                        })
                      : {},
                  ]}
                >
                  {createElement(item.component, {
                    className: 'rounded-md w-[300px] text-primary-text',
                    placeholder: item.label,
                  })}
                </Form.Item>
              );
            })}
            <Form.Item className='mt-10 mx-auto flex text-center'>
              <CustomButton
                htmlType='submit'
                className='h-[40px] w-[200px] text-white font-bold bg-btn-primary hover:opacity-80'
                type='primary'
                text='Promeni'
              />
            </Form.Item>
          </Form>
        </>
      ) : null}
    </Drawer>
  );
};

export default CEUsersDrawer;
