import { Drawer, Menu } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import checkTokenRole from 'src/utils/checkTokenRole';
import { adminPages, operatorPages } from '../constants/operatorPages';

interface IMobileDrawer {
  handleCEDrawer: (id?: number) => void;
  drawerVisibility: boolean;
}

const MobileDrawer: React.FC<IMobileDrawer> = ({ drawerVisibility, handleCEDrawer }) => {
  const location = useLocation();
  const isAdmin = checkTokenRole();
  const redirect = useNavigate();

  return (
    <Drawer
      title={
        <Title level={4} className='flex mb-0 justify-end text-[gray]'>
          Meni
        </Title>
      }
      placement='top'
      onClose={() => handleCEDrawer()}
      visible={drawerVisibility}
      height={window.innerHeight * 0.5}
      destroyOnClose
    >
      <Menu
        // className='bg-transparent border-0 font-bold w-[100%]'
        mode='vertical'
        selectedKeys={[location.pathname]}
      >
        <Menu.ItemGroup title='Admin'>
          {isAdmin
            ? adminPages.map(page => (
                <Menu.Item
                  className='flex items-center h-14'
                  onClick={() => {
                    redirect(page.path);
                    handleCEDrawer();
                  }}
                  icon={page?.icon}
                  key={page?.path}
                >
                  {page?.label}
                </Menu.Item>
              ))
            : null}
        </Menu.ItemGroup>
        <Menu.ItemGroup title='Operater'>
          {operatorPages.map(page => (
            <Menu.Item
              className='flex items-center h-14'
              onClick={() => {
                redirect(page.path);
                handleCEDrawer();
              }}
              icon={page?.icon}
              key={page?.path}
            >
              {page?.label}
            </Menu.Item>
          ))}
        </Menu.ItemGroup>
      </Menu>
    </Drawer>
  );
};

export default MobileDrawer;
