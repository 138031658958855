import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon';
import { ReactNode } from 'react';
import Doctors from '../pages/Doctors/Doctors';
import Tickets from '../pages/Tickets/Tickets';
import Users from '../../Admin/pages/Users';
import { IoPeopleOutline } from 'react-icons/io5';
import { IconContext } from 'react-icons';
import { HiOutlineClipboardList } from 'react-icons/hi';
import { RiStethoscopeLine } from 'react-icons/ri';

export const operatorPages: Array<Pages> = [
  {
    label: 'Tiketi',
    icon: (
      <IconContext.Provider value={{ className: 'mr-2', size: '25' }}>
        <HiOutlineClipboardList />
      </IconContext.Provider>
    ),
    path: '/operator/tickets',
    component: <Tickets />,
  },
  {
    label: 'Lekari',
    icon: (
      <IconContext.Provider value={{ className: 'mr-2', size: '25' }}>
        <RiStethoscopeLine />
      </IconContext.Provider>
    ),
    path: '/operator/doctors',
    component: <Doctors />,
  },
];
export const adminPages: Array<Pages> = [
  {
    label: 'Korisnici',
    icon: (
      <IconContext.Provider value={{ className: 'mr-2', size: '25' }}>
        <IoPeopleOutline />
      </IconContext.Provider>
    ),
    path: '/admin/users',
    component: <Users />,
  },
];
export interface Pages {
  label: string;
  type?: string;
  icon: AntdIconProps;
  path: string;
  component?: ReactNode;
}
export interface Doctor {
  id: number;
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  phoneNumber: string;
  enabled: boolean;
  room: Room | string;
  medicineFields: Array<MedicineFIeld> | string;
  medicineFieldsList?: string;
  name?: string | JSX.Element;
  status?: string;
  scheduledMeetingsCount: number;
}

export interface Ticket {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  status: string;
  comment: string;
  doctorLink: string | null;
  patientLink: string | null;
  birthDate: string;
  gender: string;
  medicineField: {
    id: number;
    name: string;
  };
  doctor: {
    id: number;
    firstName: string;
    lastName: string;
    title: string;
  };
  meeting: {
    id: number;
    startTime: string;
    actualStartTime: string;
    duration: number;
    actualDuration: number;
    status: string;
    endTime: string;
  };
  createdDate: string;
  patientRating: {
    grade: number;
    comment: string;
  };
  doctorRating: {
    grade: number;
    comment: string;
  };
}

export interface Room {
  name: string;
  url: string;
  enabled: boolean;
}

export interface MedicineFIeld {
  id: 0;
  name: string;
  enabled: boolean;
  value?: string;
}

export interface Pageable {
  page?: number;
  size?: number;
  sort?: Array<string>;
}
