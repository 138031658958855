import { DatePicker, Input, InputNumber, Select, TimePicker } from 'antd';
import { CEFormItem } from '../../Doctors/constants/doctorsData';
import { ITicketsConsult, ITicketsValues } from '../interfaces/TicketsInterfaces';
import moment from 'moment';
import 'moment/locale/sr';
import { Pageable, Ticket } from '../../../constants/operatorPages';
import { FaUserCircle } from 'react-icons/fa';
import { BsRecord2 } from 'react-icons/bs';
import { MdCancel } from 'react-icons/md';
import { GrCompliance } from 'react-icons/gr';
import { AiFillClockCircle, AiFillSchedule } from 'react-icons/ai';
import { SuccessSnackbar } from 'src/components/Snackbar/Snackbar';
moment.locale('sr');

export const consultDataMapper = (values: any, id: number) => {
  const { consultDate, consultTime, durationTime } = values;
  if (!consultDate || !consultTime || !durationTime) return null;
  const tempDate = consultDate.format('YYYY-MM-DD');
  const tempTime = consultTime.format('HH:mm:ss');
  const startTime = tempDate + 'T' + tempTime;

  return {
    startTime,
    duration: durationTime,
    ticketId: id,
  };
};

export const editConsult = (data: ITicketsConsult) => {
  return {
    method: 'put',
    url: 'agent/meetings',
    data,
    withCredentials: true,
  };
};

export const editAnswers = (data: any) => {
  return {
    method: 'put',
    url: 'agent/answers',
    data,
    withCredentials: true,
  };
};

export const editTicket = ({ data, id }: any) => {
  return {
    method: 'put',
    url: `agent/tickets/${id}`,
    data,
    withCredentials: true,
  };
};

export const getTickets = (pageable: Pageable) => {
  return {
    method: 'get',
    url: 'agent/tickets',
    params: pageable,
    withCredentials: true,
  };
};

export const formDataMapper = (values: ITicketsValues) => {
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    comment,
    medicineFieldId,
    doctorId,
    gender,
    birthDate,
  } = values;
  const formattedDate = moment(birthDate).format('YYYY-MM-DD');
  return {
    firstName,
    lastName,
    email,
    phoneNumber,
    comment,
    doctorId,
    medicineFieldId,
    gender: genderMapper[gender],
    birthDate: formattedDate,
  };
};

export const ticketsMapper = ({
  id,
  firstName,
  lastName,
  email,
  phoneNumber,
  status,
  medicineField,
  doctor,
  comment,
  meeting,
  createdDate,
  doctorLink,
  patientLink,
  birthDate,
  gender,
  patientRating,
  doctorRating,
}: Ticket) => {
  let durationTime: number | string = '';
  let consultDate: any = '';
  let consultTime: any = '';

  const name: JSX.Element = (
    <span>
      <FaUserCircle className='inline-block mb-1 mr-2' />
      {firstName} {lastName}
    </span>
  );
  const doctorName: JSX.Element = (
    <span>
      <FaUserCircle className='inline-block mb-1 mr-2' />
      {doctor.title} {doctor.firstName} {doctor.lastName}
    </span>
  );

  const momentBirthDate = moment(birthDate);
  createdDate = createdDate ? moment(createdDate).format('Do MMM HH:mm') : '-';

  let consultStatus = status;
  if (status === 'Zakazan' && meeting) {
    consultStatus = `Zakazan za ${moment(meeting.startTime).format('Do MMM HH:mm')}`;
  } else if (status === 'U toku') {
    consultStatus = `U toku (od ${moment(meeting.actualStartTime).format('HH:mm')})`;
  } else if (status === 'Završen') {
    consultStatus = `Završen ${moment(meeting.endTime).format('Do MMM HH:mm')}`;
  }

  if (meeting) {
    durationTime = meeting.duration;
    consultDate = moment(meeting.startTime);
    consultTime = moment(meeting.startTime);
  }

  return {
    id,
    name,
    firstName,
    lastName,
    doctorName,
    doctorId: doctor.id,
    doctor: doctor.title + ' ' + doctor.firstName + ' ' + doctor.lastName,
    email,
    phoneNumber,
    consultStatus,
    statusName: status,
    medicineField: medicineField.name,
    medicineFieldId: medicineField.id,
    comment,
    durationTime,
    consultDate,
    consultTime,
    createdDate,
    doctorLink,
    patientLink,
    birthDate: momentBirthDate,
    gender: genderMapper[gender],
    patientRating,
    doctorRating,
  };
};

// --------------------------------------------------------------------

const genderMapper: any = {
  MALE: 'Muški',
  FEMALE: 'Ženski',
  OTHER: 'Ostalo',
  Muški: 'MALE',
  Ženski: 'FEMALE',
  Ostalo: 'OTHER',
};

export const ticketsColumns = [
  {
    Header: 'Kreiran',
    accessor: 'createdDate',
    // hasSearch: true,
    hasSort: true,
    sortName: 'createdDate',
  },
  {
    // id: 'firstColumn',
    Header: 'Status',
    accessor: 'status',
    hasSort: true,
    sortName: 'status',
  },
  {
    Header: 'Pacijent',
    accessor: 'name',
    // hasSearch: true,
    hasSort: true,
    sortName: 'firstName',
  },
  {
    Header: 'Lekar',
    accessor: 'doctorName',
    // hasSearch: true,
    hasSort: true,
    sortName: 'doctor.firstName',
  },
  {
    Header: 'Oblast medicine',
    accessor: 'medicineField',
    // hasSearch: true,
    sortName: 'medicineField.name',
    hasSort: true,
  },
  {
    Header: 'Broj telefona',
    accessor: 'phoneNumber',
    // hasSearch: true,
    hasSort: true,
    sortName: 'phoneNumber',
  },
  {
    Header: 'E-mail',
    accessor: 'email',
    // hasSearch: true,
    hasSort: true,
    sortName: 'email',
  },
  {
    Header: 'Komentar',
    accessor: 'comment',
  },
  {
    Header: 'Akcije',
    accessor: 'actions',
  },
];

export const formCETickets: Array<CEFormItem> = [
  {
    id: 1,
    name: 'firstName',
    text: 'Ime',
    required: true,
    message: 'Molimo Vas unestite ime pacijenta!',
    type: Input,
  },
  {
    id: 2,
    name: 'lastName',
    text: 'Prezime',
    required: true,
    message: 'Molimo Vas unestite prezime pacijenta!',
    type: Input,
  },
  {
    id: 11,
    name: 'birthDate',
    text: 'Datum rođenja',
    required: false,
    message: 'Molimo Vas unestite datum rođenja!',
    type: DatePicker,
  },
  {
    id: 10,
    name: 'gender',
    text: 'Pol',
    required: true,
    message: 'Molimo Vas odaberite pol!',
    type: Select,
    options: [
      { id: 1, value: 'Muški' },
      { id: 2, value: 'Ženski' },
      { id: 3, value: 'Ostalo' },
    ],
  },
  {
    id: 4,
    name: 'email',
    text: 'Email',
    required: false,
    message: 'Molimo Vas unestite email adresu pacijenta!',
    type: Input,
  },
  {
    id: 5,
    name: 'phoneNumber',
    text: 'Broj telefona',
    required: true,
    message: 'Molimo Vas unestite broj telefona pacijenta!',
    type: Input,
  },
  {
    id: 3,
    name: 'doctorId',
    text: 'Lekar',
    required: true,
    message: 'Molimo Vas unestite lekara!',
    type: Select,
  },
  {
    id: 6,
    name: 'medicineFieldId',
    text: 'Grana medicine',
    required: true,
    message: 'Molimo Vas unestite granu medcine!',
    type: Select,
  },
  {
    id: 7,
    name: 'comment',
    text: 'Komentar',
    required: false,
    message: 'Molimo Vas unestite komentar!',
    autoSize: { minRows: 2, maxRows: 3 },
    type: Input.TextArea,
  },
];

export const ticketsScheduleForm: Array<CEFormItem> = [
  {
    id: 1,
    name: 'consultDate',
    text: 'Datum pregleda',
    required: true,
    message: 'Molimo Vas unestite datum pregleda!',
    type: DatePicker,
  },
  {
    id: 2,
    name: 'consultTime',
    text: 'Vreme pregleda',
    required: true,
    message: 'Molimo Vas unestite vreme pregleda!',
    type: TimePicker,
  },
  {
    id: 3,
    name: 'durationTime',
    text: 'Trajanje pregleda',
    required: true,
    message: 'Molimo Vas unesite trajanje pregleda!',
    type: InputNumber,
    options: [{ value: 15 }, { value: 30 }],
  },
];

export const sortDirectionOptions = [
  {
    previous: 'asc',
    next: 'desc',
  },
  {
    previous: 'desc',
    next: 'asc',
  },
  {
    previous: '',
    next: 'asc',
  },
];

export const ticketStatus: any = {
  'U toku':
    'flex flex-row items-center gap-1 border-green-500 text-green-500 rounded-md hover:cursor-default text-[10px] md:text-sm',
  'Na čekanju':
    'flex flex-row items-center gap-1 border-red-300 text-red-300 rounded-md hover:cursor-default text-[10px] md:text-sm',
  Zakazan:
    'flex flex-row items-center gap-1 border-yellow-500 text-yellow-500 rounded-md hover:cursor-default text-[10px] md:text-sm',
  Završen:
    'flex flex-row items-center gap-1 border-gray-500 text-gray-500 rounded-md hover:cursor-default text-[10px] md:text-sm',
  Odbijen:
    'flex flex-row items-center gap-1 border-red-500 text-red-500 rounded-md hover:cursor-default text-[10px] md:text-sm',
};

export const ticketStatusIcon: any = {
  'U toku': <BsRecord2 className='mr-2' />,
  'Na čekanju': <AiFillClockCircle className='mr-2' />,
  Zakazan: <AiFillSchedule className='mr-2' />,
  Završen: <GrCompliance className='mr-2' />,
  Odbijen: <MdCancel className='mr-2' />,
};

export const copyToClipboard = (content: string, message: string) => {
  const el = document.createElement('textarea');
  el.value = content;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  el.setSelectionRange(0, 99999);
  document.execCommand('copy');
  document.body.removeChild(el);
  SuccessSnackbar({ message: message });
};
