import { Input, Select } from 'antd';
import { FaUserCircle } from 'react-icons/fa';
import { Pageable } from 'src/pages/Operator/constants/operatorPages';
import { IUser, IUserFormItem } from './usersInterfaces';

export const getUsers = (pageable: Pageable) => {
  return {
    method: 'get',
    url: 'admin/users',
    params: pageable,
    withCredentials: true,
  };
};

export const usersMapper = ({ id, firstName, lastName, email, role, enabled }: IUser) => {
  const name: JSX.Element = (
    <span>
      <FaUserCircle className='inline-block mb-1 mr-2' />
      {firstName} {lastName}
    </span>
  );

  role = role.charAt(0) + role.slice(1).toLowerCase();

  return {
    id,
    firstName,
    name,
    lastName,
    email,
    role,
    enabled,
    statusKorisnika: enabled ? 'Aktiviran' : 'Deaktiviran',
  };
};

export const createEditUserMapper = (user: IUser) => {
  const { firstName, lastName, email, passwordConfirm, role } = user;

  if (passwordConfirm) {
    return {
      firstName,
      lastName,
      email,
      password: passwordConfirm,
      role,
    };
  } else {
    return {
      firstName,
      lastName,
      email,
      role,
    };
  }
};

export const usersColumns = [
  {
    Header: 'Status',
    accessor: 'status',
    sortName: 'status',
  },
  {
    Header: 'Ime',
    accessor: 'name',
    hasSort: true,
    sortName: 'firstName,lastName',
  },
  {
    Header: 'E-mail',
    accessor: 'email',
    hasSort: true,
    sortName: 'email',
  },
  {
    Header: 'Tip korisnika',
    accessor: 'role',
    hasSort: true,
    sortName: 'role',
  },
  {
    Header: 'Akcije',
    accessor: 'actions',
  },
];

export const formCEUsers: Array<IUserFormItem> = [
  {
    id: 1,
    name: 'firstName',
    text: 'Ime',
    required: true,
    message: 'Molimo Vas unestite ime korisnika!',
    type: Input,
  },
  {
    id: 2,
    name: 'lastName',
    text: 'Prezime',
    required: true,
    message: 'Molimo Vas unestite prezime korisnika!',
    type: Input,
  },
  {
    id: 3,
    name: 'email',
    text: 'Email',
    required: true,
    message: 'Molimo Vas unestite email adresu korisnika!',
    type: Input,
  },
  {
    id: 4,
    name: 'role',
    text: 'Tip korisnika',
    required: true,
    message: 'Molimo Vas unestite tip korisnika!',
    type: Select,
    options: [
      { id: 1, value: 'ADMIN', label: 'Admin' },
      { id: 2, value: 'AGENT', label: 'Agent' },
    ],
  },
];

export const CEUsersPasswordForm = [
  {
    label: 'Lozinka',
    name: 'password',
    required: true,
    hasFeedback: true,
    message: 'Lozinka je prekratka!',
    component: Input.Password,
    tooltip: 'Lozinka mora sadržati jedno veliko slovo, jedno malo slovo i broj',
    minLength: 8,
  },
  {
    label: 'Potvrdi lozinku',
    name: 'passwordConfirm',
    required: true,
    hasFeedback: true,
    dependencies: ['password'],
    message: 'Molimo Vas potvrdite lozinku!',
    component: Input.Password,
  },
];
