import { Typography } from 'antd';
import CustomButton from '../../../../../components/CustomComponents/CustomButton/CustomButton';
import Logo from '../../../../../assets/logo.png';
const { Title } = Typography;

interface Props {
  handleLogin: () => void;
  isLoading: boolean;
}

export const TitleComponent = () => {
  return (
    <>
      <div className="my-2">
        <img src={Logo} className="w-[80px] mb-8 mx-auto" />
        <Title level={3} className="text-center my-2 text-primary-text">
          Prijavi se na{' '}
          <span className="text-secondary-text">Telemedicinu</span>
        </Title>
      </div>
    </>
  );
};

export const FooterComponent: React.FC<Props> = ({
  handleLogin,
  isLoading,
}: Props) => {
  return (
    <div className="my-2 text-center">
      <CustomButton
        text="Prijavi se"
        loading={isLoading}
        className="bg-btn-primary font-bold text-base text-white h-[40px] w-[200px]"
        onClick={() => handleLogin()}
      />
    </div>
  );
};
