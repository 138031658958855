import { Divider, List, Modal } from 'antd';
import Title from 'antd/lib/typography/Title';
import { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import ErrorBoundary from 'src/components/ErrorBoundary';
import { LoadingScreen } from 'src/core/components/LoadingScreen';
import PublicWrapper from 'src/core/components/PublicWrapper';
import useSingleTicket from '../Operator/hooks/useSingleTicket';
import { questionsMapper } from '../Operator/pages/Ticket/TicketHelper';
import { ticketsMapper } from '../Operator/pages/Tickets/components/TicketsData';
import { API_PUBLIC } from '../service/api_agent';
import { patientInfoTicket, patientInfoQustionsValue, PatientInfoError } from './PatientInfoHelper';

const PatientInfo = () => {
  const { token } = useParams();

  const { data, isLoading, isError, error } = useSingleTicket(
    'patient-info',
    () => API_PUBLIC.getPatientInfo(token!),
    ticketsMapper,
    questionsMapper
  );

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingScreen />}>
        <PublicWrapper />
        <Modal
          className='min-w-[100vh] mx-auto'
          visible={true}
          closable={false}
          centered={true}
          title=''
          footer=''
        >
          {isLoading ? (
            <div className='h-44'>
              <LoadingScreen />
            </div>
          ) : isError ? (
            <PatientInfoError error={error} />
          ) : (
            <>
              <div className='flex justify-end'>
                <Title level={4} className='text-primary-text font-bold'>
                  {data.ticket.consultStatus}
                </Title>
              </div>
              <div className='flex justify-evenly'>
                <div className='w-1/2'>
                  <Divider orientation='left'>
                    <Title level={5}>Informacije o pacijentu</Title>
                  </Divider>
                  <List itemLayout='horizontal' className='ml-6'>
                    {patientInfoTicket.map((item: any) => (
                      <List.Item className='p-1 text-primary-text'>
                        <List.Item.Meta
                          title={item.text}
                          description={
                            item.name === 'birthDate'
                              ? data.ticket[item.name].format('L')
                              : data.ticket[item.name]
                          }
                        />
                      </List.Item>
                    ))}
                  </List>
                </div>

                <div className='w-full'>
                  <Divider orientation='left'>
                    <Title level={5}>Informacije o bolesti</Title>
                  </Divider>
                  <List itemLayout='horizontal' className='ml-10'>
                    {data.questions.map((item: any) => (
                      <List.Item className='p-1'>
                        <List.Item.Meta
                          title={item.text}
                          description={patientInfoQustionsValue(item.type, item)}
                        />
                      </List.Item>
                    ))}
                  </List>
                </div>
              </div>
            </>
          )}
        </Modal>
      </Suspense>
    </ErrorBoundary>
  );
};

export default PatientInfo;
