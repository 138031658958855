import { Button, DatePicker, Divider, Drawer, Form } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { createElement, useContext } from 'react';
import { IconContext } from 'react-icons';
import { AiOutlineCalendar } from 'react-icons/ai';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { OperatorContext } from 'src/contexts/OperatorState';
import usePostCreate from 'src/pages/Operator/hooks/usePostCreate';
import { CEFormItem } from '../../Doctors/constants/doctorsData';
import { ITicketsConsult } from '../interfaces/TicketsInterfaces';
import { consultDataMapper, editConsult, ticketsScheduleForm } from './TicketsData';
import locale from 'antd/es/date-picker/locale/sr_RS';

interface IConsultsDrawer {
  handleCEDrawer: (type: string, id?: number) => void;
  drawerVisibility: boolean;
  consultType: string;
  editId?: number;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<any, unknown>>;
}
const CEConsultsDrawer: React.VFC<IConsultsDrawer> = ({
  handleCEDrawer,
  drawerVisibility,
  consultType,
  editId,
  refetch,
}) => {
  const editConsultMutation = usePostCreate(
    editConsult,
    {
      onSuccessMessage: 'Uspešno zakazana konsultacija!',
      onErrorMessage: 'Greška! Konsultacija nije zakazana!',
    },
    refetch
  );
  const onConsultFinish = (values: ITicketsConsult, id: number) => {
    editConsultMutation.mutate(consultDataMapper(values, id));
    handleCEDrawer('consult');
  };
  const { ticketCEForm } = useContext(OperatorContext);

  return (
    <Drawer
      title={
        <Title level={4} className='text-primary-text font-bold flex mb-0 justify-end'>
          {editId ? 'Uredi konsultaciju' : 'Zakaži konsultaciju'}
        </Title>
      }
      placement='right'
      onClose={() => handleCEDrawer('consult')}
      visible={drawerVisibility}
      width={window.innerWidth < 500 ? window.innerWidth : 500}
      destroyOnClose
    >
      <Form
        noValidate
        onFinish={(values: ITicketsConsult) => onConsultFinish(values, ticketCEForm.id)}
        className='flex flex-col items-center'
        layout='vertical'
      >
        <IconContext.Provider
          value={{
            className: 'mx-auto',
            size: '90',
            color: '#F259B3',
          }}
        >
          <AiOutlineCalendar />
        </IconContext.Provider>
        <Divider orientation='center' className='my-4'>
          <Title level={4} className='text-center text-primary-text w-full font-bold'>
            Termin konsultacije
          </Title>
        </Divider>
        {ticketsScheduleForm.map((item: CEFormItem) => (
          <Form.Item
            name={item.name}
            className='my-1'
            rules={[{ required: item.required, message: item.message }]}
            initialValue={ticketCEForm[item?.name]}
            label={item.text}
            key={item.name}
          >
            {createElement(item.type, {
              className: 'rounded-md w-[300px]',
              placeholder: item.text,
              options: item.options,
              prefix: item.name === 'durationTime' ? 'min' : null,
              ...(item.name === 'durationTime' && {
                step: 5,
                min: 10,
                max: 90,
              }),
              format: item.type === DatePicker ? 'DD. MM. YYYY.' : 'HH:mm',
              disabled: consultType === 'U toku' && item.name !== 'durationTime' ? true : false,
              locale,
            })}
          </Form.Item>
        ))}
        <Form.Item className='mt-10 mx-auto flex text-center'>
          <Button
            htmlType='submit'
            className='h-[40px] w-[200px] bg-btn-primary font-bold text-white rounded-md hover:opacity-80'
            type='primary'
          >
            Zakaži
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default CEConsultsDrawer;
