import { notification, SiderProps } from 'antd';
import {
  ChangeEvent,
  ForwardRefExoticComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { OnlineMeetingContext } from 'src/contexts/OnlineMeetingState';
import useWindowSize, { windowSizeType } from 'src/hooks/useWindowSize';
import { Participant } from '../../constants/onlineMeetingData';
import ChatInput from './components/ChatInput';
import TextMessages from './components/TextMessages';
import WebFormPreview from './components/WebFormPreview';
import { filesMapper } from './helpers/textChatHelper';

interface Props {
  toggleSider: (type: 'chat' | 'form') => void;
  Sider: ForwardRefExoticComponent<
    SiderProps & React.RefAttributes<HTMLDivElement>
  >;
  drawerType: string;
  questions: Array<any>;
  user: Participant;
  scrollToBottom: () => void;
  messagesEndRef: any;
  handleMessagesCount: (seen: boolean, count?: number) => void;
  messagesCount: number;
  leaveCall: any;
  numberOfUnseenMessages: number;
  changeTime: (endTime: string) => void;
}

let stompClient: any = null;

const MeetingDrawer: React.FC<Props> = ({
  toggleSider,
  Sider,
  drawerType,
  questions,
  user,
  scrollToBottom,
  messagesEndRef,
  handleMessagesCount,
  messagesCount,
  leaveCall,
  numberOfUnseenMessages,
  changeTime,
}: Props) => {
  const { updateOnlineMeetingState, collapsed } =
    useContext(OnlineMeetingContext);
  const windowSize = useWindowSize();

  const redirect = useNavigate();

  const [message, setMessage] = useState('');
  const [base64URL, setBase64URL] = useState<any>(null);
  const [fileList, setFileList] = useState([]);

  const URL_ROOT = `${process.env.REACT_APP_BASE_URL}${
    process.env.REACT_APP_BASE_PORT ? ':' + process.env.REACT_APP_BASE_PORT : ''
  }`;

  useEffect(() => {
    sessionStorage.setItem('drawer_collapsed', JSON.stringify(collapsed));
    numberOfUnseenMessages = 0;
    sessionStorage.setItem(
      'messages_count',
      JSON.stringify(numberOfUnseenMessages)
    );
  }, [collapsed, numberOfUnseenMessages]);

  const connect = () => {
    if (user?.id) {
      const url = `${URL_ROOT}/ws`;
      const Stomp = require('stompjs');
      let SockJS = require('sockjs-client');

      SockJS = new SockJS(url);

      stompClient = Stomp.over(SockJS);
      if (window.location.hostname !== 'localhost') stompClient.debug = null;

      stompClient.connect(
        {
          'Meeting-Token': localStorage.getItem('be_auth_token'),
        },
        onConnected,
        onError
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onConnected = useCallback(() => {
    if (user?.id)
      stompClient.subscribe(
        `/user/${user?.id}/queue/messages`,
        onMessageReceived,
        { id: user?.id, receipt: `receipt-${user?.id}` }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  const onError = (error: any) => {
    if (window.location.hostname !== 'localhost')
      console.log('Reconnecting in 5 seconds');
    setTimeout(() => connect(), 5000);
  };

  const onMessageReceived = useCallback((payload: any) => {
    const localCollapsed = sessionStorage.getItem('drawer_collapsed');
    let chatMessagesCount = sessionStorage.getItem('messages_count');
    const message = JSON.parse(payload.body);
    updateOnlineMeetingState(message, 'UPDATE_CHAT');
    if (message?.type === 'CHAT') {
      if (localCollapsed === 'true') {
        numberOfUnseenMessages =
          parseInt(
            typeof chatMessagesCount === 'string' ? chatMessagesCount : '0'
          ) + 1;
      }
      if (localCollapsed === 'false') {
        numberOfUnseenMessages = 0;
      }
    }
    sessionStorage.setItem(
      'messages_count',
      JSON.stringify(numberOfUnseenMessages)
    );
    updateOnlineMeetingState(numberOfUnseenMessages, 'MESSAGES_COUNT');
    if (message?.type === 'KICK') {
      leaveCall();
      window.location.href = `${
        window.location.href.split('/online-meeting/')[0]
      }/rating/${localStorage.getItem('daily_auth_token')}`;
    }
    if (message?.type === 'MEETING_DURATION_CHANGED') {
      changeTime(message?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChatInput = (event: ChangeEvent<HTMLInputElement>) => {
    const tempValue: string = event.target.value;
    setMessage(tempValue);
  };

  const getBase64 = (event: any) => {
    return new Promise((resolve) => {
      let baseURL: any = '';
      let reader = new FileReader();

      reader.readAsDataURL(event);

      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleFileInputChange = (e: any) => {
    let mappedData;
    let tempFile = e.file.originFileObj;
    let fileName = e?.file?.name;
    let fileType = e?.file?.type;
    let fileNameArr = e?.file?.name?.split('.');
    if (fileType === '') {
      if (fileNameArr[fileNameArr.length - 1] === 'doc')
        fileType = 'application/msword';
      if (fileNameArr[fileNameArr.length - 1] === 'docx')
        fileType =
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    }
    getBase64(tempFile)
      .then((result: any) => {
        tempFile['base64'] = result;
        result = result.split(';base64,')[1];
        setBase64URL(result);
        mappedData = filesMapper(fileType, result, fileName);
        setFileList(e.fileList);
        updateOnlineMeetingState(mappedData, 'UPLOAD_FILE');
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  const allowedContentTypes = [
    'image/jpeg',
    'image/png',
    'image/webp',
    'image/gif',
    'image/tiff',
    'text/plain',
    'application/zip',
    'application/x-7z-compressed',
    'application/x-tar',
    'application/vnd.rar',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.oasis.opendocument.text',
    'video/mpeg',
    'video/mp4',
    'video/x-msvideo',
    'video/ogg',
    'video/webm',
  ];

  const dummyRequest = ({ file, onSuccess, onError }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const beforeUpload = (file: any) => {
    let fileNameArr = file?.name?.split('.');
    if (
      allowedContentTypes.includes(file?.type) ||
      fileNameArr[fileNameArr.length - 1] === 'doc' ||
      fileNameArr[fileNameArr.length - 1] === 'docx'
    ) {
      return file;
    }
    notification.config({
      duration: 3,
    });
    notification['error']({
      message: 'Nepodržani tip fajla',
      placement: 'bottomRight',
    });
    return false;
  };

  const clearFiles = () => {
    setBase64URL('');
    setFileList([]);
    updateOnlineMeetingState('', 'CLEAR_FILES');
  };

  const sendMessage = useCallback(
    (message: string, uploadFiles?: any) => {
      const type: string = uploadFiles?.length !== 0 ? 'FILE' : 'CHAT';
      if ((message && message !== '' && stompClient) || type === 'FILE') {
        const chatMessage = {
          senderId: user?.id,
          senderName: user?.name,
          text: message,
          body: message,
          files: uploadFiles,
          type: type,
          sentTime: new Date(),
        };
        stompClient.send(
          '/app/chat',
          { name: user.name },
          JSON.stringify(chatMessage)
        );
        updateOnlineMeetingState(chatMessage, 'UPDATE_CHAT');
      }
      clearFiles();
      setMessage('');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user?.id]
  );

  useEffect(() => {
    if (user.id && stompClient) {
      stompClient.disconnect();
    }
    connect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  const siderClassName: Record<windowSizeType, string> = {
    'wide-desktop': 'h-[calc(100%-128px)]',
    desktop: 'h-[calc(100%-128px)]',
    tablet: 'h-[calc(100%-128px)]',
    mobile: 'h-[calc(100%-265px)]',
    none: 'h-[calc(100%-128px)]',
  };

  const siderWidth: Record<windowSizeType, string> = {
    'wide-desktop': '540px',
    desktop: '450px',
    tablet: '100vw',
    mobile: '100vw',
    none: '100vw',
  };

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={siderWidth[windowSize]}
      collapsedWidth={0}
      className={`bg-white top-[64px] absolute right-0 border-2 border-white opacity-90 transition z-50 ${siderClassName[windowSize]}`}
    >
      <div className="h-full flex flex-col">
        {drawerType === 'chat' ? (
          <>
            <TextMessages
              user={user}
              scrollToBottom={scrollToBottom}
              messagesEndRef={messagesEndRef}
            />
            <ChatInput
              sendMessage={sendMessage}
              handleChatInput={handleChatInput}
              message={message}
              base64URL={base64URL}
              fileList={fileList}
              dummyRequest={dummyRequest}
              handleFileInputChange={handleFileInputChange}
              clearFiles={clearFiles}
              beforeUpload={beforeUpload}
            />
          </>
        ) : (
          questions && <WebFormPreview questions={questions} />
        )}
      </div>
    </Sider>
  );
};

export default MeetingDrawer;
