import { Divider, Tabs } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { Suspense, useEffect, useState } from 'react';
import { IconContext } from 'react-icons/lib';
import { MdArrowBack, MdOutlineRefresh } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import CustomButton from 'src/components/CustomComponents/CustomButton/CustomButton';
import ErrorBoundary from 'src/components/ErrorBoundary';
import { LoadingScreen } from 'src/core/components/LoadingScreen';
import useSelectOptions from 'src/hooks/useSelectOptions';
import { getAllDoctors, getAllMedFields } from 'src/pages/PatientWebForm/helpers/stepHelpers';
import { API_AGENT } from 'src/pages/service/api_agent';
import useSingleTicket from '../../hooks/useSingleTicket';
import {
  consultDataMapper,
  formDataMapper,
  ticketsMapper,
} from '../Tickets/components/TicketsData';
import { ITicketsConsult, ITicketsValues } from '../Tickets/interfaces/TicketsInterfaces';
import {
  answersDataMapper,
  filterFiles,
  questionsMapper,
  TicketDoctorsMapper,
  TicketMedFieldsMapper,
} from './TicketHelper';
import {
  BasicQuestions,
  ChatInfo,
  ConsultSchedule,
  MedicalQuestions,
  operatorTabs,
} from './components/SingleTicketData';
import useMutateData from 'src/pages/Admin/hooks/useMutateData';
import { useQuery } from 'react-query';
import useDependantQuery from 'src/hooks/useDependantQuery';

interface ITicket {}
const { TabPane } = Tabs;

const Ticket: React.VFC<ITicket> = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [files, setFiles] = useState<any>();
  const [selectedTab, setSelectedTab] = useState('1');
  const [chosenMedField, setChosenMedField] = useState();

  const [mouseEntered, setMouseEntered] = useState(false);

  const { data, isLoading, refetch, isFetchedAfterMount, isError, isFetching } = useSingleTicket(
    id!,
    () => API_AGENT.getTicket(id),
    ticketsMapper,
    questionsMapper,
    setChosenMedField
  );
  const chatMessages = useQuery(`messages-${id}`, () => API_AGENT.getTicketMessages(id), {
    refetchOnWindowFocus: false,
    cacheTime: 100,
  });

  const allDoctors = useDependantQuery(
    ['all-doctors', chosenMedField],
    getAllDoctors,
    TicketDoctorsMapper,
    chosenMedField
  );
  const allMedFields = useSelectOptions(
    'all-medicine-fields',
    getAllMedFields,
    TicketMedFieldsMapper,
    1
  );

  const editTicketMutation = useMutateData(
    API_AGENT.editTicket,
    {
      onSuccessMessage: 'Uspešno izmenjene osnovne informacije!',
      onErrorMessage: 'Greška! Osnovne informacije nisu izmenjene!',
    },
    refetch
  );

  const editConsultMutation = useMutateData(API_AGENT.editConsult, {
    onSuccessMessage: 'Uspešno zakazana konsultacija!',
    onErrorMessage: 'Greška! Konsultacija nije zakazana!',
  });

  const editAnswersMutation = useMutateData(API_AGENT.editTicketAnswers, {
    onSuccessMessage: 'Uspešno izmenjene informacije o bolesti!',
    onErrorMessage: 'Greška! Informacije o bolesti nisu izmenjene!',
  });

  const onFormFinish = (values: ITicketsValues) => {
    editTicketMutation.mutate({
      id: id,
      data: { ...formDataMapper(values) },
    });
  };

  const onConsultFinish = (values: ITicketsConsult) => {
    editConsultMutation.mutate(consultDataMapper(values, Number(id)));
  };

  const onAnswersFinish = (values: any) => {
    editAnswersMutation.mutate(answersDataMapper(data.questions, values, Number(id), files));
  };

  const onFilesChange = async (value: any) => {
    const files = await filterFiles(value);
    setFiles(files);
  };

  useEffect(() => {
    if (isFetchedAfterMount && !isError) {
      if (data.ticket.statusName === 'U toku') setSelectedTab('3');
      const file = data.questions.find((item: any) => item.type === 'FILE_UPLOAD');
      if (file) setFiles(file.answer.files);
    }
  }, [isFetchedAfterMount]);

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingScreen />}>
        <div className={window.innerWidth > 500 ? 'flex gap-5' : 'flex gap-5 flex-col mr-8'}>
          <div className='h-[calc(100vh-112px)] w-full bg-white rounded-lg overflow-auto my-6 ml-4 p-6 opacity-90'>
            <div className='flex justify-between items-center'>
              <CustomButton
                className='font-bold active:contrast-50 border-none text-lg shadow-none p-0 flex items-center'
                onClick={() => navigate(-1)}
                onMouseEnter={() => setMouseEntered(true)}
                onMouseLeave={() => setMouseEntered(false)}
                ghost
                text='Nazad'
                iconPosition='left'
                icon={
                  <IconContext.Provider
                    value={{
                      className: `mx-2 ${mouseEntered && 'bounceAlphaLeft'}`,
                      size: '25',
                    }}
                  >
                    <MdArrowBack />
                  </IconContext.Provider>
                }
              />
              <Title level={4} className='text-primary-text font-bold'>
                Uredi tiket
              </Title>
            </div>
            <Tabs activeKey={selectedTab} onChange={tab => setSelectedTab(tab)} centered>
              {operatorTabs.map((item: any) => (
                <TabPane
                  tab={
                    <span className='flex gap-1 items-center font-bold'>
                      <IconContext.Provider
                        value={{
                          className: 'mr-2',
                          size: '28',
                        }}
                      >
                        {item.icon}
                      </IconContext.Provider>
                      {item.tabTitle}
                    </span>
                  }
                  key={item.key}
                >
                  <IconContext.Provider
                    value={{
                      className: 'mx-auto',
                      size: '90',
                      color: '#F259B3',
                    }}
                  >
                    {item.icon}
                  </IconContext.Provider>
                  <Divider orientation='center' className='my-4'>
                    <Title level={4} className='text-primary-text'>
                      {item.title}
                    </Title>
                  </Divider>

                  {isLoading ? (
                    <div className='h-[50vh] flex items-center'>
                      <LoadingScreen />
                    </div>
                  ) : isError ? (
                    <div className='w-max mt-12 mx-auto text-secondary-text font-semibold text-3xl'>
                      Tiket nije pronadjen
                    </div>
                  ) : item.name === 'BasicQuestions' ? (
                    <BasicQuestions
                      onFormFinish={onFormFinish}
                      data={data}
                      allMedFields={allMedFields}
                      allDoctors={allDoctors}
                      editTicketMutation={editTicketMutation}
                      setChosenMedField={setChosenMedField}
                    />
                  ) : item.name === 'MedicalQuestions' ? (
                    isFetching ? (
                      <div className='h-[50vh] flex items-center'>
                        <LoadingScreen />
                      </div>
                    ) : (
                      <MedicalQuestions
                        data={data}
                        onAnswersFinish={onAnswersFinish}
                        onFilesChange={onFilesChange}
                        editAnswersMutation={editAnswersMutation}
                      />
                    )
                  ) : item.name === 'ConsultSchedule' ? (
                    <ConsultSchedule
                      data={data}
                      onConsultFinish={onConsultFinish}
                      editConsultMutation={editConsultMutation}
                    />
                  ) : null}
                </TabPane>
              ))}
            </Tabs>
          </div>
          <div className='h-[calc(100vh-112px)] w-[650px] bg-white rounded-lg overflow-y-none my-6 mr-4 px-6 pt-6 pb-2 opacity-90'>
            <div className='flex justify-between text-secondary-text'>
              <IconContext.Provider
                value={{
                  className:
                    'mt-1 cursor-pointer origin-center hover:rotate-45 transition active:contrast-50',
                  size: '25',
                }}
              >
                <MdOutlineRefresh onClick={() => chatMessages.refetch()} />
              </IconContext.Provider>
              <Title level={4} className='font-bold text-primary-text'>
                Prepiska lekara i pacijenta
              </Title>
            </div>
            <Divider />
            {isLoading || chatMessages.isLoading || chatMessages.isFetching ? (
              <div className='h-[50vh] flex items-center'>
                <LoadingScreen />
              </div>
            ) : isError ? (
              <div className='w-max mt-12 mx-auto text-secondary-text font-semibold text-3xl'>
                Tiket nije pronadjen
              </div>
            ) : (
              <ChatInfo
                ticket={data.ticket}
                messages={chatMessages.data}
                doctor={data.ticket.doctor}
              />
            )}
          </div>
        </div>
      </Suspense>
    </ErrorBoundary>
  );
};

export default React.memo(Ticket);
