import { useMutation } from 'react-query';
import { setupAxios } from '../../../axios/setupAxios';
import { ErrorSnackbar } from '../../../components/Snackbar/Snackbar';
import { postAnswersMapper } from '../helpers/stepHelpers';

const usePostTicketInfo = (
  postFunction: Function,
  chainFunction?: Function,
  additionalData?: any
) => {
  return useMutation(
    (data: any) =>
      setupAxios(postFunction(data)).then((response) => {
        if (chainFunction) {
          if (additionalData?.tempFieldAnswers?.length !== 0) {
            const mappedAdditionalData = postAnswersMapper(
              additionalData?.tempFieldAnswers,
              response?.data?.id
            );
            setupAxios(chainFunction(mappedAdditionalData));
          }
          sessionStorage.setItem('ticket_id', response?.data?.id);
        } else sessionStorage.removeItem('ticket_id');
      }),
    {
      onSuccess:
        // !chainFunction
        additionalData.handleChangeStep(),
      // : additionalData?.tempFieldAnswers.length !== 0
      // ? additionalData.handleChangeStep()
      // : null,
      onError: (error: any) => {
        ErrorSnackbar({
          message: 'Greška! Proverite unete podatke!',
          description: error.response.data.message,
        });
      },
    }
  );
};

export default usePostTicketInfo;
