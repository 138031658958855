import { useQuery } from 'react-query';

const useSingleTicket = (
  key: string,
  axiosData: Function,
  ticketMapper: Function,
  questionMapper: Function,
  setChosenMedField?: any
) => {
  return useQuery(
    key,
    (data: any) =>
      axiosData(data).then((response: any) => {
        const questions = response.questions.map((item: any) => questionMapper(item));
        const ticket = ticketMapper(response.ticket);
        setChosenMedField && setChosenMedField(ticket.medicineFieldId);
        return {
          ticket,
          questions,
          messages: response.messages,
        };
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      // cacheTime: 100,
    }
  );
};

export default useSingleTicket;
