import { LoginCredentials } from '../constants/loginData';

export const loginCredentialsMapper = ({
  email,
  password,
  rememberMe,
}: LoginCredentials) => {
  return {
    email,
    password,
    rememberMe,
  };
};

export const postLoginCredentials = (data: LoginCredentials) => {
  return {
    method: 'post',
    url: 'api/authentication',
    data,
  };
};
