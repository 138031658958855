import React, {
  ChangeEvent,
  createElement,
  Suspense,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Divider, Form, Skeleton, Typography } from 'antd';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import { LoadingScreen } from '../../../../core/components/LoadingScreen';
import { FormItem, MedFieldAnswers, Options } from '../../constants/stepsData';
import useForm from '../../../../hooks/useForm';
import {
  FieldEnableContext,
  formItemsStateMapper,
  medFieldAnswersMapper,
} from '../../../../contexts/WebFormState';
import { dataMapper, getMedFieldQuestions } from '../../helpers/stepHelpers';
// import { useIntl } from 'react-intl';
// import { filesMapper } from 'src/pages/OnlineMeetingWrapper/components/OnlineMeeting/components/MeetingDrawer/helpers/textChatHelper';
import locale from 'antd/es/date-picker/locale/sr_RS';
// import moment from 'moment';
import { filterFiles } from 'src/pages/Operator/pages/Ticket/TicketHelper';

const { Title } = Typography;

const formItemsMap = new Map<number, Array<number> | undefined>();

let formItemsStateMapped = {};
let medFieldAnswersMapped: Array<MedFieldAnswers> | undefined = [];

const Step2: React.FC = () => {
  const chosenMedField = {
    id: sessionStorage.getItem('medFieldId'),
    name: sessionStorage.getItem('medFieldName'),
  };
  // const intl = useIntl();
  // const onFilesChange = async (value: any) => {
  //   return await filterFiles(value);
  // };

  const { patientData, medFieldAnswers, updateWebFormState } =
    useContext(FieldEnableContext);

  const [defaultForm, setDefaultForm] = useState<Array<FormItem>>();
  // const [contentType, setContentType] = useState('');

  // const [isOpen, setIsOpen] = useState(false);

  const formQuestions = useForm(
    'form-questions-step-2',
    getMedFieldQuestions,
    dataMapper,
    chosenMedField.id
  );

  if (defaultForm?.length === 0) setDefaultForm(formQuestions?.data);

  useEffect(() => {
    if (formQuestions?.data) {
      formQuestions.refetch();
      setDefaultForm(formQuestions?.data);
      formItemsStateMapped = formItemsStateMapper(formQuestions?.data);
      medFieldAnswersMapped = medFieldAnswersMapper(formQuestions?.data);
      updateWebFormState(
        medFieldAnswersMapped,
        'UPDATE_MEDICINE_FIELD_ANSWERS'
      );
      updateWebFormState(formItemsStateMapped, 'UPDATE_FIELDS_STATE');
    }
  }, [formQuestions.data]);

  if (formQuestions.isLoading) {
    return <Skeleton active />;
  }

  if (formQuestions.isError) {
    if (formQuestions.error instanceof Error) {
      return <h3>{formQuestions.error.message}</h3>;
    }
  }

  const handleFormItemState = (questionId: number, answer: Options) => {
    if (formItemsMap.has(questionId)) {
      updateWebFormState(formItemsMap.get(questionId), 'CHANGE_FIELD_STATE');
      if (answer.triggersQuestions?.length !== 0) {
        formItemsMap.set(questionId, answer.triggersQuestions);
      } else {
        formItemsMap.delete(questionId);
      }
    } else if (
      !formItemsMap.has(questionId) &&
      answer.triggersQuestions &&
      answer.triggersQuestions?.length !== 0
    ) {
      formItemsMap.set(questionId, answer.triggersQuestions);
      updateWebFormState(answer.triggersQuestions, 'CHANGE_FIELD_STATE');
    }
  };

  // const getBase64 = (event: any) => {
  //   return new Promise(resolve => {
  //     let fileInfo;
  //     let baseURL: any = '';
  //     let reader = new FileReader();

  //     reader.readAsDataURL(event);

  //     reader.onload = () => {
  //       baseURL = reader.result;
  //       resolve(baseURL);
  //     };
  //   });
  // };

  const handleMedFieldItem =
    (
      type: string,
      options: Array<Options> | undefined,
      questionId: number,
      questionName?: string
    ) =>
    async (
      event: ChangeEvent<HTMLInputElement> | any,
      answer: Options | any
    ) => {
      let tempValue: string | number | Options | any = '';
      if (type === 'FREE_TEXT' || type === 'DECIMAL' || type === 'INTEGER') {
        tempValue = type === 'FREE_TEXT' ? event.target.value : event;
        if (questionId) {
          for (const question in medFieldAnswersMapped) {
            if (
              questionId === (medFieldAnswersMapped as any)[question].questionId
            ) {
              (medFieldAnswersMapped as any)[question].text = tempValue;
            }
          }
        }
      }
      if (type === 'SINGLE_CHOICE' || type === 'MULTIPLE_CHOICE') {
        tempValue = answer;
        handleFormItemState(questionId, answer);
        if (questionId) {
          for (const question in medFieldAnswersMapped) {
            if (
              questionId === (medFieldAnswersMapped as any)[question].questionId
            ) {
              switch (type) {
                case 'SINGLE_CHOICE':
                  (medFieldAnswersMapped as any)[question].selectedAnswers = [
                    tempValue,
                  ];
                  break;

                case 'MULTIPLE_CHOICE':
                  (medFieldAnswersMapped as any)[question].selectedAnswers =
                    tempValue;
                  break;
              }
            }
          }
        }
      }
      if (type === 'FILE_UPLOAD') {
        // let mappedData;
        // tempValue = event.file.originFileObj;
        // let fileType = event?.file?.type;
        // let fileName = event?.file?.name;
        // let fileNameArr = event?.file?.name?.split('.');
        // if (fileType === '') {
        //   if (fileNameArr[fileNameArr.length - 1] === 'doc') fileType = 'application/msword';
        //   if (fileNameArr[fileNameArr.length - 1] === 'docx')
        //     fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        // }
        // setContentType(fileType);
        if (questionId) {
          for (const question in medFieldAnswersMapped) {
            if (
              questionId === (medFieldAnswersMapped as any)[question].questionId
            ) {
              const file = await filterFiles(event);
              (medFieldAnswersMapped as any)[question].files = file;
              // getBase64(tempValue)
              //   .then((result: any) => {
              //     tempValue['base64'] = result;
              //     result = result.split(';base64,')[1];
              //     mappedData = filesMapper(event.file.type, result, fileName);
              //     if ((medFieldAnswersMapped as any)[question].files.length) {
              //       (medFieldAnswersMapped as any)[question].files.push(mappedData);
              //     } else {
              //       (medFieldAnswersMapped as any)[question].files = [mappedData];
              //     }
              //   })
              //   .catch((err: any) => {
              //     console.error(err);
              //   });
            }
          }
        }
      }
      if (type === 'DATE') {
        tempValue = event;
        if (questionId) {
          for (const question in medFieldAnswersMapped) {
            if (
              questionId === (medFieldAnswersMapped as any)[question].questionId
            ) {
              (medFieldAnswersMapped as any)[question].date = tempValue;
            }
          }
        }
      }
      return updateWebFormState(
        medFieldAnswersMapped,
        'UPDATE_MEDICINE_FIELD_ANSWERS'
      );
    };

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingScreen />}>
        <Divider orientation="left" className="mt-0">
          <Title className="text-gray-400 mb-0" level={4}>
            {chosenMedField?.name}
          </Title>
        </Divider>
        <Form noValidate className="flex justify-between flex-wrap">
          {formQuestions?.data?.map((item, index) => (
            <Form.Item
              className="mx-3"
              name={item?.name}
              rules={[{ required: item?.required, message: item?.message }]}
            >
              {createElement(item?.component, {
                className:
                  'public-dp rounded-md w-[300px] text-primary-text text-sm',
                size: 'large',
                placeholder: item?.text,
                options: item?.options,
                format: item.type === 'DATE' ? 'DD. MM. YYYY' : 'HH:mm',
                disabled: !patientData[item?.id],
                ...(item.type === 'MULTIPLE_CHOICE' && { mode: 'multiple' }),
                min: (item.type === 'DECIMAL' || item.type === 'INTEGER') && 0,
                max:
                  (item.type === 'DECIMAL' || item.type === 'INTEGER') && 10000,
                type:
                  (item.type === 'DECIMAL' || item.type === 'INTEGER') &&
                  'number',
                stringMode:
                  (item.type === 'DECIMAL' || item.type === 'INTEGER') && true,
                step: item.type === 'DECIMAL' ? '0.01' : '1',
                value:
                  item.type === 'FREE_TEXT' ||
                  item.type === 'DECIMAL' ||
                  item.type === 'INTEGER'
                    ? medFieldAnswers[index]?.text
                    : item.type === 'FILE_UPLOAD'
                    ? medFieldAnswers[index]?.file
                    : item.type === 'SINGLE_CHOICE'
                    ? medFieldAnswers[index]?.selectedAnswers[0]
                    : item.type === 'DATE'
                    ? medFieldAnswers[index]?.date
                    : medFieldAnswers[index]?.selectedAnswers,
                onChange: handleMedFieldItem(
                  item?.type,
                  item?.options,
                  item?.id
                ),
                locale,
              })}
            </Form.Item>
          ))}
        </Form>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Step2;
