import {
  DatePicker,
  Divider,
  Form,
  Input,
  Select,
  Skeleton,
  Typography,
} from 'antd';
import React, {
  ChangeEvent,
  createElement,
  Suspense,
  useContext,
  useEffect,
  useState,
} from 'react';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import { LoadingScreen } from '../../../../core/components/LoadingScreen';
import { useIntl } from 'react-intl';
import {
  basicInfo,
  FormItem,
  MedFieldAnswers,
  Options,
  TicketAnswers,
} from '../../constants/stepsData';
import {
  FieldEnableContext,
  formItemsStateMapper,
  medFieldAnswersMapper,
} from '../../../../contexts/WebFormState';
import useForm from '../../../../hooks/useForm';
import {
  dataMapper,
  doctorsMapper,
  getAllDoctors,
  getAllGendersMapper,
  getAllMedFields,
  getDefaultQuestions,
  getEnums,
  medFieldsMapper,
} from '../../helpers/stepHelpers';
import useSelectOptions from '../../../../hooks/useSelectOptions';
import { filesMapper } from 'src/pages/OnlineMeetingWrapper/components/OnlineMeeting/components/MeetingDrawer/helpers/textChatHelper';
import useGenders from '../../hooks/useGenders';
import locale from 'antd/es/date-picker/locale/sr_RS';
import useDependantQuery from 'src/hooks/useDependantQuery';

const { Title } = Typography;

const formItemsMap = new Map<number, Array<number> | undefined>();

let updatedForm: TicketAnswers = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  medicineFieldId: undefined,
  doctorId: undefined,
  birthDate: '',
  gender: undefined,
};

let medFieldAnswersMapped: Array<MedFieldAnswers> | undefined = [];

const Step1: React.FC = () => {
  const intl = useIntl();

  const { patientData, ticketAnswers, medFieldAnswers, updateWebFormState } =
    useContext(FieldEnableContext);

  const [defaultForm, setDefaultForm] = useState<Array<FormItem>>();
  const [contentType, setContentType] = useState('');

  const formQuestions = useForm(
    'form-questions',
    getDefaultQuestions,
    dataMapper
  );

  const allMedFields = useSelectOptions(
    'all-medicine-fields',
    getAllMedFields,
    medFieldsMapper,
    1
  );

  const allDoctors = useDependantQuery(
    ['all-doctors', ticketAnswers.medicineFieldId],
    getAllDoctors,
    doctorsMapper,
    ticketAnswers.medicineFieldId
  );

  const allGenders = useGenders(
    'all-genders',
    getEnums,
    getAllGendersMapper,
    1
  );

  formItemsStateMapper(basicInfo.concat(formQuestions.data));

  if (medFieldAnswers.length === 0)
    medFieldAnswersMapped = medFieldAnswersMapper(formQuestions.data);

  useEffect(() => {
    formQuestions.refetch();
    setDefaultForm(formQuestions.data);
  }, [defaultForm]);

  if (formQuestions.isLoading) {
    return <Skeleton active paragraph={{ rows: 7 }} />;
  }

  if (formQuestions.isError) {
    if (formQuestions.error instanceof Error) {
      return <h3>{formQuestions.error.message}</h3>;
    }
  }

  const handleFormItemState = (questionId: number, answer: Options) => {
    if (formItemsMap.has(questionId)) {
      updateWebFormState(formItemsMap.get(questionId), 'CHANGE_FIELD_STATE');
      if (answer.triggersQuestions?.length !== 0) {
        formItemsMap.set(questionId, answer.triggersQuestions);
      } else {
        formItemsMap.delete(questionId);
      }
    } else if (
      !formItemsMap.has(questionId) &&
      answer.triggersQuestions &&
      answer.triggersQuestions?.length !== 0
    ) {
      formItemsMap.set(questionId, answer.triggersQuestions);
      updateWebFormState(answer.triggersQuestions, 'CHANGE_FIELD_STATE');
    }
  };

  const handleFormItem =
    (
      type: any,
      options: Array<Options> | undefined,
      questionId: number,
      questionName?: string
    ) =>
    (event: ChangeEvent<HTMLInputElement> | any, answer: any) => {
      let tempValue: string | number | undefined = '';
      if (type === Input) {
        tempValue = event.target.value;
        if (questionName) (updatedForm as any)[questionName] = tempValue;
      }
      if (type === Select) {
        if (answer?.id) tempValue = answer?.id;
        else tempValue = answer?.name;
        if (questionName === 'medicineFieldId') {
          sessionStorage.setItem('medFieldId', answer?.id);
          sessionStorage.setItem('medFieldName', answer?.value);
        }
        handleFormItemState(questionId, answer);
        if (questionName) (updatedForm as any)[questionName] = tempValue;
      }
      if (type === DatePicker) {
        tempValue = event;
        if (questionName) (updatedForm as any)[questionName] = tempValue;
      }
      updateWebFormState(updatedForm, 'UPDATE_TICKET_ANSWERS');
    };

  const getBase64 = (event: any) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL: any = '';
      let reader = new FileReader();

      reader.readAsDataURL(event);

      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleMedFieldItem =
    (
      type: string,
      options: Array<Options> | undefined,
      questionId: number,
      questionName?: string
    ) =>
    (event: ChangeEvent<HTMLInputElement> | any, answer: Options) => {
      let tempValue: string | number | Options | any = '';
      if (type === 'FREE_TEXT' || type === 'DECIMAL' || type === 'INTEGER') {
        tempValue = type === 'FREE_TEXT' ? event.target.value : event;
        if (questionId) {
          for (const question in medFieldAnswersMapped) {
            if (
              questionId === (medFieldAnswersMapped as any)[question].questionId
            ) {
              (medFieldAnswersMapped as any)[question].text = tempValue;
            }
          }
        }
      }
      if (type === 'SINGLE_CHOICE' || type === 'MULTIPLE_CHOICE') {
        tempValue = answer;
        handleFormItemState(questionId, answer);
        if (questionId) {
          for (const question in medFieldAnswersMapped) {
            if (
              questionId === (medFieldAnswersMapped as any)[question].questionId
            ) {
              switch (type) {
                case 'SINGLE_CHOICE':
                  (medFieldAnswersMapped as any)[question].selectedAnswers = [
                    tempValue,
                  ];
                  break;

                case 'MULTIPLE_CHOICE':
                  (medFieldAnswersMapped as any)[question].selectedAnswers =
                    tempValue;
                  break;
              }
            }
          }
        }
      }
      if (type === 'FILE_UPLOAD') {
        let mappedData;
        tempValue = event.file.originFileObj;
        let fileType = event?.file?.type;
        let fileName = event?.file?.name;
        let fileNameArr = event?.file?.name?.split('.');
        if (fileType === '') {
          if (fileNameArr[fileNameArr.length - 1] === 'doc')
            fileType = 'application/msword';
          if (fileNameArr[fileNameArr.length - 1] === 'docx')
            fileType =
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        }
        setContentType(fileType);
        if (questionId) {
          for (const question in medFieldAnswersMapped) {
            if (
              questionId === (medFieldAnswersMapped as any)[question].questionId
            ) {
              getBase64(tempValue)
                .then((result: any) => {
                  tempValue['base64'] = result;
                  result = result.split(';base64,')[1];
                  mappedData = filesMapper(event.file.type, result, fileName);
                  (medFieldAnswersMapped as any)[question].files = [mappedData];
                })
                .catch((err: any) => {
                  console.error(err);
                });
            }
          }
        }
      }
      return updateWebFormState(
        medFieldAnswersMapped,
        'UPDATE_MEDICINE_FIELD_ANSWERS'
      );
    };

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingScreen />}>
        {formQuestions?.data?.length !== 0 && (
          <>
            <Title className="text-gray-400 mb-0" level={4}>
              Osnovne Informacije
            </Title>
            <Divider className="mt-0" />
          </>
        )}
        <Form noValidate className="flex my-10 justify-between flex-wrap">
          {basicInfo.map((item: FormItem) => (
            <Form.Item
              className="mx-3"
              name={item.name}
              rules={[{ required: item.required, message: item.message }]}
            >
              {createElement(item.type, {
                className: 'rounded-md w-[300px] text-primary-text',
                size: 'large',
                placeholder: item.text,
                options: item.options ?? eval(item.optionsHook)?.data,
                disabled: !patientData[item.id],
                ...(item.name === 'doctorId' && {
                  disabled: ticketAnswers.medicineFieldId
                    ? false
                    : !patientData[item.id],
                }),
                value: ticketAnswers[item?.name],
                defaultValue: ticketAnswers[item?.name],
                format: item.type === DatePicker ? 'DD. MM. YYYY.' : 'HH:mm',
                onChange: handleFormItem(
                  item.type,
                  item.options,
                  item.id,
                  item.name
                ),
                locale,
              })}
            </Form.Item>
          ))}
        </Form>
        {formQuestions?.data?.length !== 0 && (
          <>
            <Title className="text-gray-400 mb-0" level={4}>
              Dodatne Informacije
            </Title>
            <Divider className="mt-0" />
          </>
        )}
        <Form noValidate className="flex justify-between flex-wrap">
          {formQuestions?.data?.map((item, index) => (
            <Form.Item
              className="mx-3"
              name={item?.name}
              rules={[{ required: item?.required, message: item?.message }]}
            >
              {createElement(item?.component, {
                className: 'rounded-md w-[300px] text-primary-text',
                size: 'large',
                placeholder: item?.text,
                options: item?.options,
                disabled: !patientData[item?.id],
                format: item.type === 'DATE' ? 'DD. MM. YYYY' : 'HH:mm',
                mode: item.type === 'MULTIPLE_CHOICE' && 'multiple',
                min: (item.type === 'DECIMAL' || item.type === 'INTEGER') && 0,
                max:
                  (item.type === 'DECIMAL' || item.type === 'INTEGER') && 10000,
                type:
                  (item.type === 'DECIMAL' || item.type === 'INTEGER') &&
                  'number',
                stringMode:
                  (item.type === 'DECIMAL' || item.type === 'INTEGER') && true,
                step: item.type === 'DECIMAL' ? '0.01' : '1',
                value:
                  item.type === 'FREE_TEXT' ||
                  item.type === 'DECIMAL' ||
                  item.type === 'INTEGER'
                    ? medFieldAnswers[index]?.text
                    : item.type === 'FILE_UPLOAD'
                    ? medFieldAnswers[index]?.file
                    : item.type === 'SINGLE_CHOICE'
                    ? medFieldAnswers[index]?.selectedAnswers[0]
                    : medFieldAnswers[index]?.selectedAnswers,
                onChange: handleMedFieldItem(
                  item?.type,
                  item?.options,
                  item?.id
                ),
                locale,
              })}
            </Form.Item>
          ))}
        </Form>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Step1;
