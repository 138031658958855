import { Message } from 'src/pages/OnlineMeetingWrapper/components/OnlineMeeting/components/MeetingDrawer/constants/textChatData';
import { IRatingData } from 'src/pages/Rating/constants/ratingInterfaces';

const OnlineMeetingReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'UPDATE_CHAT':
      const updatedChatMessage: Message = action.payload;
      return { ...state, messages: [...state.messages, updatedChatMessage] };
    case 'GET_CHAT':
      const chatMessages: Array<Message> = action.payload;
      return { ...state, messages: chatMessages };
    case 'UPLOAD_FILE':
      const selectedFile: any = action.payload;
      return { ...state, files: [...state.files, selectedFile] };
    case 'CLEAR_FILES':
      return { ...state, files: [] };
    case 'RATE_MEETING':
      const updatedRating: IRatingData = action.payload;
      return { ...state, ratingData: updatedRating };
    case 'MESSAGES_COUNT':
      const updatedMessagesCount: any = action.payload;
      return { ...state, messagesCount: updatedMessagesCount };
    case 'COLLAPSE_DRAWER':
      const updatedCollapsed: boolean = action.payload;
      return { ...state, collapsed: updatedCollapsed };
    default:
      return state;
  }
};

export { OnlineMeetingReducer };
