import jwt from 'jwt-decode';

const checkTokenRole = () => {
  const token = localStorage.getItem('telemedicina_access_token');
  if (token) {
    try {
      const decodedToken: any = jwt(token);
      return decodedToken.role === 'ROLE_ADMIN';
    } catch (error) {
      console.error(error);
    }
  }
  return false;
};

export default checkTokenRole;
