import React, { useContext } from 'react';
import { Modal } from 'antd';
import { FooterComponent, TitleComponent } from './components/ModalComponents';
import { steps } from './constants/steps';
import { FieldEnableContext } from '../../contexts/WebFormState';
import usePostTicketInfo from './hooks/usePostTicketInfo';
import {
  getEnums,
  postAnswersMapper,
  postMedFieldAnswers,
  postTicketAnswers,
  ticketAnswersMapper,
} from './helpers/stepHelpers';
import PublicWrapper from '../../core/components/PublicWrapper';
import CustomButton from '../../components/CustomComponents/CustomButton/CustomButton';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { setupAxios } from 'src/axios/setupAxios';

const PatientWebForm: React.FC = () => {
  const { step, ticketAnswers, medFieldAnswers, updateWebFormState } =
    useContext(FieldEnableContext);

  const redirect = useNavigate();

  const tempFieldAnswers = medFieldAnswers?.map((item: any) => item);

  const handleChangeStep = () => () => {
    updateWebFormState(step + 1, 'CHANGE_STEP_NUMBER');
  };

  const handleGetEnums = useQuery('get-enums', () => setupAxios(getEnums()));

  const postFirstStep = usePostTicketInfo(
    postTicketAnswers,
    postMedFieldAnswers,
    {
      tempFieldAnswers,
      handleChangeStep,
    }
  );

  const postOtherSteps = usePostTicketInfo(postMedFieldAnswers, undefined, {
    tempFieldAnswers,
    handleChangeStep,
  });

  const handleNextStep = (stepNumber: number) => {
    const tempStepNumber: number = stepNumber + 1;
    const mappedTicketAnswers = ticketAnswersMapper(ticketAnswers);
    switch (stepNumber) {
      case 1:
        postFirstStep.mutate(mappedTicketAnswers);
        break;
      case 2:
        const ticketId = parseInt(sessionStorage.getItem('ticket_id') || '');
        const mappedMedFieldAnswers = postAnswersMapper(
          medFieldAnswers,
          ticketId
        );
        postOtherSteps.mutate(mappedMedFieldAnswers);
        break;
      case 3:
        // window.open('about:blank', '_self');
        // window.close();
        window.location.reload();
        break;
    }
  };

  return (
    <>
      <PublicWrapper />
      <Modal
        className="w-full my-20 md:w-[700px] m-auto"
        visible={true}
        closable={false}
        centered={true}
        title={
          <TitleComponent
            handleNextStep={handleNextStep}
            currentStep={step}
            enumsData={handleGetEnums?.data?.data}
          />
        }
        footer={
          <FooterComponent
            handleNextStep={handleNextStep}
            currentStep={step}
            enumsData={handleGetEnums?.data?.data}
          />
        }
      >
        {steps[step]()}
      </Modal>
      <CustomButton
        ghost
        type="default"
        text="Prijavi se (samo za operatere)"
        className="absolute z-[10000] bottom-8 right-8"
        onClick={() => redirect('/operator/login')}
      />
    </>
  );
};

export default PatientWebForm;
