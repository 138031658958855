import {
  useDaily,
  useNetwork,
  useParticipantIds,
  useRoom,
} from '@daily-co/daily-react-hooks';

type Props = {
  user: any;
};

export default function MeetingInformation({ user }: Props) {
  const callObject = useDaily();
  const room = useRoom();
  const network = useNetwork();
  const allParticipants = useParticipantIds()?.toString();

  return (
    <section className="meeting-information">
      <h1>Informacije o pozivu</h1>
      <ul>
        <li>Stanje: {callObject?.meetingState() ?? 'nepoznato'}</li>
        <li>Status mreže: {network?.threshold ?? 'nepoznato'}</li>
        <li>Topologija mreže: {network?.topology ?? 'nepoznato'}</li>
        <li>Jedinstveni brojevi korisnika: {allParticipants}</li>
      </ul>
    </section>
  );
}
