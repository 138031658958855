import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import React from 'react';
import useAuth from '../../hooks/useAuth';
import Navbar from '../Operator/components/Navbar';
import { AdminStateProvider } from '../../contexts/AdminState';
import checkTokenRole from '../../utils/checkTokenRole';

const Admin: React.FC = () => {
  const { Header, Content } = Layout;
  const isAuth = useAuth();
  const isAdmin = checkTokenRole();

  return (
    <Layout className="h-screen w-screen gradient-background">
      <AdminStateProvider>
        {isAuth && isAdmin && <Navbar Header={Header} />}
        <Content className="overflow-auto bg-white rounded-lg my-6 mx-4 p-6 opacity-90">
          <Outlet />
        </Content>
      </AdminStateProvider>
    </Layout>
  );
};

export default Admin;
