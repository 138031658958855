import { Select } from 'antd';
import React, { ReactNode } from 'react';
import { IconContext } from 'react-icons';
import { BsFilterRight } from 'react-icons/bs';

const { Option } = Select;

interface IFilter {
  suffixIcon?: ReactNode;
  className?: string;
  value?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  options?: any;
}

const Filter: React.VFC<IFilter> = ({
  suffixIcon,
  className,
  value,
  disabled,
  onChange,
  options,
}) => {
  return (
    <Select
      disabled={disabled}
      className={`rounded-md text-secondary-text ${className}`}
      value={value}
      suffixIcon={
        suffixIcon ?? (
          <IconContext.Provider value={{ size: '18', color: '#EA5CB4' }}>
            <BsFilterRight />
          </IconContext.Provider>
        )
      }
      onChange={(event) => onChange && onChange(event)}
    >
      {options &&
        options.map((option: any) => (
          <Option key={option.name} value={option.name}>
            {option.value}
          </Option>
        ))}
    </Select>
  );
};

Filter.defaultProps = {
  disabled: false,
};

export default Filter;
