import { Divider, Drawer, Form, Typography } from 'antd';
import React, { createElement, useContext } from 'react';
import { IconContext } from 'react-icons';
import { FaRegUserCircle } from 'react-icons/fa';
import { UseQueryResult } from 'react-query';
import { OperatorContext } from '../../../../../contexts/OperatorState';
import usePostCreate from '../../../hooks/usePostCreate';
import useSelectOptions from '../../../../../hooks/useSelectOptions';
import { fieldOfMedMapper, getAllMedFields } from '../../../../PatientWebForm/helpers/stepHelpers';
import { Doctor } from '../../../constants/operatorPages';
import { editDoctor, postDoctor, postDoctorMapper } from '../../../helpers/operatorHelpers';
import { CEDoctorValues, CEFormItem, formCEDoctor } from '../constants/doctorsData';
import { LoadingScreen } from 'src/core/components/LoadingScreen';
import CustomButton from 'src/components/CustomComponents/CustomButton/CustomButton';

const { Title } = Typography;

interface DrawerProps {
  handleCEDrawer: (id?: number) => void;
  drawerVisibility: boolean;
  doctors: UseQueryResult<Array<Doctor>, unknown>;
  editId?: number;
}

const CEDoctorDrawer: React.FC<DrawerProps> = ({
  handleCEDrawer,
  drawerVisibility,
  doctors,
  editId,
}: DrawerProps) => {
  const { doctorCEForm } = useContext(OperatorContext);

  const editDoctorForm = usePostCreate(
    editDoctor,
    {
      onSuccessMessage: 'Uspešno izmenjen lekar!',
      onErrorMessage: 'Greška! Lekar nije izmenjen!',
    },
    doctors.refetch
  );
  const postDoctorForm = usePostCreate(
    postDoctor,
    {
      onSuccessMessage: 'Uspešno kreiran lekar!',
      onErrorMessage: 'Greška! Lekar nije kreiran!',
    },
    doctors.refetch
  );

  const allMedFields = useSelectOptions(
    'all-medicine-fields',
    getAllMedFields,
    fieldOfMedMapper,
    1
  );

  const handleOnFinish = (values: CEDoctorValues) => {
    const mappedDoctorForm = postDoctorMapper(values, doctorCEForm.enabled);
    if (editId) {
      editDoctorForm.mutate({ data: mappedDoctorForm, id: editId });
      handleCEDrawer();
    } else {
      postDoctorForm.mutate(mappedDoctorForm);
      handleCEDrawer();
    }
  };

  const doctorsInitialValue = doctorCEForm.medicineFields.map((item: any) => item.id);

  if (allMedFields.isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Drawer
        title={
          <Title level={4} className='text-primary-text font-bold flex mb-0 justify-end'>
            {editId ? 'Izmeni lekara' : 'Dodaj lekara'}
          </Title>
        }
        placement='right'
        onClose={() => handleCEDrawer()}
        visible={drawerVisibility}
        width={window.innerWidth < 500 ? window.innerWidth : 500}
        destroyOnClose
      >
        <Form
          noValidate
          onFinish={handleOnFinish}
          className='flex flex-col items-center'
          layout='vertical'
        >
          <IconContext.Provider
            value={{
              className: 'mx-auto w-full mb-4',
              size: '90',
              color: '#F259B3',
            }}
          >
            <FaRegUserCircle />
          </IconContext.Provider>
          <Divider>
            <Title level={4} className='text-center w-full text-primary-text font-bold'>
              Podaci o lekaru
            </Title>
          </Divider>
          {formCEDoctor.map((item: CEFormItem) => (
            <Form.Item
              className='my-1'
              name={item.name}
              rules={[{ required: item.required, message: item.message }]}
              initialValue={
                item.name === 'medicineFields' ? doctorsInitialValue : doctorCEForm[item.name]
              }
              label={item.text}
              key={item.name}
            >
              {createElement(item.type, {
                className: 'rounded-md w-[300px]',
                placeholder: item.text,
                options: item.name === 'medicineFields' ? allMedFields.data : '',
                mode: item?.mode,
              })}
            </Form.Item>
          ))}
          <Form.Item key='submit' className='mt-10 mx-auto flex text-center'>
            <CustomButton
              htmlType='submit'
              className='h-[40px] w-[200px] bg-btn-primary font-bold text-white hover:opacity-80'
              type='primary'
              text='Sačuvaj'
            />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default CEDoctorDrawer;
