import { Input, Modal, Rate, Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { setupAxios } from 'src/axios/setupAxios';
import CustomButton from 'src/components/CustomComponents/CustomButton/CustomButton';
import { RatingContext } from 'src/contexts/RatingState';
import { LoadingScreen } from 'src/core/components/LoadingScreen';
import { IRatingData } from 'src/pages/Rating/constants/ratingInterfaces';
import {
  getIfHasRating,
  postRating,
} from 'src/pages/Rating/helpers/ratingHelpers';

type Props = {};

let localRatingData: IRatingData = {
  grade: 0,
  comment: '',
};

const RatingModal = (props: Props) => {
  const [stars, setStars] = useState<number>(0);
  const [step, setStep] = useState<1 | 2>(1);

  const { ratingData, updateRatingState } = useContext(RatingContext);

  const token: string | null = localStorage.getItem('be_auth_token');

  const description = [
    'Veoma nezadovoljan/na',
    'Nezadovoljan/na',
    'Neutralan/na',
    'Zadovoljan/na',
    'Veoma zadovoljan/na',
  ];

  const checkIfHasRating = useQuery(
    'check-ratings',
    () => setupAxios(getIfHasRating(token)),
    { refetchOnWindowFocus: false }
  );

  const mutateRating = useMutation(
    'mutate-rating',
    () => setupAxios(postRating(token, ratingData)),
    { onSuccess: () => handleNextStep() }
  );

  useEffect(() => {
    if (checkIfHasRating.isSuccess) {
      if (checkIfHasRating?.data?.data?.exists) handleNextStep();
    }
  }, [checkIfHasRating.isSuccess]);

  if (checkIfHasRating.isLoading) {
    return <LoadingScreen />;
  }

  if (checkIfHasRating.isError) {
    if (checkIfHasRating.error instanceof Error) {
      return <h3>{checkIfHasRating.error.message}</h3>;
    }
  }

  const handleChangeRating = (item: string) => (event: number | any) => {
    let tempValue;
    if (item === 'grade') {
      tempValue = event;
      localRatingData.grade = tempValue;
    } else {
      tempValue = event.target.value;
      localRatingData.comment = tempValue;
    }
    updateRatingState(localRatingData, 'RATE_MEETING');
  };

  const handleNextStep = () => {
    setStep(2);
  };

  return (
    <Modal
      className="min-w-[300px] mx-auto"
      visible={true}
      closable={false}
      centered={true}
      title={
        <Typography.Title className="text-primary-text text-center" level={4}>
          Ocenite svoje zadovoljstvo online konsultacijom
        </Typography.Title>
      }
      footer={
        step === 1 ? (
          <div className="my-2 text-center">
            <CustomButton
              text="Potvrdite"
              className="bg-btn-primary font-bold text-base text-white h-[40px] w-[200px]"
              onClick={mutateRating.mutate}
            />
          </div>
        ) : (
          ''
        )
      }
    >
      {step === 1 ? (
        <div className="flex justify-center items-center flex-col">
          <Rate
            tooltips={description}
            onChange={handleChangeRating('grade')}
            value={ratingData?.grade}
          />
          {ratingData?.grade ? (
            <span className="ant-rate-text">
              {description[ratingData?.grade - 1]}
            </span>
          ) : (
            ''
          )}
          <Input.TextArea
            size="large"
            className="my-5"
            placeholder="Ovde napišite komentar..."
            value={ratingData?.comment}
            onChange={handleChangeRating('comment')}
          />
        </div>
      ) : (
        <Typography.Title className="text-primary-text" level={4}>
          Hvala Vam što ste popunili anketu!
        </Typography.Title>
      )}
    </Modal>
  );
};

export default RatingModal;
