import { Typography } from 'antd';
import moment from 'moment';
import { IconContext } from 'react-icons';
import { RiFileDownloadFill } from 'react-icons/ri';
import { PacientForm } from '../constants/onlineMeetingData';

export const pacientFormMapper = ({ text, type, answer, displayOrder }: PacientForm) => {
  const token = localStorage.getItem('be_auth_token') ?? window.location.pathname.split('/')[2];
  let answerFormatted = '';
  if (answer?.text === null) {
    if (answer?.selectedAnswers.length !== 0) answerFormatted = answer?.selectedAnswers.join();
    else {
      answerFormatted = answer?.files.map((file: any) => {
        return (
          <a
            className='pointer hover:bg-gray-400 transition flex items-center break-all w-fit bg-secondary-bg rounded-md px-2 m-1'
            href={`${file?.url}?token=${token}`}
            download={file?.name}
          >
            <IconContext.Provider
              value={{
                className: 'm-2 ml-0 h-5 w-5',
                color: '#f14dae',
              }}
            >
              <RiFileDownloadFill />
              <Typography className='text-white mr-2'>{file?.name}</Typography>
            </IconContext.Provider>
          </a>
        );
      });
    }
  } else {
    if (type === 'DATE') answerFormatted = moment(answer?.text).format('DD. MM. YYYY');
    else answerFormatted = answer?.text;
  }

  return {
    text,
    answerFormatted,
    displayOrder,
  };
};

export const timeLeftMapper = (secondsToEnd: number) => {
  secondsToEnd = Number(secondsToEnd);
  var h = Math.floor(secondsToEnd / 3600);
  var m = Math.floor((secondsToEnd % 3600) / 60);
  var s = Math.floor((secondsToEnd % 3600) % 60);

  var hDisplay = h > 0 ? h : '';
  var mDisplay = m > 0 ? m : '';
  var sDisplay = s > 0 ? s : '';
  return `${hDisplay}:${mDisplay}:${sDisplay}`;
};
