import { Message } from '../constants/textChatData';

export const messagesMapper = ({
  senderId,
  senderName,
  text,
  files,
  type,
  sentTime,
}: Message) => {
  return { senderId, senderName, text, files, type, sentTime };
};

export const filesMapper = (
  contentType: string,
  encodedContent: string,
  name?: string
) => {
  return {
    contentType,
    encodedContent,
    name,
  };
};
