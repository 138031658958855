import React, { useCallback, useState } from 'react';
import {
  useDaily,
  useScreenShare,
  useLocalParticipant,
  useVideoTrack,
  useAudioTrack,
} from '@daily-co/daily-react-hooks';
import MeetingInformation from './MeetingInformation';
import { IconContext } from 'react-icons';
import { Button, Dropdown, Menu, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { api } from 'src/axios/setupAxios';
import { BiMicrophone, BiMicrophoneOff, BiStar } from 'react-icons/bi';
import { BsCameraVideo, BsCameraVideoOff, BsQuestionLg } from 'react-icons/bs';
import useWindowSize, { windowSizeType } from 'src/hooks/useWindowSize';

interface TrayProps {
  leaveCall: any;
  toggleFocus: () => void;
  focusMe: boolean;
  user: any;
  handleRatingModal: () => void;
  ratingModal: boolean;
}

const Tray: React.FC<TrayProps> = ({
  leaveCall,
  toggleFocus,
  focusMe,
  user,
  handleRatingModal,
  ratingModal,
}: TrayProps) => {
  const windowSize = useWindowSize();

  const { isSharingScreen, startScreenShare, stopScreenShare } =
    useScreenShare();

  const [showMeetingInformation, setShowMeetingInformation] = useState(false);

  // const navigate = useNavigationHook();

  // const redirect = (path: string) => {
  //   navigate(path);
  // };
  const redirect = useNavigate();

  const token: string | null = localStorage.getItem('be_auth_token');

  const callObject = useDaily();
  const localParticipant = useLocalParticipant();

  const localVideo = useVideoTrack(localParticipant?.session_id ?? '');
  const localAudio = useAudioTrack(localParticipant?.session_id ?? '');

  const mutedVideo = localVideo.isOff;
  const mutedAudio = localAudio.isOff;

  const toggleVideoAndMic = useCallback(() => {
    callObject?.setLocalVideo(mutedVideo);
    callObject?.setLocalAudio(mutedVideo);
  }, [callObject, mutedAudio, mutedAudio]);

  const toggleVideo = useCallback(() => {
    callObject?.setLocalVideo(mutedVideo);
  }, [callObject, mutedVideo]);

  const toggleAudio = useCallback(() => {
    callObject?.setLocalAudio(mutedAudio);
  }, [callObject, mutedAudio]);

  const toggleScreenShare = () =>
    isSharingScreen ? stopScreenShare() : startScreenShare();

  // const leave = () => {
  //   leaveCall();
  //   redirect(`/rating/${token}`);
  // };

  const toggleMeetingInformation = () => {
    showMeetingInformation
      ? setShowMeetingInformation(false)
      : setShowMeetingInformation(true);
  };

  const postAssistanceRequest = () => {
    return api({
      method: 'post',
      url: `api/meetings/assistance/${token}`,
    });
  };

  const askForAssistance = () => {
    postAssistanceRequest()
      .then((response) => {
        notification.config({
          duration: 3,
        });

        notification['success']({
          message: response?.data?.message,
          description: response?.data?.description,
          placement: 'top',
        });
      })
      .catch((error) => {
        notification.config({
          duration: 3,
        });

        notification['error']({
          message: error.message,
          description: error.description,
          placement: 'top',
        });
      });
  };

  const trayClassName: Record<windowSizeType, string> = {
    'wide-desktop': '',
    desktop: '',
    tablet: '',
    mobile: 'w-screen rounded-md',
    none: 'w-screen rounded-md',
  };

  return (
    <div
      className={`tray bg-white text-primary-text opacity-90 ${trayClassName[windowSize]}`}
    >
      {showMeetingInformation && <MeetingInformation user={user} />}
      <div className="flex items-baseline">
        <Button
          className={`w-auto h-max mx-2 border-0 text-slate-500 hover:text-[#EB008B]`}
          onClick={() => askForAssistance()}
        >
          <IconContext.Provider
            value={{
              className: 'm-auto w-5 h-5',
            }}
          >
            <BsQuestionLg /> Zatražite pomoć
          </IconContext.Provider>
        </Button>
        {windowSize !== 'tablet' && windowSize !== 'mobile' ? (
          <div className="actions">
            <Button
              className={`w-auto h-max mx-2 border-0 shadow-none hover:text-[#FF0000] ${
                mutedVideo ? 'text-[#FF0000]' : 'text-primary-text'
              }`}
              onClick={toggleVideo}
            >
              {mutedVideo ? (
                <IconContext.Provider
                  value={{
                    className: 'm-auto w-5 h-5',
                  }}
                >
                  <BsCameraVideoOff />
                </IconContext.Provider>
              ) : (
                <IconContext.Provider
                  value={{
                    className: 'm-auto w-5 h-5',
                  }}
                >
                  <BsCameraVideo />
                </IconContext.Provider>
              )}
              {mutedVideo ? 'Uključi kameru' : 'Isključi kameru'}
            </Button>
            <Button
              className={`w-auto h-max mx-2 border-0 shadow-none hover:text-[#FF0000] ${
                mutedAudio ? 'text-[#FF0000]' : 'text-primary-text'
              }`}
              onClick={toggleAudio}
            >
              {mutedAudio ? (
                <IconContext.Provider
                  value={{
                    className: 'm-auto w-5 h-5',
                  }}
                >
                  <BiMicrophoneOff />
                </IconContext.Provider>
              ) : (
                <IconContext.Provider
                  value={{
                    className: 'm-auto w-5 h-5',
                  }}
                >
                  <BiMicrophone />
                </IconContext.Provider>
              )}
              {mutedAudio ? 'Uključi mikrofon' : 'Isključi mikrofon'}
            </Button>
          </div>
        ) : (
          <div className="actions">
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item
                    className={
                      mutedVideo ? 'text-[#FF0000]' : 'text-primary-text'
                    }
                    onClick={toggleVideo}
                  >
                    {mutedVideo ? (
                      <IconContext.Provider
                        value={{
                          className: 'm-auto w-5 h-5',
                        }}
                      >
                        <BsCameraVideoOff />
                      </IconContext.Provider>
                    ) : (
                      <IconContext.Provider
                        value={{
                          className: 'm-auto w-5 h-5',
                        }}
                      >
                        <BsCameraVideo />
                      </IconContext.Provider>
                    )}
                    {mutedVideo ? 'Uključi kameru' : 'Isključi kameru'}
                  </Menu.Item>
                  <Menu.Item
                    className={
                      mutedAudio ? 'text-[#FF0000]' : 'text-primary-text'
                    }
                    onClick={toggleAudio}
                  >
                    {mutedAudio ? (
                      <IconContext.Provider
                        value={{
                          className: 'm-auto w-5 h-5',
                        }}
                      >
                        <BiMicrophoneOff />
                      </IconContext.Provider>
                    ) : (
                      <IconContext.Provider
                        value={{
                          className: 'm-auto w-5 h-5',
                        }}
                      >
                        <BiMicrophone />
                      </IconContext.Provider>
                    )}
                    {mutedAudio ? 'Uključi mikrofon' : 'Isključi mikrofon'}
                  </Menu.Item>
                </Menu>
              }
              placement="top"
            >
              <Button
                className={`w-auto h-max mx-2 border-0 shadow-none hover:text-[#FF0000] ${
                  mutedVideo || mutedAudio
                    ? 'text-[#FF0000]'
                    : 'text-primary-text'
                }`}
              >
                <div className="flex">
                  {mutedVideo ? (
                    <IconContext.Provider
                      value={{
                        color: '#FF0000',
                        className: 'm-auto w-5 h-5',
                      }}
                    >
                      <BsCameraVideoOff />
                    </IconContext.Provider>
                  ) : (
                    <IconContext.Provider
                      value={{
                        className: 'm-auto w-5 h-5',
                      }}
                    >
                      <BsCameraVideo />
                    </IconContext.Provider>
                  )}
                  {mutedAudio ? (
                    <IconContext.Provider
                      value={{
                        color: '#FF0000',
                        className: 'm-auto w-5 h-5',
                      }}
                    >
                      <BiMicrophoneOff />
                    </IconContext.Provider>
                  ) : (
                    <IconContext.Provider
                      value={{
                        className: 'm-auto w-5 h-5',
                      }}
                    >
                      <BiMicrophone />
                    </IconContext.Provider>
                  )}
                </div>
                Kontrole
                {/* {mutedVideo || mutedAudio ? 'Odmutiraj se' : 'Mutiraj se'} */}
              </Button>
            </Dropdown>
          </div>
        )}
        <Button
          className={`w-auto h-max mx-2 border-0 shadow-none hover:text-[#FADB14] ${
            ratingModal ? 'text-[#FADB14]' : 'text-primary-text'
          }`}
          onClick={() => handleRatingModal()}
        >
          <IconContext.Provider
            value={{
              className: 'm-auto w-5 h-5',
            }}
          >
            <BiStar /> Ocenite poziv
          </IconContext.Provider>
        </Button>
        {/* <div className="actions"> */}
        {/* <button onClick={() => toggleFocus()}>
            <IconContext.Provider
              value={{
                className: 'm-auto w-[24px] h-[24px]',
              }}
            >
              <FaExchangeAlt />
            </IconContext.Provider>
            Zameni mesta korisnicima
          </button> */}
        {/* <button onClick={toggleScreenShare}>
            <Screenshare />
            {isSharingScreen ? 'Zaustavi deljenje ekrana' : 'Podeli ekran'}
          </button> */}
        {/* <button onClick={toggleMeetingInformation}>
            <Info />
            {showMeetingInformation
              ? 'Sakrij informacije'
              : 'Prikaži informacije'}
          </button> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default Tray;
