import { CEDoctorValues } from '../pages/Operator/pages/Doctors/constants/doctorsData';
import { LoginInput } from '../pages/Operator/pages/Login/constants/loginData';
import { ITicketsValues } from '../pages/Operator/pages/Tickets/interfaces/TicketsInterfaces';

const OperatorReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'LOGIN_OPERATOR':
      const updatedLoginCredentials: LoginInput = action.payload;
      return { ...state, loginCredentials: updatedLoginCredentials };
    case 'CE_DOCTOR':
      const updatedDoctor: CEDoctorValues = action.payload;
      return { ...state, doctorCEForm: updatedDoctor };
    case 'CE_TICKET':
      const updatedTicket: ITicketsValues = action.payload;
      return { ...state, ticketCEForm: updatedTicket };
    default:
      return state;
  }
};

export { OperatorReducer };
