import { Typography } from 'antd';
import moment from 'moment';
import { useCountdown } from 'src/pages/OnlineMeetingWrapper/hooks/useCountdown';

type Props = {
  endTime: string;
};

const CountdownTImer = ({ endTime }: Props) => {
  const timeLeft = moment.duration(moment(endTime)?.diff(moment(Date.now()))).asSeconds();
  const timeLeftFormatted = useCountdown(timeLeft);
  return (
    <div className='absolute top-2 right-2 z-[1]'>
      <Typography.Title
        className='text-white p-2 my-0 rounded-md bg-secondary-bg opacity-60'
        level={4}
      >
        {endTime !== '' && timeLeftFormatted ? timeLeftFormatted : ''}
      </Typography.Title>
    </div>
  );
};

export default CountdownTImer;
