import { Pagination, Popover, Select } from 'antd';
import React, { Suspense, useContext, useEffect, useMemo, useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { FaUserCog, FaUserPlus } from 'react-icons/fa';
import { sortDirectionOptions } from 'src/pages/Operator/pages/Tickets/components/TicketsData';
import { API_ADMIN } from 'src/pages/service/api_agent';
import CustomButton from '../../../components/CustomComponents/CustomButton/CustomButton';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Table from '../../../components/Table/Table';
import { AdminContext } from '../../../contexts/AdminState';
import { LoadingScreen } from '../../../core/components/LoadingScreen';
import useFormLocal from '../../Operator/hooks/useFormLocal';
import CEUsersDrawer from '../components/CEUsersDrawer';
import { getUsers, usersColumns, usersMapper } from '../constants/usersData';
import { IUser } from '../constants/usersInterfaces';
import useMutateData from '../hooks/useMutateData';

const Users: React.VFC = () => {
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const [editId, setEditId] = useState<number | undefined>();
  const [tablePage, setTablePage] = useState(0);
  const [size, setSize] = useState(localStorage.getItem('page-size') ?? '10');
  const [sort, setSort] = useState('firstName,lastName');
  const [order, setOrder] = useState('asc');

  const { updateAdminState } = useContext(AdminContext);

  const userColumnsMemo = useMemo(() => usersColumns, []);

  const { data, isLoading, isError, error, refetch, isPreviousData, isFetched } = useFormLocal(
    'get-users',
    getUsers,
    usersMapper,
    {
      size,
      sort: `${sort},${order}`,
      page: tablePage,
    }
  );

  const onUserStatusEdit = useMutateData(
    API_ADMIN.editUserStatus,
    {
      onSuccessMessage: 'Uspešno izmenjen status korisnika!',
      onErrorMessage: 'Greška! Status korisnika nije izmenjen!',
    },
    refetch
  );

  useEffect(() => {
    if (!isPreviousData || data?.hasMore) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePage, size, order]);

  const handleSort = (column: any, sortDirection: any) => {
    if (column.hasSort) {
      userColumnsMemo.map((item: any) => item.accessor === column.id && setSort(item.sortName));
      sortDirectionOptions.map(item => item.previous === sortDirection && setOrder(item.next));
    }
  };

  const handleSize = () => (value: string) => {
    localStorage.setItem('page-size', value);
    setTablePage(0);
    setSize(localStorage.getItem('page-size')!);
  };

  const handlePagination = () => (page: number) => {
    setTablePage(page - 1);
  };

  const handleUserStatus = (user: IUser) => {
    onUserStatusEdit.mutate({ user: { enabled: !user.enabled }, id: user.id });
  };

  const nameToLinkMapper = () => {
    data?.map((user: any) => {
      user.status = (
        <Popover
          mouseEnterDelay={0.1}
          placement='right'
          content={user.enabled ? 'Deaktiviraj korisnika' : 'Aktiviraj korisnika'}
        >
          <div className='flex justify-start w-max'>
            <CustomButton
              className={
                user.enabled
                  ? 'border-green-500 text-green-500 hover:text-red-500 hover:border-red-500 transition'
                  : 'border-red-500 text-red-500 hover:text-green-500 hover:border-green-500 transition'
              }
              icon={<FaUserCog className='mr-2' />}
              text={user.enabled ? ' Aktivan' : ' Neaktivan'}
              type='default'
              onClick={() => handleUserStatus(user)}
            />
          </div>
        </Popover>
      );
      user.actions = (
        <div className='flex flex-row gap-1 cursor-pointer justify-center pr-8'>
          <Popover mouseEnterDelay={0.1} placement='top' content='Uredi korisnika'>
            <BiEdit className='w-6 h-6' onClick={() => handleCEDrawer(user.id!)} />
          </Popover>
        </div>
      );
    });
  };

  const handleCEDrawer = (id?: number) => {
    if (id) {
      setDrawerVisibility(!drawerVisibility);
      setEditId(id);
      const user = data?.find((user: { id: number }) => user?.id === id);
      updateAdminState(user, 'CE_USER');
    } else {
      setDrawerVisibility(!drawerVisibility);
      if (drawerVisibility === true) {
        setEditId(id);
        const temp = {
          firstName: '',
          lastName: '',
          name: '',
          email: '',
          role: '',
        };
        updateAdminState(temp, 'CE_USER');
      }
    }
  };

  if (isError) {
    if (error instanceof Error) {
      return <h3>{error.message}</h3>;
    }
  }

  if (isFetched) {
    nameToLinkMapper();
  }

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingScreen />}>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <>
            <div className='flex justify-between mb-6'>
              <div />
              <CustomButton
                text='Dodaj korisnika'
                className='h-9 font-bold bg-btn-primary hover:opacity-80'
                onClick={() => handleCEDrawer()}
                icon={<FaUserPlus className='inline-block mb-1 mr-2' />}
              />
            </div>
            <CEUsersDrawer
              handleCEDrawer={handleCEDrawer}
              drawerVisibility={drawerVisibility}
              editId={editId}
              refetch={refetch}
            />
            <Table
              columns={userColumnsMemo}
              data={data}
              // search={search}
              // handleSearch={handleSearch}
              // confirmSearch={confirmSearch}
              handleSort={handleSort}
              sort={sort}
              order={order}
            />
            <div className='mt-2 flex justify-between'>
              <div />
              <Pagination
                className='flex'
                size='small'
                pageSize={1}
                current={tablePage + 1}
                defaultCurrent={1}
                total={Number(localStorage.getItem('pagination-size'))}
                onChange={handlePagination()}
                showSizeChanger={false}
              />
              <Select
                placeholder='Size'
                value={size}
                options={[{ value: 10 }, { value: 25 }, { value: 50 }]}
                onChange={handleSize()}
              />
            </div>
          </>
        )}
      </Suspense>
    </ErrorBoundary>
  );
};

export default Users;
