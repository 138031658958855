import { Modal, Typography } from 'antd';
import CustomButton from 'src/components/CustomComponents/CustomButton/CustomButton';

const refreshPage = () => {
  console.log(
    "make sure to allow access to your microphone and camera in your browser's permissions"
  );
  window.location.reload();
};

const UserMediaError = () => {
  return (
    <Modal
      className="min-w-[300px] mx-auto"
      visible={true}
      closable={false}
      centered={true}
      title={
        <Typography.Title className="text-primary-text text-center" level={4}>
          Greška
        </Typography.Title>
      }
      footer={
        <div className="flex justify-between items-center">
          <Typography className="text-primary-text">
            Broj za podršku:{' '}
            <a className="inline p-0" href={`tel:+381600687460`}>
              060/0687-460
            </a>
          </Typography>
          <CustomButton
            text="Pokušajte ponovo"
            className="bg-btn-primary font-bold text-base text-white h-[40px] w-[180px]"
            onClick={refreshPage}
          />
        </div>
      }
    >
      <Typography.Title className="text-primary-text text-center" level={4}>
        Kamera ili mikrofon su blokirani
      </Typography.Title>
    </Modal>
  );
};

export default UserMediaError;
