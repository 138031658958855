import { Button, DatePicker, Divider, Drawer, Form } from 'antd';
import Title from 'antd/lib/typography/Title';
import { createElement, useState } from 'react';
import { IconContext } from 'react-icons';
import { MdAnalytics } from 'react-icons/md';
import useDependantQuery from 'src/hooks/useDependantQuery';
import { CEFormItem } from '../../Doctors/constants/doctorsData';
import {
  allMeetings,
  analyticsForm,
  calculateMeetingsByDoctor,
  getTicketsByDate,
  handleExcelFileExport,
} from './AnalyticsData';
import { ticketsMapper } from './TicketsData';
const { RangePicker } = DatePicker;

type IAnalyticsDrawer = {
  drawerVisibility: boolean;
  setDrawerVisibility: (drawerVisibility: boolean) => void;
};

const AnalyticsDrawer: React.FC<IAnalyticsDrawer> = ({ drawerVisibility, setDrawerVisibility }) => {
  const [dateValue, setDateValue] = useState<any>();

  const { data, isLoading } = useDependantQuery(
    ['analytics-data', dateValue],
    getTicketsByDate,
    ticketsMapper,
    dateValue
  );

  const onValuesChange = (values: any) => {
    if (values.analyticsDate) {
      const startDate = values.analyticsDate[0].format('YYYY-MM-DD');
      const endDate = values.analyticsDate[1].format('YYYY-MM-DD');
      setDateValue({ startDate, endDate });
    }
  };
  const handleOnFinish = (values: any) => {
    const startDate = values.analyticsDate[0].format('DD.MM.YYYY');
    const endDate = values.analyticsDate[1].format('DD.MM.YYYY');
    if (values.analyticsType === 'statConsult')
      handleExcelFileExport(allMeetings(data), `Konsultacije ${startDate}-${endDate}.xlsx`);
    if (values.analyticsType === 'statConsultPerDoctor')
      handleExcelFileExport(
        calculateMeetingsByDoctor(data),
        `StatistikaDoktora ${startDate}-${endDate}.xlsx`
      );
    setDrawerVisibility(!drawerVisibility);
  };

  return (
    <Drawer
      title={
        <Title level={4} className='text-primary-text font-bold flex mb-0 justify-end'>
          Analitika podataka
        </Title>
      }
      placement='right'
      onClose={() => setDrawerVisibility(!drawerVisibility)}
      visible={drawerVisibility}
      width={window.innerWidth < 500 ? window.innerWidth : 500}
      destroyOnClose
    >
      <Form
        noValidate
        onFinish={handleOnFinish}
        className='flex flex-col items-center'
        layout='vertical'
        onValuesChange={onValuesChange}
      >
        <IconContext.Provider
          value={{
            className: 'mx-auto',
            size: '90',
            color: '#F259B3',
          }}
        >
          <MdAnalytics />
        </IconContext.Provider>
        <Divider orientation='center' className='my-4'>
          <Title level={4} className='text-center text-primary-text w-full font-bold'>
            Preuzimanje podataka
          </Title>
        </Divider>
        {analyticsForm.map((item: CEFormItem) => (
          <Form.Item
            name={item.name}
            className='my-2'
            rules={[{ required: item.required, message: item.message }]}
            label={item.text}
            key={item.name}
            tooltip={item.tooltip}
          >
            {createElement(item.type, {
              className: 'rounded-md w-[300px]',
              placeholder: item.placeholder,
              format: item.type === RangePicker ? 'DD. MM. YYYY.' : '',
              options: item.options,
              separator: item.separator,
            })}
          </Form.Item>
        ))}
        <Form.Item className='mt-10 mx-auto flex text-center'>
          <Button
            htmlType='submit'
            className='h-[40px] w-[200px] bg-btn-primary font-bold text-white rounded-md hover:opacity-80'
            type='primary'
            loading={isLoading}
          >
            Preuzmi
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AnalyticsDrawer;
