import { PoweroffOutlined, SolutionOutlined } from '@ant-design/icons';
import { Divider, Empty, Menu, Skeleton, Typography } from 'antd';
import ReactTimeAgo from 'react-time-ago';
import { useMutation, useQuery } from 'react-query';
import { API_AGENT } from '../../service/api_agent';
import { useEffect, useState } from 'react';
import CustomButton from '../../../components/CustomComponents/CustomButton/CustomButton';
import { useNavigate } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { MdArrowDownward } from 'react-icons/md';

interface INotification {
  id: number;
  title: string;
  content: string;
  seenTime: Date;
  createdTime: Date;
  type: string;
}

interface INotificationMenu {
  count: number | any;
  handleNotificationsCount: (data: number) => void;
  setNotificationsState: any;
}

export const UserMenu = (handleLogout: any) => {
  return (
    <Menu>
      <Menu.Item disabled>
        <SolutionOutlined className='icon m-2' />
        Uredi profil
      </Menu.Item>
      <Menu.Item onClick={() => handleLogout()}>
        <PoweroffOutlined className='icon m-2' />
        <span>Odjavi se</span>
      </Menu.Item>
    </Menu>
  );
};

export const NotificationMenu: React.VFC<INotificationMenu> = ({
  count,
  handleNotificationsCount,
  setNotificationsState,
}) => {
  const [data, setData] = useState<INotification[]>([]);
  const [size, setSize] = useState(30);

  const [mouseEntered, setMouseEntered] = useState(false);

  const redirect = useNavigate();

  const getAllNotifications = useQuery(
    'notifications',
    () => API_AGENT.getAllNotifications({ size }),
    {
      onSuccess: response => setData(response.content),
      cacheTime: 100,
    }
  );

  const editNotifications = useMutation('notification-edit', API_AGENT.patchNotifications, {
    onSuccess: data => {
      handleNotificationsCount(data.newNotificationCount);
      getAllNotifications.refetch();
    },
  });

  const readAllNotifications = useMutation(
    'read-all-notifications',
    API_AGENT.readAllNotifications,
    {
      onSuccess: data => {
        handleNotificationsCount(data.newNotificationCount);
        getAllNotifications.refetch();
      },
    }
  );

  const onNotificationEdit = (event: any, id: number) => {
    event.stopPropagation(); // prevent closing notifications !!
    editNotifications.mutate(id);
  };

  const onAllNotificationEdit = () => {
    readAllNotifications.mutate();
  };

  useEffect(() => {
    getAllNotifications.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size, count]);

  const onLoadMore = () => {
    setSize(size + 10);
  };

  if (getAllNotifications.isError && getAllNotifications.error instanceof Error) {
    return <h3>{getAllNotifications.error.message}</h3>;
  }

  const createLink = (item: any) => () => {
    setNotificationsState(false);
    if (item.objectId) return redirect(`/operator/tickets/${item.objectId}`);
    return redirect(`/operator/tickets`);
  };

  return (
    <Menu
      className={
        readAllNotifications.isLoading
          ? 'w-screen lg:w-[30vw] overflow-hidden drop-shadow-xl p-2 rounded-md opacity-[0.95] blur-sm'
          : 'w-screen lg:w-[30vw] overflow-hidden drop-shadow-xl p-2 rounded-md opacity-[0.95]'
      }
    >
      <div className='flex justify-between items-baseline h-1 mx-2 text-xs font-semibold sticky'>
        <Typography.Title level={4} className='text-primary-text font-bold'>
          Obaveštenja
        </Typography.Title>
        <span
          className='text-secondary-text active:contrast-50 cursor-pointer unselectable'
          onClick={() => onAllNotificationEdit()}
        >
          Označi sve kao pročitano
        </span>
      </div>
      <Divider />
      {getAllNotifications.isLoading ? (
        <Skeleton active paragraph={{ rows: 4 }} />
      ) : (
        <div className='max-h-[80vh] overflow-scroll'>
          {data?.map((item: INotification) => (
            <div
              className='bg-gray-100 m-2 p-3 h-full rounded hover:scale-[1.01] transition cursor-pointer hover:bg-gray-200 relative font-semibold text-primary-text opacity-100'
              onClick={createLink(item)}
            >
              <Typography.Title className='text-secondary-text font-bold' level={5}>
                {item.title}
              </Typography.Title>
              {!item.seenTime ? (
                <div
                  className='bg-btn-primary rounded-full w-[12px] h-[12px] absolute top-3 right-3'
                  onClick={e => onNotificationEdit(e, item.id)}
                />
              ) : (
                <ReactTimeAgo
                  className='text-xs font-normal absolute top-2 right-2'
                  date={item.createdTime}
                  locale='sr'
                  timeStyle='twitter'
                />
              )}
              <div className='text-xs font-normal my-2 mr-20'>{item.content}</div>
            </div>
          ))}
          {data.length !== 0 ? (
            data.length >= 10 ? (
              <CustomButton
                className='font-bold active:contrast-50 mx-auto border-none w-full'
                onClick={onLoadMore}
                onMouseEnter={() => setMouseEntered(true)}
                onMouseLeave={() => setMouseEntered(false)}
                ghost
                text='Učitaj još...'
                iconPosition='right'
                icon={
                  <IconContext.Provider
                    value={{
                      className: `mx-2 ${mouseEntered && 'bounceAlphaDown'}`,
                      size: '25',
                    }}
                  >
                    <MdArrowDownward />
                  </IconContext.Provider>
                }
              />
            ) : null
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Nema podataka' />
          )}
        </div>
      )}
    </Menu>
  );
};
