import { Divider, Skeleton, Typography } from 'antd';
import { Suspense, useEffect } from 'react';

const { Title } = Typography;

interface Props {
  questions: Array<any>;
}

const WebFormPreview: React.FC<Props> = ({ questions }: Props) => {
  useEffect(() => {
    console.log(questions);
  }, [questions]);

  return questions.length !== 0 ? (
    <>
      <Title className="ml-6 my-2 font-bold text-primary-text" level={3}>
        Odgovori na upitnik
      </Title>
      <Divider className="my-0" />
      <div className="mb-2 overflow-auto">
        {questions.map((item, index) => (
          <div
            key={index}
            className="ml-6 my-2 mx-10 p-3 rounded-md bg-gray-100 transition"
          >
            <Title className="font-bold text-secondary-text" level={5}>
              {item?.text}
            </Title>
            <Typography className="text-primary-text font-bold text-base">
              {typeof item?.answerFormatted === 'string' && (
                <span className="text-xl text-secondary-text mr-3">
                  &#8627;
                </span>
              )}
              {item?.answerFormatted}
            </Typography>
          </div>
        ))}
      </div>
    </>
  ) : (
    <Skeleton className="p-10 h-fit mb-5" active paragraph={{ rows: 20 }} />
  );
};

export default WebFormPreview;
