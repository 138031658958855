import { notification } from 'antd';
import { MdOutlineCloudDownload } from 'react-icons/md';

export interface ISnackbar {
  message: string;
  description?: string;
}

export interface ISnackbarMutation {
  onSuccessMessage: string;
  onErrorMessage: string;
}

export const SuccessSnackbar = (props: ISnackbar) => {
  notification.config({
    duration: 1.5,
  });

  notification['success']({
    message: props.message,
    description: props.description,
    placement: 'top',
  });
};
export const DownloadSnackbar = () => {
  notification.open({
    message: 'Preuzimanje fajla',
    description: 'Preuzimanje fajla je u toku.',
    icon: <MdOutlineCloudDownload className='text-primary-text' />,
    placement: 'top',
    duration: 2,
  });
};

export const ErrorSnackbar = (props: ISnackbar) => {
  notification.config({
    duration: 3,
  });

  notification['error']({
    message: props.message,
    description: props.description,
    placement: 'top',
  });
};
