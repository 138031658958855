import { FaUserCircle } from 'react-icons/fa';
import { Doctor, Pageable } from '../constants/operatorPages';
import { CEDoctorValues } from '../pages/Doctors/constants/doctorsData';

export const doctorsMapper = ({
  id,
  firstName,
  lastName,
  title,
  email,
  phoneNumber,
  enabled,
  room,
  medicineFields,
  scheduledMeetingsCount,
}: Doctor) => {
  let medicineFieldsList: string = '';
  const name: JSX.Element = (
    <span>
      <FaUserCircle className='inline-block mb-1 mr-2' />
      {title} {firstName} {lastName}
    </span>
  );
  if (typeof medicineFields !== 'string') {
    medicineFields.map(medField => (medField.value = medField?.name));
    medicineFieldsList = medicineFields?.map(medFIeld => medFIeld?.name).join();
  }

  if (typeof room !== 'string') {
    room = room?.name;
  }

  return {
    id,
    name,
    firstName,
    lastName,
    title,
    email,
    phoneNumber,
    room,
    medicineFields,
    medicineFieldsList,
    enabled,
    scheduledMeetingsCount,
    scheduledMeetings: scheduledMeetingsCount,
  };
};

export const postDoctorMapper = (
  { firstName, lastName, title, email, phoneNumber, medicineFields }: CEDoctorValues,
  enabled: boolean
) => {
  const medicineFieldIds = medicineFields?.map((medField: any) =>
    typeof medField === 'number' ? medField : medField?.id
  );
  return {
    firstName,
    lastName,
    title,
    email,
    phoneNumber,
    enabled,
    medicineFieldIds,
  };
};

export const getDoctors = (pageable: Pageable) => {
  return {
    method: 'get',
    url: 'agent/doctors',
    params: pageable,
    withCredentials: true,
  };
};

export const postDoctor = (data: CEDoctorValues) => {
  return {
    method: 'post',
    url: 'agent/doctors',
    data,
    withCredentials: true,
  };
};

export const editDoctor = ({ data, id }: any) => {
  return {
    method: 'put',
    url: `/agent/doctors/${id}`,
    data,
    withCredentials: true,
  };
};
