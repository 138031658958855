import { Form, Input } from 'antd';
import React, { ChangeEvent, createElement, useContext } from 'react';
import { OperatorContext } from '../../../../../contexts/OperatorState';
import { tempLoginCredentials, loginInputs } from '../constants/loginData';

interface ILoginContent {
  onKeyPress: () => void;
}

const LoginContent: React.FC<ILoginContent> = ({
  onKeyPress,
}: ILoginContent) => {
  const { loginCredentials, updateOperatorState } = useContext(OperatorContext);

  const handleLoginInput =
    (component: any, questionType: string) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      let tempValue: string = '';
      if (component === Input || component === Input.Password) {
        tempValue = event.target.value;
        (tempLoginCredentials as any)[questionType] = tempValue;
      }
      updateOperatorState(tempLoginCredentials, 'LOGIN_OPERATOR');
    };

  return (
    <Form
      className="flex flex-col items-center mx-auto mt-5 mb-12 max-w-[400px]"
      name="normal_login"
      initialValues={{ remember: true }}
      onKeyPress={(event) => {
        if (event.key === 'Enter') onKeyPress();
      }}
    >
      {loginInputs.map((item) => {
        if (item.type !== 'checkbox')
          return (
            <Form.Item className="w-full" {...item.props}>
              {createElement(item.component, {
                className: 'rounded-md text-primary-text',
                size: 'large',
                type: item.type,
                prefix: item.prefix,
                placeholder: item.name,
                iconRender: item.iconRender,
                onChange: handleLoginInput(item.component, item.type),
                value: loginCredentials?.[item.type],
              })}
            </Form.Item>
          );
        // return (
        //   <Form.Item>
        //     {/* <Link to=''>Zaboravljena lozinka</Link> */}
        //     <Card className="bg-slate-100">
        //       <p>Email: agent007 / admin</p>
        //       <p>Password: ASDasd123</p>
        //     </Card>
        //   </Form.Item>
        // );
      })}
    </Form>
  );
};

export default LoginContent;
