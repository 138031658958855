import { IRatingData } from '../constants/ratingInterfaces';

export const getIfHasRating = (token: string | null) => {
  return {
    method: 'get',
    url: `api/ratings/${token}`,
  };
};

export const postRating = (token: string | null, data: IRatingData) => {
  return {
    method: 'post',
    url: `api/ratings/${token}`,
    data,
  };
};

export const putRating = (token: string | null, data: IRatingData) => {
  return {
    method: 'put',
    url: `api/ratings/${token}`,
    data,
  };
};
