import { Dropdown, Menu, Pagination, Popconfirm, Popover, Select } from 'antd';
import React, { Suspense, useContext, useEffect, useMemo, useState } from 'react';
import { AiOutlineClockCircle, AiOutlineSchedule } from 'react-icons/ai';
import { IoMdCopy } from 'react-icons/io';
import { BiEdit } from 'react-icons/bi';
import { MdAnalytics, MdOutlineCancel, MdOutlineCheckCircleOutline } from 'react-icons/md';
import { useQuery } from 'react-query';
import CustomButton from 'src/components/CustomComponents/CustomButton/CustomButton';
import useMutateData from 'src/pages/Admin/hooks/useMutateData';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import Table from '../../../../components/Table/Table';
import { OperatorContext } from '../../../../contexts/OperatorState';
import { LoadingScreen } from '../../../../core/components/LoadingScreen';
import { API_AGENT } from '../../../service/api_agent';
import useFormLocal from '../../hooks/useFormLocal';
import CEConsultsDrawer from './components/CEConsultsDrawer';
import {
  copyToClipboard,
  getTickets,
  sortDirectionOptions,
  ticketsColumns,
  ticketsMapper,
  ticketStatus,
  ticketStatusIcon,
} from './components/TicketsData';
import { ITicketsValues } from './interfaces/TicketsInterfaces';
import { useNavigate } from 'react-router-dom';
import Filter from 'src/components/CustomComponents/CustomSelectMenus/Filter';
import AnalyticsDrawer from './components/AnalyticsDrawer';
import checkTokenRole from 'src/utils/checkTokenRole';

const Tickets: React.VFC = () => {
  const [consultVisibility, setConsultVisibility] = useState(false);
  const [analyticsVisibility, setAnalyticsVisibility] = useState(false);
  const [editId, setEditId] = useState<number | undefined>();
  const [tablePage, setTablePage] = useState(0);
  const [size, setSize] = useState(localStorage.getItem('page-size') ?? '10');
  const [sort, setSort] = useState('createdDate');
  const [consultType, setConsultType] = useState('');
  const [order, setOrder] = useState('desc');
  const [status, setStatus] = useState(
    localStorage.getItem('ticket-option') ?? 'STARTED,OPEN,SCHEDULED'
  );

  const { updateOperatorState } = useContext(OperatorContext);
  const columns = useMemo(() => ticketsColumns, []);
  const navigate = useNavigate();
  const isAdmin = checkTokenRole();

  const dropdownOptions = useQuery('dropdown-options', API_AGENT.getAllStatuses, {
    refetchOnWindowFocus: false,
  });

  const { data, isLoading, isError, error, refetch, isPreviousData, isFetched } = useFormLocal(
    'get-tickets',
    getTickets,
    ticketsMapper,
    {
      statuses: status,
      size,
      sort: `${sort},${order}`,
      page: tablePage,
    }
  );

  useEffect(() => {
    if (!isPreviousData || data?.hasMore) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, tablePage, size, order]);

  const handleDropdownValue = (value: string) => {
    localStorage.setItem('ticket-option', value);
    setStatus(localStorage.getItem('ticket-option')!);
    setTablePage(0);
  };

  const editTicketStatus = useMutateData(
    API_AGENT.editTicketStatus,
    {
      onSuccessMessage: 'Uspešno izmenjen status tiketa!',
      onErrorMessage: 'Greška! Status tiketa nije izmenjen!',
    },
    refetch
  );

  const handleSort = (column: any, sortDirection: any) => {
    if (column.hasSort) {
      columns.map((item: any) => item.accessor === column.id && setSort(item.sortName));
      sortDirectionOptions.map(item => item.previous === sortDirection && setOrder(item.next));
    }
  };

  const handleSize = () => (value: string) => {
    localStorage.setItem('page-size', value);
    setTablePage(0);
    setSize(localStorage.getItem('page-size')!);
  };

  const handlePagination = () => (page: number) => {
    setTablePage(page - 1);
  };

  const handleCancelTicketAction = (action: string, id: number) => {
    return action === 'Odbijen'
      ? editTicketStatus.mutate({
          id: id,
          status: { status: 'OPEN' },
        })
      : action === 'U toku'
      ? editTicketStatus.mutate({
          id: id,
          status: { status: 'FINISHED' },
        })
      : editTicketStatus.mutate({
          id: id,
          status: { status: 'REJECTED' },
        });
  };

  const nameToLinkMapper = () => {
    data?.map((ticket: any) => {
      ticket.status = (
        <CustomButton
          className={ticketStatus[ticket.statusName]}
          icon={ticketStatusIcon[ticket.statusName]}
          text={ticket.consultStatus}
          type='text'
        />
      );
      ticket.actions = (
        <div className='flex flex-row gap-1 cursor-pointer justify-end pr-8'>
          {ticket.patientLink &&
          ticket.doctorLink &&
          ticket.statusName !== 'Završen' &&
          ticket.statusName !== 'Odbijen' ? (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={() =>
                      copyToClipboard(ticket.patientLink, 'Uspešno kopiran link pacijenta!')
                    }
                    key='link-pacijenta'
                  >
                    Link pacijenta
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      copyToClipboard(ticket.doctorLink, 'Uspešno kopiran link lekara')
                    }
                    key='link-lekara'
                  >
                    Link lekara
                  </Menu.Item>
                </Menu>
              }
              placement='bottom'
            >
              <IoMdCopy className='w-6 h-6' />
            </Dropdown>
          ) : null}
          <Popover mouseEnterDelay={0.1} placement='top' content='Uredi tiket'>
            <BiEdit
              className='w-6 h-6'
              onClick={() => {
                tempSingleTicketHandler(ticket.id!);
                navigate(`${ticket.id}`);
              }}
            />
          </Popover>
          {ticket.statusName !== 'Završen' && ticket.statusName !== 'Odbijen' ? (
            <Popover mouseEnterDelay={0.1} placement='top' content='Uredi konsultaciju'>
              <AiOutlineSchedule
                className='w-6 h-6'
                onClick={() => handleCEDrawer('consult', ticket.id!)}
              />
            </Popover>
          ) : null}
          {ticket.statusName !== 'Završen' ? (
            <Popover
              mouseEnterDelay={0.1}
              placement='top'
              content={
                ticket.statusName === 'Odbijen'
                  ? 'Vrati tiket na čekanje'
                  : ticket.statusName === 'U toku'
                  ? 'Završi tiket'
                  : 'Odbij tiket'
              }
            >
              <Popconfirm
                title={
                  ticket.statusName === 'Odbijen'
                    ? 'Da li ste sigurni da želite da vratite tiket na čekanje?'
                    : ticket.statusName === 'U toku'
                    ? 'Da li ste sigurni da želite da završite tiket?'
                    : 'Da li ste sigurni da želite da odbijete tiket?'
                }
                onConfirm={() => handleCancelTicketAction(ticket.statusName, ticket.id)}
                okText='Da'
                cancelText='Ne'
                placement='topLeft'
              >
                {ticket.statusName === 'Odbijen' ? (
                  <AiOutlineClockCircle className='w-6 h-6' />
                ) : ticket.statusName === 'U toku' ? (
                  <MdOutlineCheckCircleOutline className='w-6 h-6' />
                ) : (
                  <MdOutlineCancel className='w-6 h-6' />
                )}
              </Popconfirm>
            </Popover>
          ) : null}
        </div>
      );
    });
  };

  const tempSingleTicketHandler = (id: number) => {
    const ticket = data?.find((ticket: { id: number }) => ticket?.id === id);
    updateOperatorState(ticket, 'CE_TICKET');
  };

  const handleCEDrawer = (type: string, id?: number) => {
    if (id) {
      setEditId(id);
      const ticket = data?.find((ticket: { id: number }) => ticket?.id === id);
      setConsultType(ticket.statusName);
      updateOperatorState(ticket, 'CE_TICKET');
      setConsultVisibility(!consultVisibility);
    }
    setConsultVisibility(!consultVisibility);
    if (consultVisibility === true) {
      setEditId(id);
      const temp: ITicketsValues = {
        id: null,
        name: '',
        firstName: '',
        lastName: '',
        doctorName: '',
        doctorId: null,
        doctor: '',
        email: '',
        phoneNumber: '',
        status: '',
        medicineField: '',
        comment: '',
        meeting: '',
        durationTime: '',
        consultDate: '',
        consultTime: '',
        createdDate: '',
        birthDate: null,
        gender: '',
      };
      updateOperatorState(temp, 'CE_TICKET');
    }
  };

  if (isLoading || dropdownOptions.isLoading) {
    return <LoadingScreen />;
  }

  if (isError || dropdownOptions.isError) {
    if (error instanceof Error) {
      return <h3>{error.message}</h3>;
    }
  }

  if (isFetched) {
    nameToLinkMapper();
  }

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingScreen />}>
        <div className='flex justify-between mb-6'>
          <Filter
            className='w-40'
            value={status}
            onChange={handleDropdownValue}
            options={dropdownOptions.data.TicketStatus}
          />

          {isAdmin ? (
            <CustomButton
              text='Preuzmi analitiku'
              className='h-9 font-bold bg-btn-primary hover:opacity-80'
              onClick={() => setAnalyticsVisibility(!analyticsVisibility)}
              icon={<MdAnalytics className='inline-block mb-1 mr-2' />}
            />
          ) : null}

          <div className='w-40' />
        </div>
        <CEConsultsDrawer
          handleCEDrawer={handleCEDrawer}
          drawerVisibility={consultVisibility}
          consultType={consultType}
          editId={editId}
          refetch={refetch}
        />
        <AnalyticsDrawer
          drawerVisibility={analyticsVisibility}
          setDrawerVisibility={setAnalyticsVisibility}
        />
        <Table columns={columns} data={data} handleSort={handleSort} sort={sort} order={order} />
        <div className='mt-2 flex justify-between'>
          <div />
          <Pagination
            className='flex'
            size='small'
            pageSize={1}
            current={tablePage + 1}
            defaultCurrent={1}
            total={Number(localStorage.getItem('pagination-size'))}
            onChange={handlePagination()}
            showSizeChanger={false}
          />
          <Select
            placeholder='Size'
            value={size}
            options={[{ value: 10 }, { value: 25 }, { value: 50 }]}
            onChange={handleSize()}
          />
        </div>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Tickets;
