import { Input, Select } from 'antd';

export const doctorColumns = [
  {
    Header: 'Status',
    accessor: 'status',
    // hasSort: false,
    sortName: 'status',
  },
  {
    Header: 'Ime',
    accessor: 'name',
    // hasSearch: true,
    hasSort: true,
    sortName: 'firstName',
  },
  {
    Header: 'Broj telefona',
    accessor: 'phoneNumber',
    // hasSearch: true,
    hasSort: true,
    sortName: 'phoneNumber',
  },
  {
    Header: 'E-mail',
    accessor: 'email',
    // hasSearch: true,
    hasSort: true,
    sortName: 'email',
  },
  {
    Header: 'Konsultacije',
    accessor: 'scheduledMeetingsCount',
    hasSort: true,
    sortName: 'scheduledMeetingsCount',
  },
  {
    Header: 'Oblasti medicine',
    accessor: 'medicineFieldsList',
    // hasSearch: true,
  },
  {
    Header: 'Čet soba',
    accessor: 'room',
    // hasSearch: true,
    sortName: 'room',
  },
  {
    Header: 'Akcije',
    accessor: 'actions',
  },
];

export const formCEDoctor: Array<CEFormItem> = [
  {
    id: 1,
    name: 'firstName',
    text: 'Ime',
    required: true,
    message: 'Molimo Vas unestite ime lekara!',
    type: Input,
  },
  {
    id: 2,
    name: 'lastName',
    text: 'Prezime',
    required: true,
    message: 'Molimo Vas unestite prezime lekara!',
    type: Input,
  },
  {
    id: 3,
    name: 'title',
    text: 'Titula',
    required: false,
    message: 'Molimo Vas unestite titulu!',
    type: Input,
  },
  {
    id: 4,
    name: 'email',
    text: 'Email',
    required: true,
    message: 'Molimo Vas unestite email adresu lekara!',
    type: Input,
  },
  {
    id: 5,
    name: 'phoneNumber',
    text: 'Broj telefona',
    required: true,
    message: 'Molimo Vas unestite broj telefona lekara!',
    type: Input,
  },
  {
    id: 6,
    name: 'medicineFields',
    text: 'Grane medicine',
    optionsHook: 'allMedFields',
    mode: 'multiple',
    required: true,
    message: 'Molimo Vas unestite grane medicine!',
    type: Select,
  },
];

export const doctorsFiltersOptions: Array<IDoctorsFilter> = [
  {
    name: '',
    value: 'Prikaži sve',
  },
  {
    name: 'true',
    value: 'Aktivni',
  },
  {
    name: 'false',
    value: 'Neaktivni',
  },
];

export interface DoctorColumn {
  Header: string;
  accessor: string;
  hasSearch?: boolean;
  hasSort?: boolean;
  sortName?: string;
}

export interface CEFormItem {
  id: number;
  name: string;
  text: string;
  required: boolean;
  message: string;
  type: any;
  component?: any;
  options?: Array<any>;
  optionsHook?: string;
  mode?: string;
  autoSize?: any;
  placeholder?: string | string[];
  tooltip?: string;
  separator?: string;
}

export interface CEDoctorValues {
  id?: number | null;
  firstName: string;
  lastName: string;
  title: string;
  name?: string | JSX.Element;
  email?: string;
  phoneNumber?: string;
  enabled?: boolean;
  medicineFieldIds?: Array<number>;
  medicineFields: Array<any>;
  medicineFieldsList?: string;
}

export interface IDoctorsFilter {
  name: '' | 'true' | 'false';
  value: string;
}
