import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useMutation } from 'react-query';
import { setupAxios } from '../../../axios/setupAxios';
import {
  ErrorSnackbar,
  ISnackbarMutation,
  SuccessSnackbar,
} from '../../../components/Snackbar/Snackbar';

const usePostCreate = (
  postFunction: Function,
  snackbarProperties: ISnackbarMutation,
  refetch?: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<any, unknown>>
) => {
  return useMutation((data: any) => setupAxios(postFunction(data)), {
    onSuccess: response => {
      refetch && refetch!();
      SuccessSnackbar({
        message: snackbarProperties.onSuccessMessage,
      });
    },
    onError: (error: any) => {
      ErrorSnackbar({
        message: snackbarProperties.onErrorMessage,
        description: error.response.data.message,
      });
    },
  });
};

export default usePostCreate;
