import { useCallback, useEffect, useRef, useState } from 'react';
import {
  useAudioTrack,
  useDailyEvent,
  useLocalParticipant,
  useParticipantIds,
  useScreenShare,
  useVideoTrack,
} from '@daily-co/daily-react-hooks';

import Tile from './Tile';
import UserMediaError from './UserMediaError';
import { IconContext } from 'react-icons';
import { IoPersonCircleOutline } from 'react-icons/io5';
import { BiMicrophoneOff } from 'react-icons/bi';
import useWindowSize, { windowSizeType } from 'src/hooks/useWindowSize';
import CountdownTImer from './CountdownTImer';

interface Props {
  toggleFocus: () => void;
  focusMe: boolean;
  endTime: string;
}

const Call: React.FC<Props> = ({ toggleFocus, focusMe, endTime }: Props) => {
  const windowSize = useWindowSize();

  const [getUserMediaError, setGetUserMediaError] = useState(false);

  useDailyEvent(
    'camera-error',
    useCallback((ev) => {
      setGetUserMediaError(true);
    }, [])
  );

  const localParticipant = useLocalParticipant();
  const localParticipantVideoTrack = useVideoTrack(
    localParticipant?.session_id ?? ''
  );
  const localAudio = useAudioTrack(localParticipant?.session_id ?? '');

  const localVideoElement = useRef<any>(null);

  const mutedVideo = localParticipantVideoTrack.isOff;
  const mutedAudio = localAudio.isOff;

  useEffect(() => {
    if (!localParticipantVideoTrack.persistentTrack) return;
    localVideoElement?.current &&
      (localVideoElement.current.srcObject =
        localParticipantVideoTrack.persistentTrack &&
        new MediaStream([localParticipantVideoTrack?.persistentTrack]));
  }, [localParticipantVideoTrack.persistentTrack, mutedVideo]);

  const { screens } = useScreenShare();
  const remoteParticipantIds = useParticipantIds({ filter: 'remote' });

  const callClassName: Record<windowSizeType, string> = {
    'wide-desktop': 'mx-10 w-[calc(100%-624px)]',
    desktop: 'mx-2 w-[calc(100%-467px)]',
    tablet: 'mx-2 w-screen',
    mobile: 'mx-2 w-screen',
    none: 'mx-2 w-[calc(100%-467px)]',
  };

  const tileClassName: Record<windowSizeType, string> = {
    'wide-desktop': 'w-[460px] h-[270px]',
    desktop: 'w-[375px] h-[220px]',
    tablet: 'w-[200px] h-[117px]',
    mobile: 'w-[200px] h-[117px]',
    none: 'w-[200px] h-[117px]',
  };

  const localClassName: Record<windowSizeType, string> = {
    'wide-desktop': '',
    desktop: '',
    tablet: '',
    mobile: '',
    none: '',
  };

  const renderCallScreen = () => {
    return (
      <div
        className={`flex items-center justify-center bg-secondary-bg rounded-md relative transiton ${callClassName[windowSize]}`}
      >
        <div>
          {/* className={`${screens.length > 0 ? 'relative' : 'relative'}`} */}
          {localParticipant && (
            <div
              className={`self-view transition ${
                remoteParticipantIds?.length > 0 || screens?.length > 0
                  ? focusMe
                    ? 'h-[calc(100vh-208px)] w-full flex items-center text-white ' +
                      localClassName[windowSize]
                    : 'absolute left-3 top-3 opacity-90 hover:opacity-100 transition rounded-md z-10 flex justify-center items-center text-white bg-secondary-bg border ' +
                      tileClassName[windowSize]
                  : 'h-[calc(100vh-208px)] w-full flex items-center text-white ' +
                    localClassName[windowSize]
              }`}
            >
              {remoteParticipantIds?.length === 0 && (
                <CountdownTImer endTime={endTime} />
              )}
              {mutedAudio && (
                <IconContext.Provider
                  value={{
                    className:
                      'absolute bottom-2 left-2 w-8 h-8 z-10 fill-red-700',
                  }}
                >
                  <BiMicrophoneOff />
                </IconContext.Provider>
              )}
              {mutedVideo ? (
                // <div
                //   className={`flex justify-center items-center bg-secondary-bg text-white ${
                //     remoteParticipantIds?.length > 0 || screens?.length > 0
                //       ? !focusMe
                //         ? 'absolute rounded-md opacity-90 hover:opacity-100 transition ' +
                //           tileClassName[windowSize]
                //         : 'h-[calc(100vh-208px)] w-[calc(100vw-624px)] rounded-md'
                //       : 'h-[calc(100vh-208px)] w-[calc(100vw-624px)] rounded-md'
                //   }`}
                // >
                <IconContext.Provider
                  value={{
                    size: '80',
                  }}
                >
                  <IoPersonCircleOutline />
                </IconContext.Provider>
              ) : (
                // </div>
                <>
                  <video
                    className="h-full w-fit mx-auto"
                    autoPlay
                    muted
                    playsInline
                    ref={localVideoElement}
                  />
                </>
              )}
            </div>
          )}
          {remoteParticipantIds?.length > 0 || screens?.length > 0 ? (
            <>
              {remoteParticipantIds.map((id) => (
                <Tile key={id} id={id} focusMe={focusMe} endTime={endTime} />
              ))}
              {screens.map((screen) => (
                <Tile
                  key={screen.screenId}
                  id={screen.session_id}
                  focusMe={focusMe}
                  endTime={endTime}
                  isScreenShare
                />
              ))}
            </>
          ) : (
            <div>
              {/* <Title className="text-white" level={4}>
                Molimo sačekajte sagovornika da pristupi aplikaciji
              </Title> */}
              {/* <p>Invite someone by sharing this link:</p>
              <span className="room-url">{window.location.href}</span> */}
            </div>
          )}
        </div>
      </div>
    );
  };

  return <>{getUserMediaError ? <UserMediaError /> : renderCallScreen()}</>;
};

export default Call;
