import { useQuery } from 'react-query';
import { setupAxios } from '../axios/setupAxios';

const useDependantQuery = (key: any, getFunction: Function, mapper: Function, params: any) => {
  return useQuery(
    key,
    () =>
      setupAxios(getFunction(params)).then(response => {
        if (response.data.content) return response.data.content.map((item: any) => mapper(item));
        return response.data.map((item: any) => mapper(item));
      }),
    {
      enabled: !!params,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      cacheTime: 4543534543543,
    }
  );
};

export default useDependantQuery;
