import { createContext, useReducer } from 'react';
import { AdminReducer } from '../reducers/AdminReducer';

const initialState: any = {
  userCEForm: {
    firstName: '',
    lastName: '',
    email: '',
    role: '',
  },
};

export const AdminContext = createContext(initialState);

export const AdminStateProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(AdminReducer, initialState);

  const updateAdminState = (payload: any, type?: string) => {
    dispatch({
      type,
      payload,
    });
  };

  return (
    <AdminContext.Provider
      value={{
        userCEForm: state.userCEForm,
        updateAdminState,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};
