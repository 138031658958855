import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { RatingStateProvider } from 'src/contexts/RatingState';
import { LoadingScreen } from 'src/core/components/LoadingScreen';

type Props = {};

const Rating = (props: Props) => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingScreen />}>
        <RatingStateProvider>
          <Outlet />
        </RatingStateProvider>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Rating;
