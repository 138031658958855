import { useQuery } from 'react-query';
import { setupAxios } from '../../../axios/setupAxios';

const useFormLocal = (key: string, getFunction: Function, mapper: Function, params?: any) => {
  return useQuery(
    key,
    () =>
      setupAxios(getFunction(params)).then(response => {
        localStorage.setItem(
          'pagination-size',
          response.data.totalPages ? response.data.totalPages : 1
        );
        if (response.data.content) return response.data?.content?.map((item: any) => mapper(item));
        return response.data?.map((item: any) => mapper(item));
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
};

export default useFormLocal;
