import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import OnlineMeeting from '../pages/OnlineMeetingWrapper/components/OnlineMeeting/OnlineMeeting';
import OnlineMeetingWrapper from '../pages/OnlineMeetingWrapper/OnlineMeetingWrapper';
import Users from '../pages/Admin/pages/Users';
import Doctors from '../pages/Operator/pages/Doctors/Doctors';
import Login from '../pages/Operator/pages/Login/Login';
import Tickets from '../pages/Operator/pages/Tickets/Tickets';
import PatientWebForm from '../pages/PatientWebForm/PatientWebForm';
import Admin from '../pages/Admin/Admin';
import Rating from 'src/pages/Rating/Rating';
import RatingStep from 'src/pages/Rating/components/RatingStep/RatingStep';
import Ticket from 'src/pages/Operator/pages/Ticket/Ticket';
import PatientInfo from 'src/pages/PatientInfo/PatientInfo';

const Step = lazy(() => import('../pages/PatientWebForm/components/Step1/Step1'));

const Operator = lazy(() => import('../pages/Operator/Operator'));

export const routes = (isAuth: boolean, isAdmin: boolean) => [
  {
    path: '/',
    element: <PatientWebForm />,
    children: [{ index: true, element: <Step />, path: 'step' }],
  },
  {
    path: 'operator',
    element: <Operator />,
    children: [
      {
        index: true,
        element: !isAuth ? <Login /> : <Navigate to='/operator/tickets' />,
        path: 'login',
      },
      {
        index: true,
        element: isAuth ? <Doctors /> : <Navigate to='/operator/login' />,
        path: 'doctors',
      },
      {
        index: true,
        element: isAuth ? <Tickets /> : <Navigate to='/operator/login' />,
        path: 'tickets',
      },
      {
        index: true,
        element: isAuth ? <Ticket /> : <Navigate to='/operator/login' />,
        path: 'tickets/:id',
      },
    ],
  },
  {
    path: 'online-meeting',
    element: <OnlineMeetingWrapper />,
    children: [{ element: <OnlineMeeting />, path: ':token' }],
  },
  {
    path: 'rating',
    element: <Rating />,
    children: [{ element: <RatingStep />, path: ':token' }],
  },
  {
    path: 'patient-info/:token',
    element: <PatientInfo />,
  },
  {
    path: '/admin',
    element: isAdmin && isAuth ? <Admin /> : <Navigate to='/operator/login' />,
    children: [{ index: true, element: <Users />, path: 'users' }],
  },
  // { path: '/login', element: !isAuth ? <Login /> : <Navigate to='/' /> },
  { path: '*', element: <Navigate to='/' /> },
];
