import { useState, useEffect } from 'react';

export type windowSizeType =
  | 'desktop'
  | 'wide-desktop'
  | 'tablet'
  | 'mobile'
  | 'none';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<windowSizeType>('none');

  const determineWindowSize = (width: null | number, height: null | number) => {
    if (width) {
      if (width > 1440) {
        setWindowSize('wide-desktop');
      }
      if (width > 970 && width < 1440) {
        setWindowSize('desktop');
      }
      if (width > 500 && width < 970) {
        setWindowSize('tablet');
      }
      if (width < 500) {
        setWindowSize('mobile');
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      determineWindowSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
};

export default useWindowSize;
