import axios from 'axios';

async function createRoom() {
  const URL_ROOT = `${process.env.REACT_APP_BASE_URL}${
    process.env.REACT_APP_BASE_PORT ? ':' + process.env.REACT_APP_BASE_PORT : ''
  }`;
  const exp = Math.round(Date.now() / 1000) + 60 * 30;
  const options = {
    properties: {
      exp,
      enable_prejoin_ui: false, // TODO: remove me when robots are no longer needed
    },
  };

  const token = window.location.pathname.split('/')[2];
  localStorage.setItem('be_auth_token', token);

  const response = await axios(`${URL_ROOT}/api/meetings/validate/${token}`, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    method: 'get',
    data: JSON.stringify(options),
  });

  return await response.data;
}

export default { createRoom };
