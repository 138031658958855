import { Input, Modal, Rate, Typography } from 'antd';
import { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { setupAxios } from 'src/axios/setupAxios';
import CustomButton from 'src/components/CustomComponents/CustomButton/CustomButton';
import { OnlineMeetingContext } from 'src/contexts/OnlineMeetingState';
import { IRatingData } from 'src/pages/Rating/constants/ratingInterfaces';
import { putRating } from 'src/pages/Rating/helpers/ratingHelpers';

type Props = { ratingModal: boolean; handleRatingModal: () => void };

let localRatingData: IRatingData = {
  grade: 0,
  comment: '',
};

const RatingModal = ({ ratingModal, handleRatingModal }: Props) => {
  const [stars, setStars] = useState<number>(0);
  const [step, setStep] = useState<1 | 2>(1);

  const { ratingData, updateOnlineMeetingState } =
    useContext(OnlineMeetingContext);

  const token: string | null = localStorage.getItem('be_auth_token');

  const description = [
    'Veoma nezadovoljan/na',
    'Nezadovoljan/na',
    'Neutralan/na',
    'Zadovoljan/na',
    'Veoma zadovoljan/na',
  ];

  const mutateRating = useMutation(
    'mutate-rating',
    () => setupAxios(putRating(token, ratingData)),
    { onSuccess: () => handleRatingModal() }
  );

  const handleChangeRating = (item: string) => (event: number | any) => {
    let tempValue;
    if (item === 'grade') {
      tempValue = event;
      localRatingData.grade = tempValue;
    } else {
      tempValue = event.target.value;
      localRatingData.comment = tempValue;
    }
    updateOnlineMeetingState(localRatingData, 'RATE_MEETING');
  };

  const handleNextStep = () => {
    setStep(2);
  };

  return (
    <Modal
      getContainer={false}
      className="min-w-[300px] mx-auto"
      visible={ratingModal}
      onCancel={handleRatingModal}
      mask={false}
      closable={true}
      centered={true}
      title={
        <Typography.Title className="text-primary-text" level={5}>
          Ocenite svoje zadovoljstvo online konsultacijom
        </Typography.Title>
      }
      footer={
        <div className="my-2 text-center">
          <CustomButton
            text="Potvrdite"
            className="bg-btn-primary font-bold text-base text-white h-[40px] w-[200px]"
            onClick={mutateRating.mutate}
          />
        </div>
      }
    >
      {step === 1 ? (
        <div className="flex justify-center items-center flex-col">
          <Rate
            tooltips={description}
            onChange={handleChangeRating('grade')}
            value={ratingData?.grade}
          />
          {ratingData?.grade ? (
            <span className="ant-rate-text">
              {description[ratingData?.grade - 1]}
            </span>
          ) : (
            ''
          )}
          <Input.TextArea
            size="large"
            className="my-5"
            placeholder="Ovde napišite komentar..."
            value={ratingData?.comment}
            onChange={handleChangeRating('comment')}
          />
        </div>
      ) : (
        <Typography.Title className="text-primary-text" level={4}>
          Hvala Vam što ste popunili anketu!
        </Typography.Title>
      )}
    </Modal>
  );
};

export default RatingModal;
