import { IUser } from '../pages/Admin/constants/usersInterfaces';

const AdminReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'CE_USER':
      const updatedUser: IUser = action.payload;
      return { ...state, userCEForm: updatedUser };
    default:
      return state;
  }
};

export { AdminReducer };
