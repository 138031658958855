import { Button, DatePicker, Divider, Form, Rate, Typography } from "antd";
import locale from "antd/es/date-picker/locale/sr_RS";
import moment from "moment";
import { createElement, useState, useEffect } from "react";
import {
  AiOutlineCalendar,
  AiOutlineIdcard,
  AiOutlineMedicineBox,
} from "react-icons/ai";
import { IconContext } from "react-icons";
import { RiChatOffFill, RiFileDownloadFill } from "react-icons/ri";
import { UseMutationResult, UseQueryResult } from "react-query";
import { CEFormItem } from "../../Doctors/constants/doctorsData";
import {
  formCETickets,
  ticketsScheduleForm,
} from "../../Tickets/components/TicketsData";
import {
  ITicketsConsult,
  ITicketsValues,
} from "../../Tickets/interfaces/TicketsInterfaces";
import { downloadFile, questionsInitialValue } from "../TicketHelper";
import { MdDeleteOutline, MdOutlineCloudDownload } from "react-icons/md";

interface IBasicInfo {
  onFormFinish: (values: ITicketsValues) => void;
  data: any;
  allDoctors: any;
  allMedFields: UseQueryResult;
  editTicketMutation: UseMutationResult<unknown, any, any, unknown>;
  setChosenMedField: any;
}

interface IMedicalQuestions {
  data: any;
  onAnswersFinish: (values: any) => void;
  onFilesChange: (value: any) => Promise<void>;
  editAnswersMutation: UseMutationResult<unknown, any, any, unknown>;
}

interface IConsultSchedule {
  data: any;
  onConsultFinish: (values: ITicketsConsult) => void;
  editConsultMutation: UseMutationResult<unknown, any, any, unknown>;
}

export const BasicQuestions = ({
  onFormFinish,
  data,
  allMedFields,
  allDoctors,
  editTicketMutation,
  setChosenMedField,
}: IBasicInfo) => {
  const [prefilledFields, setPrefilledFields] = useState<any>();
  const onFieldsChange = (changedFields: any, allFields: any) => {
    if (changedFields[0].name[0] === "medicineFieldId") {
      const mappedFields = allFields.map((field: any) =>
        field.name[0] === "doctorId"
          ? {
              ...field,
              value: null,
              errors: ["Unesite lekara za odabranu granu medicine!"],
            }
          : field
      );
      setPrefilledFields(mappedFields);
      setChosenMedField(changedFields[0].value);
    }
  };

  useEffect(() => {
    if (
      data.ticket &&
      allDoctors.data &&
      (data.ticket.statusName === "Na čekanju" ||
        data.ticket.statusName === "U toku" ||
        data.ticket.statusName === "Zakazan")
    ) {
      const findDoctor = allDoctors.data.find(
        (doctor: any) => doctor.id === data.ticket.doctorId
      );
      if (!findDoctor) {
        setPrefilledFields([
          {
            name: ["doctorId"],
            value: null,
            errors: ["Unesite lekara za odabranu granu medicine!"],
          },
        ]);
      }
    }
  }, [allDoctors.data]);

  return (
    <Form
      noValidate
      onFinish={(values) => onFormFinish(values)}
      layout={window.innerWidth > 500 ? "vertical" : "inline"}
      className="flex flex-col w-full overflow-auto "
      fields={prefilledFields}
      scrollToFirstError={true}
      onFieldsChange={onFieldsChange}
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 text-primary-text">
        {formCETickets.map((item: CEFormItem) => (
          <Form.Item
            className="my-2 mx-auto text-primary-text w-[16vw]"
            name={item.name}
            rules={[{ required: item.required, message: item.message }]}
            initialValue={data.ticket[item?.name]}
            label={item.text}
            key={item.name}
          >
            {createElement(item.type, {
              className: "rounded-md w-full my-2 text-primary-text",
              placeholder: item.text,
              options:
                item.name === "medicineFieldId"
                  ? allMedFields.data
                  : item.name === "doctorId"
                  ? allDoctors.data
                  : item.options,
              format: item.type === DatePicker ? "DD. MM. YYYY." : "HH:mm",
              disabled:
                ((data.ticket.statusName === "Završen" ||
                  data.ticket.statusName === "Odbijen") &&
                  item.name !== "comment") ||
                data.ticket.statusName === "U toku"
                  ? true
                  : false,
              locale,
            })}
          </Form.Item>
        ))}
      </div>
      <Form.Item key="submit" className="mt-10 flex text-center">
        <Button
          htmlType="submit"
          className="h-[40px] bg-btn-primary font-bold text-white rounded-md hover:opacity-80"
          type="primary"
          loading={editTicketMutation.isLoading}
          disabled={data.ticket.statusName === "U toku" ? true : false}
        >
          Sačuvaj osnovne informacije
        </Button>
      </Form.Item>
    </Form>
  );
};

export const MedicalQuestions = ({
  data,
  onAnswersFinish,
  onFilesChange,
  editAnswersMutation,
}: IMedicalQuestions) => {
  return (
    <Form
      noValidate
      onFinish={(values) => onAnswersFinish(values)}
      layout={window.innerWidth > 500 ? "vertical" : "inline"}
      className="flex flex-col w-full"
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
        {data.questions.map((item: any) => (
          <Form.Item
            className="my-2 mx-auto text-primary-text w-[16vw]"
            name={item.questionId}
            rules={[{ required: item.required, message: "" }]}
            label={item.text}
            initialValue={questionsInitialValue(item.type, item)}
            key={item.questionId}
          >
            {createElement(item.component, {
              className: "rounded-md w-full text-primary-text",
              placeholder: item.text,
              options: item?.options,
              mode: item.type === "MULTIPLE_CHOICE" ? "multiple" : undefined,
              format: "DD. MM. YYYY.",
              defaultFileList:
                item.type === "FILE_UPLOAD" ? item.answer.files : null,
              onDownload: (file: any) =>
                downloadFile(file.downloadUrl, file.name),
              showUploadList: {
                showDownloadIcon: true,
                downloadIcon: (
                  <MdOutlineCloudDownload className="w-5 h-5 text-primary-text" />
                ),
                removeIcon: (
                  <MdDeleteOutline className="w-5 h-5 text-primary-text" />
                ),
              },
              disabled:
                data.ticket.statusName === "Završen" ||
                data.ticket.statusName === "Odbijen" ||
                data.ticket.statusName === "U toku"
                  ? true
                  : false,
              onChange: item.type === "FILE_UPLOAD" ? onFilesChange : null,
              locale,
            })}
          </Form.Item>
        ))}
      </div>

      <Form.Item key="submit" className="mt-10 flex text-center">
        <Button
          htmlType="submit"
          className="h-[40px] bg-btn-primary font-bold text-white rounded-md hover:opacity-80"
          type="primary"
          loading={editAnswersMutation.isLoading}
          disabled={
            data.ticket.statusName === "Završen" ||
            data.ticket.statusName === "Odbijen" ||
            data.ticket.statusName === "U toku"
              ? true
              : false
          }
        >
          Sačuvaj informacije o bolesti
        </Button>
      </Form.Item>
    </Form>
  );
};

export const ConsultSchedule = ({
  onConsultFinish,
  data,
  editConsultMutation,
}: IConsultSchedule) => {
  return (
    <Form
      noValidate
      onFinish={(values: ITicketsConsult) => onConsultFinish(values)}
      layout="vertical"
      className="flex flex-col w-full"
    >
      {ticketsScheduleForm.map((item: CEFormItem) => (
        <Form.Item
          name={item.name}
          className="my-2 mx-auto"
          rules={[{ required: item.required, message: item.message }]}
          initialValue={data.ticket[item?.name]}
          label={item.text}
          key={item.name}
        >
          {createElement(item.type, {
            className: "rounded-md w-[16vw] text-primary-text",
            placeholder: item.text,
            options: item.options,
            prefix: item.name === "durationTime" ? "min" : null,
            ...(item.name === "durationTime" && {
              step: 5,
              min: 10,
              max: 90,
            }),
            format: item.type === DatePicker ? "DD. MM. YYYY." : "HH:mm",
            disabled:
              data.ticket.statusName === "Završen" ||
              data.ticket.statusName === "Odbijen" ||
              (data.ticket.statusName === "U toku" &&
                item.name !== "durationTime")
                ? true
                : false,
            locale,
          })}
        </Form.Item>
      ))}
      <Form.Item key="submit" className="mt-10 flex text-center">
        <Button
          htmlType="submit"
          className="h-[40px] w-[200px] bg-btn-primary font-bold text-white rounded-md hover:opacity-80"
          type="primary"
          loading={editConsultMutation.isLoading}
          disabled={
            data.ticket.statusName === "Završen" ||
            data.ticket.statusName === "Odbijen"
              ? true
              : false
          }
        >
          Zakaži
        </Button>
      </Form.Item>
    </Form>
  );
};

export const operatorTabs = [
  {
    key: 1,
    name: "BasicQuestions",
    icon: <AiOutlineIdcard />,
    tabTitle: "Osnovne informacije",
    title: "Osnovne informacije tiketa",
  },
  {
    key: 2,
    name: "MedicalQuestions",
    icon: <AiOutlineMedicineBox />,
    tabTitle: "Informacije o bolesti",
    title: "Informacije o bolesti pacijenta",
  },
  {
    key: 3,
    name: "ConsultSchedule",
    icon: <AiOutlineCalendar />,
    tabTitle: "Termin konsultacije",
    title: "Termin konsultacije",
  },
];

export const ChatInfo = ({ messages, doctor, ticket }: any) => {
  const description = [
    "Veoma nezadovoljan/na",
    "Nezadovoljan/na",
    "Neutralan/na",
    "Zadovoljan/na",
    "Veoma zadovoljan/na",
  ];
  return (
    <>
      <div
        className={
          ticket.patientRating === null && ticket.doctorRating === null
            ? "overflow-auto h-[70%]"
            : "overflow-auto h-[62%]"
        }
      >
        {messages?.length !== 0 ? (
          messages?.map((message: any, index: number) => (
            <div key={index}>
              {message?.type === "CHAT" || message?.type === "FILE" ? (
                <div key={index}>
                  {message?.sentTime && index === 0 ? (
                    <Typography className="my-2 text-primary-text text-center">
                      {moment(message?.sentTime).format("HH:mm")}
                    </Typography>
                  ) : moment(message?.sentTime).format("HH:mm") !==
                    moment(messages[index - 1].sentTime).format("HH:mm") ? (
                    <Typography className="my-2 text-primary-text text-center">
                      {moment(message?.sentTime).format("HH:mm")}
                    </Typography>
                  ) : (
                    ""
                  )}
                  <Typography
                    className={`mx-4 font-bold text-primary-text ${
                      message?.senderName === doctor ? "text-right" : ""
                    }`}
                  >
                    {message?.senderName}
                  </Typography>
                  <div
                    key={index}
                    className={`mb-6 py-2 px-4 rounded-xl mx-4 max-w-[300px] w-fit ${
                      message?.senderName === doctor
                        ? "bg-secondary-bg rounded-br-none ml-[auto]"
                        : "bg-primary-bg rounded-tl-none"
                    }`}
                  >
                    <Typography className="text-white">
                      {message?.text}
                    </Typography>
                    <div key={index} className="flex flex-wrap">
                      {message?.files?.length !== 0 &&
                        message?.files?.map((file: any) => (
                          <a
                            className="pointer hover:bg-gray-100 transition flex items-center break-all w-fit bg-gray-200 rounded-md px-2 m-1"
                            onClick={() => downloadFile(file?.url, file?.name)}
                          >
                            <IconContext.Provider
                              value={{
                                className: "m-2 ml-0 h-5 w-5",
                                color: "#f14dae",
                              }}
                            >
                              <RiFileDownloadFill />
                              <Typography className="text-primary-text mr-2">
                                {file?.name}
                              </Typography>
                            </IconContext.Provider>
                          </a>
                        ))}
                    </div>
                  </div>
                </div>
              ) : (
                <Typography className="my-2 text-primary-text text-center">
                  {message?.sentTime && index === 0 ? (
                    <Typography className="my-2 text-primary-text text-center">
                      {moment(message?.sentTime).format("HH:mm")}
                    </Typography>
                  ) : moment(message?.sentTime).format("HH:mm") !==
                    moment(messages[index - 1].sentTime).format("HH:mm") ? (
                    <Typography className="my-2 text-primary-text text-center">
                      {moment(message?.sentTime).format("HH:mm")}
                    </Typography>
                  ) : (
                    ""
                  )}
                  {message?.text}
                </Typography>
              )}
            </div>
          ))
        ) : (
          <IconContext.Provider
            value={{
              className: "m-auto w-full mt-[50%] opacity-60",
              size: "80",
              color: "#F259B3",
            }}
          >
            <RiChatOffFill />
          </IconContext.Provider>
        )}
      </div>
      {ticket.patientRating === null && ticket.doctorRating === null ? null : (
        <>
          <Divider className="my-3" />
          <div className="flex justify-between h-[21%]">
            <div className="w-1/2 flex items-center flex-col pr-1">
              <div className="text-primary-text font-bold mb-1">Lekar</div>
              {ticket.doctorRating !== null ? (
                <>
                  <Rate
                    tooltips={description}
                    value={ticket.doctorRating.grade}
                    disabled
                  />
                  <Typography.Text
                    className="h-[12vh] text-center overflow-auto w-full mt-2"
                    italic
                  >
                    {ticket.doctorRating.comment &&
                      `„${ticket.doctorRating.comment}“`}
                  </Typography.Text>
                </>
              ) : (
                <Typography.Text className="h-[12vh] overflow-auto" italic>
                  Lekar nije dao ocenu!
                </Typography.Text>
              )}
            </div>
            <Divider className="h-full" type="vertical" />
            <div className="w-1/2 items-center flex flex-col pl-1">
              <div className="text-primary-text font-bold mb-1">Pacijent</div>

              {ticket.patientRating !== null ? (
                <>
                  <Rate
                    tooltips={description}
                    value={ticket.patientRating.grade}
                    disabled
                  />
                  <Typography.Text
                    className="w-full text-center h-[12vh] overflow-auto mt-2"
                    italic
                  >
                    {ticket.patientRating.comment &&
                      `„${ticket.patientRating.comment}“`}
                  </Typography.Text>
                </>
              ) : (
                <Typography.Text className="h-[12vh] overflow-auto" italic>
                  Pacijent nije dao ocenu!
                </Typography.Text>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
