import { useEffect, useState } from 'react';

export const useCountdown = (secondsLeft: number) => {
  let time: number = secondsLeft;
  const [countDown, setCountDown] = useState('');
  useEffect(() => {
    const interval = setInterval(() => {
      time = time - 1;
      let date: any = new Date(0);
      date.setSeconds(time);
      let timeString = date.toISOString().substr(11, 8);
      setCountDown(timeString);
    }, 1000);

    return () => clearInterval(interval);
  }, [time]);

  return countDown;
};
