import React, { Suspense } from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { useRoutes } from 'react-router-dom';
import { routes } from './constants/routes';
import { LoadingScreen } from './core/components/LoadingScreen';
import { I18nProvider } from './i18n/i18nProvider';
// import 'antd/dist/antd.css';
// import 'antd/dist/antd.min.css';
import 'antd/dist/antd.variable.min.css';
import { WebFormStateProvider } from './contexts/WebFormState';
import useAuth from './hooks/useAuth';
import { RecoilRoot } from 'recoil';
import checkTokenRole from './utils/checkTokenRole';

import TimeAgo from 'javascript-time-ago';
import sr from 'javascript-time-ago/locale/sr-Latn.json';
import { ConfigProvider } from 'antd';

TimeAgo.addDefaultLocale(sr);
TimeAgo.addLocale(sr);

export const queryCache = new QueryCache();
const queryClient = new QueryClient({
  queryCache: queryCache,
});

const App: React.FC = () => {
  const isAuth = useAuth();
  const isAdmin = checkTokenRole();

  let element = useRoutes(routes(isAuth, isAdmin));

  ConfigProvider.config({
    theme: {
      primaryColor: '#f14dae',
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<LoadingScreen />}>
        <WebFormStateProvider>
          <RecoilRoot>
            <ConfigProvider>
              <I18nProvider>{element}</I18nProvider>
            </ConfigProvider>
          </RecoilRoot>
        </WebFormStateProvider>
      </Suspense>
    </QueryClientProvider>
  );
};

export { App };
