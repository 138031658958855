import { Typography } from 'antd';
import CustomButton from 'src/components/CustomComponents/CustomButton/CustomButton';
import Logo from '../../../../../../../assets/logo.png';
const { Title } = Typography;

interface Props {
  startCall: () => void;
}

export const TitleComponent = () => {
  return (
    <>
      <div className="my-2">
        <img src={Logo} className="w-[60px] mx-auto" />
        <Title level={5} className="text-center my-2 text-primary-text">
          Dobrodošli na{' '}
          <span className="text-secondary-text">Telemedicinu</span>, aplikaciju
          za online lekarske preglede i konsultacije.
        </Title>
      </div>
    </>
  );
};

export const FooterComponent: React.FC<Props> = ({ startCall }: Props) => {
  return (
    <div className="my-2 text-center">
      <CustomButton
        text="Otpočnite konsultaciju"
        className="bg-btn-primary font-bold text-base text-white h-[40px]"
        onClick={() => startCall()}
      />
    </div>
  );
};
