import { Pagination, Popover, Select } from 'antd';
import React, { Suspense, useContext, useEffect, useMemo, useState } from 'react';
import { FaUserCog, FaUserPlus } from 'react-icons/fa';
import { AiOutlineSchedule } from 'react-icons/ai';
import CustomButton from '../../../../components/CustomComponents/CustomButton/CustomButton';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import Table from '../../../../components/Table/Table';
import { OperatorContext } from '../../../../contexts/OperatorState';
import { LoadingScreen } from '../../../../core/components/LoadingScreen';
import useFormLocal from '../../hooks/useFormLocal';
import { doctorsMapper, getDoctors } from '../../helpers/operatorHelpers';
import CEDoctorDrawer from './components/CEDoctorDrawer';
import { doctorColumns, doctorsFiltersOptions } from './constants/doctorsData';
import { sortDirectionOptions } from '../Tickets/components/TicketsData';
import useMutateData from 'src/pages/Admin/hooks/useMutateData';
import { API_AGENT } from 'src/pages/service/api_agent';
import { BiEdit } from 'react-icons/bi';
import Filter from 'src/components/CustomComponents/CustomSelectMenus/Filter';

const Doctors: React.FC = () => {
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const [editId, setEditId] = useState<number | undefined>();
  const [tablePage, setTablePage] = useState(0);
  const [size, setSize] = useState(localStorage.getItem('page-size') ?? '10');
  const [sort, setSort] = useState('firstName');
  const [order, setOrder] = useState('asc');
  const [status, setStatus] = useState(localStorage.getItem('doctor-option') ?? '');

  const { updateOperatorState } = useContext(OperatorContext);

  const doctorColumnsMemo = useMemo(() => doctorColumns, []);

  const doctors = useFormLocal('get-doctors', getDoctors, doctorsMapper, {
    enabled: status,
    size,
    sort: `${sort},${order}`,
    page: tablePage,
  });

  useEffect(() => {
    if (!doctors.isPreviousData || doctors.data?.hasMore) {
      doctors.refetch();
    }
  }, [tablePage, size, order, status]);

  const handleSort = (column: any, sortDirection: any) => {
    if (column.hasSort) {
      doctorColumnsMemo.map((item: any) => item.accessor === column.id && setSort(item.sortName));
      sortDirectionOptions.map(item => item.previous === sortDirection && setOrder(item.next));
    }
  };

  const handleSize = () => (value: string) => {
    localStorage.setItem('page-size', value);
    setTablePage(0);
    setSize(localStorage.getItem('page-size')!);
  };

  const handlePagination = () => (page: number) => {
    setTablePage(page - 1);
  };

  const onUserStatusEdit = useMutateData(
    API_AGENT.editDoctorStatus,
    {
      onSuccessMessage: 'Uspešno izmenjen status lekara!',
      onErrorMessage: 'Greška! Status lekara nije izmenjen!',
    },
    doctors.refetch
  );

  const handleDoctorStatus = (doctor: any) => {
    onUserStatusEdit.mutate({
      doctor: { enabled: !doctor.enabled },
      id: doctor.id,
    });
  };

  const nameToLinkMapper = () => {
    doctors?.data?.map((doctor: any) => {
      doctor.status = (
        <Popover
          mouseEnterDelay={0.1}
          placement='right'
          content={doctor.enabled ? 'Deaktiviraj lekara' : 'Aktiviraj lekara'}
        >
          <div className='flex justify-start w-max'>
            <CustomButton
              className={
                doctor.enabled
                  ? 'border-green-500 text-green-500 hover:text-red-500 hover:border-red-500 transition'
                  : 'border-red-500 text-red-500 hover:text-green-500 hover:border-green-500 transition'
              }
              icon={<FaUserCog className='mr-2' />}
              text={doctor.enabled ? ' Aktivan' : ' Neaktivan'}
              type='default'
              onClick={() => handleDoctorStatus(doctor)}
            />
          </div>
        </Popover>
      );
      doctor.actions = (
        <div className='flex flex-row gap-1 cursor-pointer justify-center pr-8'>
          <Popover mouseEnterDelay={0.1} placement='top' content='Uredi lekara'>
            <BiEdit className='w-6 h-6' onClick={() => handleCEDrawer(doctor.id!)} />
          </Popover>
        </div>
      );
      doctor.scheduledMeetingsCount = (
        <Popover mouseEnterDelay={0.1} placement='right' content='Broj zakazanih konsultacija'>
          <div className='flex w-max'>
            <CustomButton
              className='border-gray-500 text-gray-500 hover:cursor-default font-semibold'
              icon={<AiOutlineSchedule className='w-5 h-5 mr-2' />}
              text={doctor.scheduledMeetings}
              type='text'
            />
          </div>
        </Popover>
      );
    });
  };

  nameToLinkMapper();

  const handleCEDrawer = (id?: number) => {
    let chosenDoctor = undefined;
    if (id) {
      setEditId(id);
      chosenDoctor = doctors?.data?.find((doctor: { id: number }) => doctor?.id === id);
      if (chosenDoctor?.status === 'Aktivan') {
        chosenDoctor.enabled = true;
      } else if (chosenDoctor?.status === 'Neaktivan') {
        chosenDoctor.enabled = false;
      }
      updateOperatorState(chosenDoctor, 'CE_DOCTOR');
    }
    setDrawerVisibility(!drawerVisibility);
    if (drawerVisibility === true) {
      setEditId(id);
      const tempData = {
        firstName: '',
        lastName: '',
        title: '',
        email: '',
        phoneNumber: '',
        enabled: true,
        medicineFields: [],
      };
      updateOperatorState(tempData, 'CE_DOCTOR');
    }
  };

  const handleDropdownValue = (value: string) => {
    localStorage.setItem('doctor-option', value);
    setStatus(localStorage.getItem('doctor-option')!);
    setTablePage(0);
  };

  if (doctors.isLoading) {
    return <LoadingScreen />;
  }

  if (doctors.isError) {
    if (doctors.error instanceof Error) {
      return <h3>{doctors.error.message}</h3>;
    }
  }

  return (
    // <>
    <ErrorBoundary>
      <Suspense fallback={<LoadingScreen />}>
        <div className='flex justify-between mb-6 h-content'>
          <Filter
            className='w-32'
            value={status}
            options={doctorsFiltersOptions}
            onChange={handleDropdownValue}
          />
          <div />
          <CustomButton
            text='Dodaj lekara'
            className='h-9 bg-btn-primary font-bold text-white hover:opacity-80'
            onClick={() => handleCEDrawer()}
            icon={<FaUserPlus className='inline-block mb-1 mr-2' />}
          />
        </div>
        <CEDoctorDrawer
          handleCEDrawer={handleCEDrawer}
          drawerVisibility={drawerVisibility}
          doctors={doctors}
          editId={editId}
        />
        <Table
          columns={doctorColumnsMemo}
          data={doctors.data}
          // search={search}
          // handleSearch={handleSearch}
          // confirmSearch={confirmSearch}
          handleSort={handleSort}
          sort={sort}
          order={order}
        />
        <div className='mt-2 flex justify-between'>
          <div />
          <Pagination
            className='flex'
            size='small'
            pageSize={1}
            current={tablePage + 1}
            defaultCurrent={1}
            total={Number(localStorage.getItem('pagination-size'))}
            onChange={handlePagination()}
            showSizeChanger={false}
          />
          <Select
            placeholder='Size'
            value={size}
            options={[{ value: 10 }, { value: 25 }, { value: 50 }]}
            onChange={handleSize()}
          />
        </div>
      </Suspense>
    </ErrorBoundary>
    // </>
  );
};

export default Doctors;
