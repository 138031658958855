import formBackground from '../../assets/public-form-background.jpg';
import React from 'react';
import backgroundLogo from '../../assets/stetoskop-telemedicina-logo.png';

const PublicWrapper: React.FC = () => {
  return (
    <div className="gradient-background w-screen h-screen">
      <img className="float-right mt-5 mr-5 w-[500px]" src={backgroundLogo} />
      {/* <img src={formBackground} className="w-[1000px] h-[1000px]" /> */}
    </div>
  );
};

export default PublicWrapper;
