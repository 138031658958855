const WebFormReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'UPDATE_FIELDS_STATE':
      const updatedFields = action.payload;
      return { ...state, patientData: updatedFields };

    case 'CHANGE_FIELD_STATE':
      const updatedField: Array<number> = action.payload;
      let updatedState = {};
      Object.entries(state.patientData).map((field: any) => {
        for (let i = 0; i < updatedField.length; i++) {
          if (parseInt(field[0]) === updatedField[i]) {
            updatedState = Object.assign({}, state.patientData, {
              [updatedField[i]]: !field[1],
            });
            return updatedState;
          }
        }
      });
      return { ...state, patientData: updatedState };

    case 'CHANGE_STEP_NUMBER':
      const updatedStep: number = action.payload;
      return { ...state, step: updatedStep };

    case 'UPDATE_TICKET_ANSWERS':
      const updatedForm: string = action.payload;
      return { ...state, ticketAnswers: updatedForm };

    case 'UPDATE_MEDICINE_FIELD_ANSWERS':
      const updatedMedField: any = action.payload;
      return { ...state, medFieldAnswers: updatedMedField };
    default:
      return state;
  }
};

export { WebFormReducer };
