import moment from 'moment';
import {
  components,
  FormItem,
  Doctor,
  MedField,
  TicketAnswers,
  MedFieldAnswers,
  Gender,
} from '../constants/stepsData';

export const dataMapper = ({ id, text, type, initiallyDisplayed, options }: FormItem) => {
  // let component: any = components[type]?.component
  let component: any =
    type === 'SINGLE_CHOICE' || type === 'MULTIPLE_CHOICE'
      ? components.SINGLE_CHOICE?.component
      : type === 'FREE_TEXT'
      ? components.FREE_TEXT?.component
      : type === 'DECIMAL' || type === 'INTEGER'
      ? components.DECIMAL?.component
      : type === 'DATE'
      ? components.DATE?.component
      : components.FILE_UPLOAD?.component;
  options?.map(option => {
    return (option.value = option.text);
  });
  return {
    id,
    text,
    type,
    component,
    initiallyDisplayed,
    options,
  };
};

export const doctorsMapper = ({ id, firstName, lastName, title }: Doctor) => {
  const value: string = `${title} ${firstName} ${lastName}`;
  return {
    id,
    value,
  };
};

export const medFieldsMapper = ({ id, name }: MedField) => {
  const value: string = name;
  return {
    id,
    value,
  };
};

export const fieldOfMedMapper = ({ id, name }: MedField) => {
  return {
    id,
    value: id,
    label: name,
  };
};

export const ticketAnswersMapper = ({
  firstName,
  lastName,
  email,
  phoneNumber,
  medicineFieldId,
  doctorId,
  birthDate,
  gender,
}: TicketAnswers) => {
  if (typeof medicineFieldId !== 'number') medicineFieldId = medicineFieldId?.id;
  if (typeof doctorId !== 'number') doctorId = doctorId?.id;
  if (typeof birthDate !== 'string') birthDate = moment(birthDate)?.format('YYYY-MM-DD');

  return {
    firstName,
    lastName,
    email,
    phoneNumber,
    medicineFieldId,
    doctorId,
    birthDate,
    gender,
  };
};

export const postAnswersMapper = (additionalData: Array<MedFieldAnswers>, id: number) => {
  let tempAdditionalData = additionalData;
  tempAdditionalData.map(item => (item.ticketId = id));
  for (let answer = 0; answer < tempAdditionalData.length; answer++) {
    if (typeof tempAdditionalData[answer]?.date !== 'string')
      tempAdditionalData[answer].text = tempAdditionalData[answer]?.date.format('YYYY-MM-DD');
    if (tempAdditionalData[answer]?.selectedAnswers.length !== 0) {
      for (const answerId in tempAdditionalData[answer]?.selectedAnswers) {
        tempAdditionalData[answer].selectedAnswerIds[answerId] =
          tempAdditionalData[answer]?.selectedAnswers[answerId]?.id;
      }
    } else {
      if (
        tempAdditionalData[answer]?.text === '' &&
        tempAdditionalData[answer]?.files.length === 0
      ) {
        tempAdditionalData.splice(answer, 1);
        answer--;
      }
    }
  }
  return tempAdditionalData;
};

export const getAllGendersMapper = ({ name, value }: Gender) => {
  return {
    name,
    value,
  };
};

export const getDefaultQuestions = () => {
  return {
    method: 'get',
    url: 'api/questions/default',
  };
};

export const getMedFieldQuestions = (medicineFieldId: number) => {
  return {
    method: 'get',
    url: 'api/questions',
    params: {
      medicineFieldId,
    },
  };
};

export const getAllDoctors = (medicineFieldId: number) => {
  return {
    method: 'get',
    url: 'api/doctors',
    params: {
      medicineFieldId,
    },
  };
};

export const getAllMedFields = () => {
  return {
    method: 'get',
    url: 'api/medicine-fields',
  };
};

export const postTicketAnswers = (data: TicketAnswers) => {
  return {
    method: 'post',
    url: 'api/tickets',
    data,
  };
};

export const postMedFieldAnswers = (data: MedFieldAnswers) => {
  return {
    method: 'put',
    url: 'api/answers',
    data,
  };
};

export const getEnums = () => {
  return {
    method: 'get',
    url: 'api/enums',
  };
};
