import { createContext, useReducer } from 'react';
import { RatingReducer } from 'src/reducers/RatingReducer';

const initialState: any = {
  ratingData: { grade: 0, comment: '' },
};

export const RatingContext = createContext(initialState);

export const RatingStateProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(RatingReducer, initialState);

  const updateRatingState = (payload: any, type?: string) => {
    dispatch({
      type,
      payload,
    });
  };

  return (
    <RatingContext.Provider
      value={{
        ratingData: state.ratingData,
        updateRatingState,
      }}
    >
      {children}
    </RatingContext.Provider>
  );
};
